const notifications = {
  notifications: {
    events: {
      totalCOunt: 1,
      eventsList: [
        {
          incident: "Overspeeding",
          busNo: "1234",
          route: "Route#1",
          source: {
            locality: "Florrencia, Costa Rica",
            coOrdinates: {
              lat: 10.364558407178833,
              lng: -84.47743152619893,
            },
          },
          destination: {
            locality: "Jicarito, Costa Rica",
            coOrdinates: {
              lat: 10.605794407884536,
              lng: -84.704989450137,
            },
          },
          currentLocation: {
            locality: "Florrencia, Costa Rica",
            coOrdinates: {
              lat: 10.364558407178833,
              lng: -84.47743152619893,
            },
          },
          nextStop: {
            locality: "Muelle San Carlos",
            coOrdinates: {
              lat: 10.473848132759747,
              lng: -84.46963269522386,
            },
          },
          driver: "John Doe",
          licenceNo: "DRV#123456",
          timeStamp: "08-05-2022 | 10.00 AM",
        },
      ],
    },
    incidents: {
      totalCOunt: 1,
      incidentsList: [
        {
          incident: "Harsh Braking",
          busNo: "4321",
          route: "Route#1",
          source: {
            locality: "San Jorge",
            coOrdinates: {
              lat: 10.717084314358102,
              lng: -84.67959872583144,
            },
          },
          destination: {
            locality: "Boco Arenal",
            coOrdinates: {
              lat: 10.535121220817171,
              lng: -84.48273248094928,
            },
          },
          currentLocation: {
            locality: "San Jorge",
            coOrdinates: {
              lat: 10.717084314358102,
              lng: -84.67959872583144,
            },
          },
          nextStop: {
            locality: "Santa Rosa de Pocosol",
            coOrdinates: {
              lat: 10.628080138363762,
              lng: -84.52937188836226,
            },
          },
          driver: "John Doe",
          licenceNo: "DRV#123456",
          timeStamp: "08-05-2022 | 10.00 AM",
        },
      ],
    },
    oprAlerts: {
      totalCOunt: 1,
      oprAlertsList: [
        {
          incident: "Sharp Turns",
          busNo: "1920",
          route: "Route#1",
          source: {
            locality: "San Rafael de Guatuso",
            coOrdinates: {
              lat: 10.666413876081828,
              lng: -84.8112894535626,
            },
          },
          destination: {
            locality: "Pocosol",
            coOrdinates: {
              lat: 10.381380505208913,
              lng: -84.61877218354736,
            },
          },
          currentLocation: {
            locality: "San Rafael de Guatuso",
            coOrdinates: {
              lat: 10.666413876081828,
              lng: -84.8112894535626,
            },
          },
          nextStop: {
            locality: "La Fortuna",
            coOrdinates: {
              lat: 10.470200326746983,
              lng: -84.64022251241668,
            },
          },
          driver: "John Doe",
          licenceNo: "DRV#123456",
          timeStamp: "08-05-2022 | 10.00 AM",
        },
      ],
    },
  },
};
export default notifications;
