import { styled } from "@mui/material/styles";
export const RootContainer = styled("div")`
  background-color: ${({ selected }) => (selected ? "#3A3A3A" : "#000000")};
  padding: 10px 20px;
  border-top: ${({ selected }) => (!selected ? "1px solid #828282" : null)};
  cursor: pointer;
  color: ${({ selected }) => (selected ? "#FFFFFF" : "#484848")};
  font-family: "OpenSans-Regular";
`;
export const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19.07px;
  font-family: "OpenSans-Medium";
  @media (max-width: 1920px) {
    font-size: 12px;
  }
`;

export const TitleContainerElements = styled("div")`
  font-size: 14px;
  line-height: 19.07px;
  font-family: "OpenSans-Medium";
  color: ${({ inprogress, selected }) =>
    selected
      ? inprogress
        ? "#4CDC34"
        : "#FFFFFF"
      : inprogress
      ? "#1B7A0C"
      : "#484848"};
  @media (max-width: 1920px) {
    font-size: 12px;
  }
`;

export const DefaultBody = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BodyText = styled("div")`
  font-size: 12px;
  line-height: 16.34px;
  font-family: "OpenSans-Regular";
  @media (max-width: 1920px) {
    font-size: 10px;
  }
`;
export const DateTimeText = styled("div")`
  font-size: 12px;
  line-height: 16.34px;
  font-family: "OpenSans-Regular";
  padding-left: 10px;
  @media (max-width: 1920px) {
    font-size: 10px;
  }
`;
