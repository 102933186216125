import React from "react";
import {
  RootContainer,
  TitleContainer,
  DefaultBody,
  BodyText,
  DateTimeText,
  TitleContainerElements,
} from "./styles";
import {
  FlexSpace,
  HorizontalSpace,
  VerticalSpace,
} from "elements/Space/space";
import {
  sourceLocation,
  blackRightArrow,
  greenDestinationLocation,
  rightArrow,
  whiteRightArrowExtended,
} from "assets/icons";

const TripList = (props) => {
  const {
    data: { tripNo, distance, tripStatus, routeDetails },
    handleIsSelected,
    selected,
    index,
  } = props;

  return (
    <RootContainer
      selected={selected === index ? true : false}
      onClick={() => handleIsSelected(index, routeDetails, tripStatus)}
    >
      <TitleContainer>
        <TitleContainerElements
          selected={selected === index ? true : false}
          inprogress={false}
        >{`Trip ${tripNo} - Stops(${routeDetails.routeStops.totalStops})`}</TitleContainerElements>
        <HorizontalSpace count={2} />
        <TitleContainerElements
          selected={selected === index ? true : false}
          inprogress={tripStatus === "In Progress" ? true : false}
        >
          {tripStatus}
        </TitleContainerElements>
      </TitleContainer>
      <VerticalSpace count={2} />

      <DefaultBody>
        <img src={sourceLocation} />
        <HorizontalSpace count={0.5} />
        <BodyText expanded={true} source={true}>
          {routeDetails.routeStops.stoppages[0].area}
        </BodyText>
        <HorizontalSpace count={1} />
        <img src={whiteRightArrowExtended} />
        <HorizontalSpace count={2} />
        <img src={greenDestinationLocation} />
        <HorizontalSpace count={0.5} />
        <BodyText expanded={true} source={false}>
          {routeDetails.routeStops.stoppages[5].area}
        </BodyText>
        <FlexSpace />
        <DateTimeText>
          {selected === index ? (
            <img src={rightArrow} />
          ) : (
            <img src={blackRightArrow} />
          )}
        </DateTimeText>
      </DefaultBody>
      <VerticalSpace count={2} />
      <DateTimeText>Distance - {distance}</DateTimeText>
    </RootContainer>
  );
};

export default TripList;
