const drivers = {
  drivers: [
    {
      avatarImg:
        "https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      driver: "Tim John",
      speed: "10 mph",
      workStatus: "On Trip",
      violations: "5",
      distanceCovered: "20 Miles",
      route: "Route#1",
      licenseNo: "DRV#123456",
      busNo: "Bus#1",
      busLicensePlateNo: "PB2586",
      timeStamp: "08-05-2022 | 10:00 AM",
      source: {
        area: "Florrencia, Costa Rica",
        coOrdinates: {
          lat: 10.364558407178833,
          lng: -84.47743152619893,
        },
      },
      destination: {
        area: "Jicarito, Costa Rica",
        coOrdinates: {
          lat: 10.605794407884536,
          lng: -84.704989450137,
        },
      },
      currentLocation: {
        area: "Tabacon, Costa Rica",
        coOrdinates: {
          lat: 10.364558407178833,
          lng: -84.47743152619893,
        },
      },
      nextStop: {
        area: "Muelle San Carlos",
        coOrdinates: {
          lat: 10.473848132759747,
          lng: -84.46963269522386,
        },
      },
    },
  ],
};
export default drivers;
