export const formattedCardListData = (tabIndex, data, type) => {
  const dataList = [];
  const getNotificationList = (data) => {
    let notListData = {};
    switch (type) {
      case "events":
        notListData = data?.notifications?.events?.eventsList;
        break;
      case "incidents":
        notListData = data?.notifications?.incidents?.incidentsList;
        break;
      case "oprAlerts":
        notListData = data?.notifications?.oprAlerts?.oprAlertsList;
        break;
      default:
        break;
    }
    notListData &&
      notListData.length > 0 &&
      notListData.map((item) => {
        dataList.push({
          title: `${item?.incident}${" | "}${item?.busNo}${" | "}${"Route - "}${
            item?.route
          }`,
          source: item?.source?.locality,
          destination: item?.destination?.locality,
          timeStamp: item?.timeStamp,
          driver: `${"Driver - "}${item?.driver}`,
          licenseNumber: `${"License Number - "}${item?.licenceNo}`,
          currentLocation: item?.currentLocation?.locality,
          sourceCoOrdinates: item?.source?.coOrdinates,
          destinationCoOrdinates: item?.destination?.coOrdinates,
          currentLocationCoOrdinates: item?.currentLocation?.coOrdinates,
          nextStopCoOrdinates: item?.nextStop?.coOrdinates,
          busNo: item?.busNo,
        });
      });
    return dataList;
  };
  const getBusesList = (data) => {
    data?.buses?.map((item) => {
      dataList.push({
        busNo: item?.busNo,
        title: `BUS#${item?.busNo}${" | "}${"Route - "}${item?.route}`,
        source: item?.source?.area,
        destination: item?.destination?.area,
        timeStamp: item?.timeStamp,
        currentLocation: item?.currentLocation?.area,
        nextStop: item?.nextStop?.area,
        speed: item?.speed,
        distance: item?.distanceCovered,
        stops: item?.stops,
        fuelConsumed: item?.fuelConsumed,
        distance: item?.distanceCovered,
        driver: item?.driver,
        sourceCoOrdinates: item?.source?.coOrdinates,
        destinationCoOrdinates: item?.destination?.coOrdinates,
        currentLocationCoOrdinates: item?.currentLocation?.coOrdinates,
        nextStopCoOrdinates: item?.nextStop?.coOrdinates,
      });
    });
    return dataList;
  };
  const getDriversList = (data) => {
    data?.drivers?.map((item) => {
      dataList.push({
        title: `${item?.driver}${" | "}${"Route - "}${item?.route}`,
        source: item?.source?.area,
        destination: item?.destination?.area,
        timeStamp: item?.timeStamp,
        licenseNumber: item?.licenseNo,
        busNo: item?.busNo,
        workStatus: item?.workStatus,
        violations: item?.violations,
        distance: item?.distanceCovered,
        speed: item?.speed,
        busPlateNo: item?.busLicensePlateNo,
        imageUrl: item?.avatarImg,
        sourceCoOrdinates: item?.source?.coOrdinates,
        destinationCoOrdinates: item?.destination?.coOrdinates,
        currentLocationCoOrdinates: item?.currentLocation?.coOrdinates,
        nextStopCoOrdinates: item?.nextStop?.coOrdinates,
      });
    });
    return dataList;
  };
  const getRoutesList = (data) => {
    let routeListData = {};
    switch (type) {
      case "activeRoutes":
        routeListData = data?.activeRoutes?.routes;
        break;
      case "completedRoutes":
        routeListData = data?.completedRoutes?.routes;
        break;
      default:
        break;
    }
    routeListData &&
      routeListData.length > 0 &&
      routeListData.map((item) => {
        dataList.push({
          title: item?.route,
          busNo: item?.busId,
          source: item?.source?.area,
          destination: item?.destination?.area,
          timeStamp: item?.timeStamp,
          currentLocation: item?.currentLocation?.area,
          nextStop: item?.nextStop?.area,
          buses: item?.noOfBuses,
          stops: item?.stops,
          fuelConsumed: item?.fuelConsumed,
          distance: item?.distanceCovered,
          driver: item?.driver,
          sourceCoOrdinates: item?.source?.coOrdinates,
          destinationCoOrdinates: item?.destination?.coOrdinates,
          currentLocationCoOrdinates: item?.currentLocation?.coOrdinates,
          nextStopCoOrdinates: item?.nextStop?.coOrdinates,
        });
      });
    return dataList;
  };
  switch (tabIndex) {
    case 0:
      return getNotificationList(data);
    case 1:
      return getBusesList(data);
    case 2:
      return getDriversList(data);
    case 3:
      return getRoutesList(data);
    default:
      break;
  }
};

export const formattedAnalyticsListData = (label, data) => {
  const dataList = [];

  const getBusesAnalyticsList = (data) => {
    data?.busAnalytics?.map((item) => {
      dataList.push({
        title: `Bus# ${item?.busNo}`,
        licenseNumber: `License Plate Number - ${item?.busLicensePlateNo}`,
        generalStats: item?.generalStats,
        incomeStats: item?.incomeStats,
        distanceTravelledStats: item?.distanceTravelledStats,
        fuelConsumedStats: item?.fuelConsumedStats,
        travellingHoursStats: item?.travellingHoursStats,
        co2EmissionStats: item?.co2EmissionStats,
        expensesStats: item?.expensesStats,
      });
    });
    return dataList;
  };

  const getDriversAnalyticsList = (data) => {
    data?.driverAnalytics?.map((item) => {
      dataList.push({
        title: item?.name,
        licenseNumber: `Driving License Number - ${item?.licenseNo}`,
        generalStats: item?.generalStats,
        tripStats: item?.tripStats,
        avgDistanceDrivenStats: item?.avgDistanceDrivenStats,
        labourCostStats: item?.labourCostStats,
        driveTimeStats: item?.driveTimeStats,
        violationsStats: item?.violationsStats,
      });
    });
    return dataList;
  };

  const getRoutesAnalyticsList = (data) => {
    data?.routeAnalytics?.map((item) => {
      dataList.push({
        title: `Route# ${item?.route}`,
        generalStats: item?.generalStats,
        incomeStats: item?.incomeStats,
        passengersStats: item?.passengersStats,
        fuelEconomyStats: item?.fuelEconomyStats,
        avgBusesStats: item?.avgBusesStats,
        top5BusyRouteStats: item?.top5BusyRouteStats,
        stopsStats: item?.stopsStats,
      });
    });
    return dataList;
  };

  switch (label) {
    case "buses":
      return getBusesAnalyticsList(data);
    case "drivers":
      return getDriversAnalyticsList(data);
    case "routes":
      return getRoutesAnalyticsList(data);
    default:
      break;
  }
};
