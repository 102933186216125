import { makeStyles } from "@material-ui/core/styles";
import theme from "theme";

const useStyles = makeStyles(
  {
    infoIconMainClass: {
      flex: "1",
      margin: 10,
    },
    infoBoxClass: {
      display: "inline-flex",
      [theme.breakpoints.down(1537)]: {
        "& .MuiButtonBase-root": {
          width: 50,
          height: 50,
          "& img": {
            width: 24,
            height: 24,
          },
        },
      },
    },
    infoTextContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    infoTextH1: {
      margin: 0,
      padding: 0,
      color: theme.palette.darkGreyTwo,
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "41px",
      [theme.breakpoints.down(1537)]: {
        fontSize: "18px !important",
        lineHeight: "30px",
      },
    },
    infoTextH4: {
      margin: 0,
      padding: 0,
      color: theme.palette.grey7,
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "19px",
      [theme.breakpoints.down(1537)]: {
        fontSize: "10px !important",
        lineHeight: "22px",
      },
    },
  },
  { index: 1 }
);
export default useStyles;
