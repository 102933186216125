import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import useStyles from "./styles";

const CircularChart = (props) => {
  const { data, circularInnerText } = props;
  const {
    progressMainStyle,
    progressInnerText,
    progressValueStyle,
    progressTextStyle,
    progressBarStyle,
  } = useStyles();

  const sum = data.reduce((prevVal, object) => {
    return prevVal + object.value;
  }, 0);

  return (
    <div className={progressMainStyle}>
      <PieChart
        animate
        animationDuration={500}
        animationEasing="ease-out"
        center={[50, 50]}
        data={data}
        lengthAngle={-360}
        lineWidth={18}
        paddingAngle={0}
        radius={30}
        rounded
        startAngle={0}
        viewBoxSize={[100, 100]}
        labelPosition={50}
      ></PieChart>
      <div className={progressInnerText}>
        <div className={progressValueStyle}>{sum}</div>
        <div className={progressTextStyle}>{circularInnerText}</div>
      </div>
    </div>
  );
};
export default CircularChart;
