import React, { useState } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import Button from "elements/Button";
import { searchIcon, arrowIcon } from "assets/icons";
import useStyles from "./styles";

const AnalyticsList = (props) => {
  const { cardList, currentIndex } = props;

  const {
    RootContainer,
    TitleContainer,
    ButtonContainer,
    buttonStyles,
    buttonStylesDisable,
    title,
    flex1,
  } = useStyles();

  const handleListItem = (item, index) => {
    props.handleSelectedListItem(item);
    props.handleCurrentIndex(index);
  };

  const handleAnalytics = () => {
    props.handleAnalytics();
  };

  return (
    <div className={RootContainer}>
      <div className={ButtonContainer}>
        <TextField
          label="Search"
          type="search"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img src={searchIcon} />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          handleClick={handleAnalytics}
          buttonStyles={
            currentIndex === null ? buttonStyles : buttonStylesDisable
          }
        >
          OVERALL ANALYTICS
        </Button>
      </div>
      {cardList?.map((item, index) => {
        return (
          <div
            key={index}
            className={TitleContainer}
            onClick={() => handleListItem(item, index)}
            style={{
              background: index === currentIndex ? "#D0EFFF" : "#ffffff",
              border:
                index === currentIndex
                  ? "1px solid #218AC5"
                  : "1px solid #dcdada",
            }}
          >
            <div className={flex1}>
              <span className={item.licenseNumber ? "" : title}>
                {item.title}{" "}
              </span>
              {item.licenseNumber ? <span>|</span> : ""}
              <span>{item.licenseNumber}</span>
            </div>
            {index === currentIndex ? (
              <img src={arrowIcon} width={"16px"} height={"16px"} />
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AnalyticsList;
