import { put } from "redux-saga/effects";
import {
  setBusInfo,
  setBusesList,
  setBusAnalytics,
} from "redux/actions/busesActions";
// import fetchAPIServices from "../../../services/fetchAPIServices";
// import { loginApi } from "../../../services/endPoints";
import buses from "mockdata/buses";
import busInfo from "mockdata/busInfo";
import busAnalytics from "mockdata/busAnalytics";

export function* handleBusesList(action) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    //  const response = yield fetchLogin(loginApi, action.payload);
    if (buses) {
      yield put(setBusesList(buses));
    } else {
      yield put(setBusesList({}));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleBusesInfo(action) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    //  const response = yield fetchLogin(loginApi, action.payload);
    if (busInfo) {
      yield put(setBusInfo(busInfo));
    } else {
      yield put(setBusInfo({}));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleBusesAnalyticsInfo(action) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    //  const response = yield fetchLogin(loginApi, action.payload);
    if (busAnalytics) {
      yield put(setBusAnalytics(busAnalytics));
    } else {
      yield put(setBusAnalytics({}));
    }
  } catch (error) {
    console.log(error);
  }
}
