import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    footer: {
      background: "#2F2F2F",
      fontFamily: "Roboto-Regular",
      color: "#fff",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: "1111",
      padding: "10px 16px",
      width: "100%",
      "& p": {
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        margin: "0 auto",
      },
      "& img": {
        width: 40,
        margin: "0px 10px",
      },
    },
  }),
  { index: 1 }
);

export default useStyles;
