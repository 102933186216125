import { put } from "redux-saga/effects";
import { setNotifications } from "redux/actions/notificationActions";
// import fetchAPIServices from "../../../services/fetchAPIServices";
// import { loginApi } from "../../../services/endPoints";
import notifications from "mockdata/notifications";

export function* handleNotificationsList(action) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    //  const response = yield fetchLogin(loginApi, action.payload);
    if (notifications) {
      yield put(setNotifications(notifications));
    } else {
      yield put(setNotifications({}));
    }
  } catch (error) {
    console.log(error);
  }
}
