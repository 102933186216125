import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  analyticsContainerSection: {
    height: "calc(100vh - 108px) !important",
  },
  analyticsRow: {
    borderTop: `5px solid ${theme.palette.lightBlueSeven} !important`,
  },
  analyticsList: {
    display: "flex",
    flexDirection: " column !important",
    height: "calc(100vh - 165px)",
  },
  graphSection: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
    padding: 12,
    margin: 10,
    flex: 1,
    position: " relative",
  },
  incomeText: {
    fontFamily: "OpenSans-Bold",
    fontSize: 16,
    lineHeight: "22px",
    color: theme.palette.blue,
    marginRight: 16,
    [theme.breakpoints.down(1537)]: {
      fontSize: 13,
    },
  },
  incomeCurrentText: {
    fontFamily: "OpenSans-Medium",
    fontSize: 11,
    lineHeight: "22px",
    color: theme.palette.darkGreyOne,
    marginRight: 8,
    [theme.breakpoints.down(1537)]: {
      fontSize: 10,
      marginRight: 5,
    },
  },
  incomeCurrentTextDot: {
    width: 12,
    height: 12,
    backgroundColor: theme.palette.lightBlueOne,
    borderRadius: "50%",
    marginRight: 7,
    [theme.breakpoints.down(1537)]: {
      width: 9,
      height: 9,
      marginRight: 5,
    },
  },
  incomeCurrentSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  incomeLastTextDot: {
    width: 12,
    height: 12,
    backgroundColor: theme.palette.darkGreenOne,
    borderRadius: "50%",
    marginRight: 7,
    [theme.breakpoints.down(1537)]: {
      width: 9,
      height: 9,
    },
  },
  avgIncomeText: {
    fontFamily: "OpenSans-Medium",
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.darkGreyOne,
    marginRight: 8,
    marginTop: 2,
    [theme.breakpoints.down(1537)]: {
      fontSize: 9,
    },
  },
  followersText: {
    fontFamily: "OpenSans-Bold",
    fontSize: 15,
    lineHeight: "20px",
    color: theme.palette.lightBlueTwo,
    marginLeft: 6,
    [theme.breakpoints.down(1537)]: {
      fontSize: 12,
    },
  },
  followersTextBlue: {
    fontFamily: "OpenSans-Bold",
    fontSize: 15,
    lineHeight: "20px",
    color: theme.palette.lightBlueThree,
    marginLeft: 6,
    [theme.breakpoints.down(1537)]: {
      fontSize: 12,
    },
  },
  followersTextGreen: {
    fontFamily: "OpenSans-Bold",
    fontSize: 15,
    lineHeight: "20px",
    color: theme.palette.darkGreenOne,
    marginLeft: 6,
    [theme.breakpoints.down(1537)]: {
      fontSize: 12,
    },
  },
  followersTextGrey: {
    fontFamily: "OpenSans-Bold",
    fontSize: 15,
    lineHeight: "20px",
    color: theme.palette.darkGreyTwo,
    marginLeft: 6,
    [theme.breakpoints.down(1537)]: {
      fontSize: 12,
    },
  },
  followersPercentage: {
    fontFamily: "OpenSans-Medium",
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.darkGreenTwo,
    marginLeft: 7,
    [theme.breakpoints.down(1537)]: {
      fontSize: 10,
    },
  },
  followersPercentageRed: {
    fontFamily: "OpenSans-Medium",
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.darkRedOne,
    marginLeft: 7,
    [theme.breakpoints.down(1537)]: {
      fontSize: 10,
    },
  },
  arrow: {
    marginRight: 2,
    marginLeft: 6,
  },
  analyticsRightColumn: {
    background: theme.palette.grey9,
  },
  analyticsGridSection: {
    display: "flex",
    flexDirection: "column !important",
    height: "calc(100vh - 300px) !important",
    [theme.breakpoints.down(1537)]: {
      height: "calc(100vh - 271px) !important",
      overflow: "auto",
    },
    [theme.breakpoints.down(900)]: {
      marginBottom: 45,
    },
  },
  flex1: {
    flex: 1,
  },
  analyticsGrid: {
    display: "flex",
    flexDirection: "column !important",
  },
  infoIconClass: {
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 0px 4px rgb(0 0 0 / 10%)",
    margin: "0 10px 10px 10px",
    background: theme.palette.white,
    [theme.breakpoints.down(900)]: {
      flexWrap: "wrap",
      marginTop: 6,
    },
  },
  selectButton: {
    padding: "10px 10px 0 0",
    [theme.breakpoints.down(900)]: {
      padding: "26px 62px 0 0",
    },
  },
  expenseList: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flex: 1,
  },
  expenseListDriver: {
    display: "flex",
    margin: "0 auto",
    justifyContent: "flex-end",
    flexDirection: "column",
    flex: 1,
  },
  analyticsGridList: {
    display: "flex",
  },
  innerExpenseListDriver: {
    display: "flex",
    alignItems: "center",
    margin: 18,
  },
  expenseFuelDriver: {
    fontFamily: "OpenSans-Bold",
    fontSize: 17,
    lineHeight: "23px",
    color: theme.palette.grey10,
    margin: "0 12px",
  },
  innerExpenseList: {
    margin: "0 36px",
    [theme.breakpoints.down(1537)]: {
      margin: "0 16px",
    },
  },
  expenseFuel: {
    fontFamily: "OpenSans-Medium",
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.grey5,
  },
  expenseFollowers: {
    fontFamily: "OpenSans-Bold",
    fontSize: 15,
    lineHeight: "20px",
    color: theme.palette.darkGreyThree,
  },
  expenseDriverFollowers: {
    fontFamily: "OpenSans-Bold",
    fontSize: 15,
    lineHeight: "20px",
    color: theme.palette.darkGreyThree,
  },
  expenseCircleChip: {
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    marginBottom: "5px",
  },
  expenseCircleChipLightBlue5: {
    background: theme.palette.lightBlueFive,
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    marginBottom: "5px",
  },
  expenseCircleChipLightPink1: {
    background: theme.palette.lightPinkOne,
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    marginBottom: "5px",
  },
  expenseCircleChipLightRed2: {
    background: theme.palette.darkRedTwo,
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    marginBottom: "5px",
  },
  expenseCircleChipDarkOrange: {
    background: theme.palette.darkOrange,
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    marginBottom: "5px",
  },
  expenseCircleChipPurpleOne: {
    background: theme.palette.purpleOne,
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    marginBottom: "5px",
  },
  expenseGreenChip: {
    borderRadius: "5px",
    width: "30px",
    height: "5px",
    marginBottom: "5px",
  },
  expensePinkChip: {
    background: theme.palette.lightPinkOne,
    borderRadius: "5px",
    width: "30px",
    height: "5px",
    marginBottom: "5px",
  },
  expenseBlueChip: {
    background: theme.palette.lightBlueFive,
    borderRadius: "5px",
    width: "30px",
    height: "5px",
    marginBottom: "5px",
  },
  graphExpenseSection: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down(600)]: {
      minHeight: 300,
    },
  },
}));

export default useStyles;
