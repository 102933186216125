import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  chipButtonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "14px",
  },
  notificationListSection: {
    overflow: "auto",
  },
}));

export default useStyles;
