const busInfo = {
  busInfo: {
    busNo: "123",
    licensePlateNo: "PB1672",
    regionDepo: "Costa Rica",
    totalTrips: "1458",
    safetyScore: "85",
    totalPassengers: "25896",
    totalMiles: "1500",
    avgFuelEconomy: "40 Miles/Gal",
    nextMaintanance: "08-25-2022",
    trips: [
      {
        tripNo: "1",
        speed: "10 mph",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        driver: "Tim John",
        tripStatus: "In Progress",
        distance: "150 Miles",
        routeDetails: {
          route: "Route#1",
          occupancy: "20/40",
          videoUrl: "http://busVideo",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "2",
        speed: "10 mph",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        driver: "Tim John",
        tripStatus: "Completed",
        distance: "150 Miles",
        routeDetails: {
          route: "Route#2",
          occupancy: "20/40",
          videoUrl: "http://busVideo",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "3",
        speed: "10 mph",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        driver: "Tim John",
        tripStatus: "Completed",
        distance: "150 Miles",
        routeDetails: {
          route: "Route#3",
          occupancy: "20/40",
          videoUrl: "http://busVideo",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "4",
        speed: "10 mph",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        driver: "Tim John",
        tripStatus: "Completed",
        distance: "150 Miles",
        routeDetails: {
          route: "Route#4",
          occupancy: "20/40",
          videoUrl: "http://busVideo",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "5",
        speed: "10 mph",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        driver: "Tim John",
        tripStatus: "Completed",
        distance: "150 Miles",
        routeDetails: {
          route: "Route#5",
          occupancy: "20/40",
          videoUrl: "http://busVideo",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "6",
        speed: "10 mph",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        driver: "Tim John",
        tripStatus: "Completed",
        distance: "150 Miles",
        routeDetails: {
          route: "Route#6",
          occupancy: "20/40",
          videoUrl: "http://busVideo",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "7",
        speed: "10 mph",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        driver: "Tim John",
        tripStatus: "Completed",
        distance: "150 Miles",
        routeDetails: {
          route: "Route#7",
          occupancy: "20/40",
          videoUrl: "http://busVideo",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
    ],
    pastViolations: [
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
    ],
  },
};

export default busInfo;
