import { put } from "redux-saga/effects";
import {
  setRouteInfo,
  setRoutesList,
  setRouteAnalytics,
} from "redux/actions/routesActions";
// import fetchAPIServices from "../../../services/fetchAPIServices";
// import { loginApi } from "../../../services/endPoints";
import routes from "mockdata/routes";
import routesInfo from "mockdata/routeInfo";
import routeAnalytics from "mockdata/routeAnalytics";

export function* handleRoutesList(action) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    //  const response = yield fetchLogin(loginApi, action.payload);
    if (routes) {
      yield put(setRoutesList(routes));
    } else {
      yield put(setRoutesList({}));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleRouteInfo(action) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    //  const response = yield fetchLogin(loginApi, action.payload);
    if (routesInfo) {
      yield put(setRouteInfo(routesInfo));
    } else {
      yield put(setRouteInfo({}));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleRoutesAnalyticsInfo(action) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    //  const response = yield fetchLogin(loginApi, action.payload);
    if (routeAnalytics) {
      yield put(setRouteAnalytics(routeAnalytics));
    } else {
      yield put(setRouteAnalytics({}));
    }
  } catch (error) {
    console.log(error);
  }
}
