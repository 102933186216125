import { styled } from "@mui/material/styles";

export const RootContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const ItemContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 767px) {
    flex: 1 1 50%;
  }
  @media (max-width: 430px) {
    flex: 1 1 100%;
  }
`;

export const ItemValue = styled("div")`
  color: ${({ highlighted }) => (highlighted ? "#74BA68" : "#FFFFFF")};
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const ItemLabel = styled("div")`
  font-size: 14px;
  line-height: 19px;
  color: #c6c2c2;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const VerticalSpace = styled("div")`
  height: ${({ count }) => count * 4}px;
`;
