const driverAnalytics = {
  driverAnalytics: [
    {
      name: "John",
      licenseNo: "PB2586",
      generalStats: {
        day: {
          totalTrips: "200",
          avgDistanceDriven: "1524 miles",
          avgDrivingHours: "100Hrs",
          avgLabourCost: "300$",
          avgDrivingScore: "90%",
        },
        weekly: {
          totalTrips: "300",
          avgDistanceDriven: "1524 miles",
          avgDrivingHours: "100Hrs",
          avgLabourCost: "300$",
          avgDrivingScore: "90%",
        },
        monthly: {
          totalTrips: "400",
          avgDistanceDriven: "1524 miles",
          avgDrivingHours: "100Hrs",
          avgLabourCost: "300$",
          avgDrivingScore: "70%",
        },
        yearly: {
          totalTrips: "500",
          avgDistanceDriven: "1524 miles",
          avgDrivingHours: "100Hrs",
          avgLabourCost: "300$",
          avgDrivingScore: "50%",
        },
      },
      tripStats: {
        day: {
          trips: "200",
          observation: "up",
          percentage: "0.5%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 217.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 247.0,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 257.0,
            },
            {
              node: "2022-10-11T14:00:00",
              count: 247.0,
            },
            {
              node: "2022-10-11T15:00:00",
              count: 287.0,
            },
            {
              node: "2022-10-11T16:00:00",
              count: 367.0,
            },
            {
              node: "2022-10-11T17:00:00",
              count: 327.0,
            },
          ],
        },
        weekly: {
          trips: "200",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          trips: "200",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          trips: "200",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-05-11T11:00:00",
              count: 117.0,
            },
            {
              node: "2022-06-11T12:00:00",
              count: 147.0,
            },
            {
              node: "2022-07-11T13:00:00",
              count: 157.0,
            },
            {
              node: "2022-08-11T14:00:00",
              count: 147.0,
            },
            {
              node: "2022-09-11T15:00:00",
              count: 187.0,
            },
            {
              node: "2022-10-11T16:00:00",
              count: 267.0,
            },
            {
              node: "2022-11-11T17:00:00",
              count: 227.0,
            },
          ],
        },
      },
      avgDistanceDrivenStats: {
        day: {
          totalDistanceDriven: "1524",
          observation: "up",
          percentage: "3.0%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          totalDistanceDriven: "1524",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          totalDistanceDriven: "1524",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          totalDistanceDriven: "1524",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      labourCostStats: {
        day: {
          labourCost: "$100",
          observation: "up",
          percentage: "0.5%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          labourCost: "$200",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          labourCost: "$300",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          labourCost: "$400",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      driveTimeStats: {
        day: {
          driveTime: "05Hrs",
          driveObservation: "up",
          drivePercentage: "1.0%",
          idleTime: "1Hrs",
          idleObservation: "down",
          idlePercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        weekly: {
          driveTime: "05Hrs",
          driveObservation: "up",
          drivePercentage: "1.0%",
          idleTime: "1Hrs",
          idleObservation: "down",
          idlePercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        monthly: {
          driveTime: "05Hrs",
          driveObservation: "up",
          drivePercentage: "1.0%",
          idleTime: "1Hrs",
          idleObservation: "down",
          idlePercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        yearly: {
          driveTime: "05Hrs",
          driveObservation: "up",
          drivePercentage: "1.0%",
          idleTime: "1Hrs",
          idleObservation: "down",
          idlePercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
      },
      violationsStats: {
        day: {
          overspeeding: "",
          harshBraking: "",
          harshAccelaration: "",
          sharpCorner: "",
          distractions: "",
          otherViolations: "",
          highFatigue: "",
        },
        weekly: {
          overspeeding: "",
          harshBraking: "",
          harshAccelaration: "",
          sharpCorner: "",
          distractions: "",
          otherViolations: "",
          highFatigue: "",
        },
        monthly: {
          overspeeding: "",
          harshBraking: "",
          harshAccelaration: "",
          sharpCorner: "",
          distractions: "",
          otherViolations: "",
          highFatigue: "",
        },
        yearly: {
          overspeeding: "",
          harshBraking: "",
          harshAccelaration: "",
          sharpCorner: "",
          distractions: "",
          otherViolations: "",
          highFatigue: "",
        },
      },
    },
    {
      name: "Tim",
      licenseNo: "PB2586",
      generalStats: {
        day: {
          totalTrips: "800",
          avgDistanceDriven: "2524 miles",
          avgDrivingHours: "500Hrs",
          avgLabourCost: "800$",
          avgDrivingScore: "50%",
        },
        weekly: {
          totalTrips: "1800",
          avgDistanceDriven: "2524 miles",
          avgDrivingHours: "500Hrs",
          avgLabourCost: "800$",
          avgDrivingScore: "50%",
        },
        monthly: {
          totalTrips: "500",
          avgDistanceDriven: "2524 miles",
          avgDrivingHours: "500Hrs",
          avgLabourCost: "800$",
          avgDrivingScore: "50%",
        },
        yearly: {
          totalTrips: "500",
          avgDistanceDriven: "2524 miles",
          avgDrivingHours: "500Hrs",
          avgLabourCost: "800$",
          avgDrivingScore: "50%",
        },
      },
      tripStats: {
        day: {
          trips: "100",
          observation: "up",
          percentage: "0.5%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 217.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 247.0,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 257.0,
            },
            {
              node: "2022-10-11T14:00:00",
              count: 247.0,
            },
            {
              node: "2022-10-11T15:00:00",
              count: 287.0,
            },
            {
              node: "2022-10-11T16:00:00",
              count: 367.0,
            },
            {
              node: "2022-10-11T17:00:00",
              count: 327.0,
            },
          ],
        },
        weekly: {
          trips: "200",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          trips: "300",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          trips: "400",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-05-11T11:00:00",
              count: 117.0,
            },
            {
              node: "2022-06-11T12:00:00",
              count: 147.0,
            },
            {
              node: "2022-07-11T13:00:00",
              count: 157.0,
            },
            {
              node: "2022-08-11T14:00:00",
              count: 147.0,
            },
            {
              node: "2022-09-11T15:00:00",
              count: 187.0,
            },
            {
              node: "2022-10-11T16:00:00",
              count: 267.0,
            },
            {
              node: "2022-11-11T17:00:00",
              count: 227.0,
            },
          ],
        },
      },
      avgDistanceDrivenStats: {
        day: {
          totalDistanceDriven: "2524",
          observation: "up",
          percentage: "3.0%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          totalDistanceDriven: "3524",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          totalDistanceDriven: "1524",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          totalDistanceDriven: "5524",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      labourCostStats: {
        day: {
          labourCost: "$500",
          observation: "up",
          percentage: "0.5%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          labourCost: "$200",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          labourCost: "$300",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          labourCost: "$400",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      driveTimeStats: {
        day: {
          driveTime: "10Hrs",
          driveObservation: "up",
          drivePercentage: "1.0%",
          idleTime: "1Hrs",
          idleObservation: "down",
          idlePercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        weekly: {
          driveTime: "05Hrs",
          driveObservation: "up",
          drivePercentage: "1.0%",
          idleTime: "1Hrs",
          idleObservation: "down",
          idlePercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        monthly: {
          driveTime: "05Hrs",
          driveObservation: "up",
          drivePercentage: "1.0%",
          idleTime: "1Hrs",
          idleObservation: "down",
          idlePercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        yearly: {
          driveTime: "05Hrs",
          driveObservation: "up",
          drivePercentage: "1.0%",
          idleTime: "1Hrs",
          idleObservation: "down",
          idlePercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
      },
      violationsStats: {
        day: {
          overspeeding: "",
          harshBraking: "",
          harshAccelaration: "",
          sharpCorner: "",
          distractions: "",
          otherViolations: "",
          highFatigue: "",
        },
        weekly: {
          overspeeding: "",
          harshBraking: "",
          harshAccelaration: "",
          sharpCorner: "",
          distractions: "",
          otherViolations: "",
          highFatigue: "",
        },
        monthly: {
          overspeeding: "",
          harshBraking: "",
          harshAccelaration: "",
          sharpCorner: "",
          distractions: "",
          otherViolations: "",
          highFatigue: "",
        },
        yearly: {
          overspeeding: "",
          harshBraking: "",
          harshAccelaration: "",
          sharpCorner: "",
          distractions: "",
          otherViolations: "",
          highFatigue: "",
        },
      },
    },
  ],
  overAllAnalytics: {
    generalStats: {
      day: {
        totalTrips: "500",
        avgDistanceDriven: "2956 miles",
        avgDrivingHours: "100Hrs",
        avgLabourCost: "$10K",
        avgDrivingScore: "75%",
      },
      weekly: {
        totalTrips: "3000",
        avgDistanceDriven: "2524 miles",
        avgDrivingHours: "100Hrs",
        avgLabourCost: "300$",
        avgDrivingScore: "90%",
      },
      monthly: {
        totalTrips: "4000",
        avgDistanceDriven: "3524 miles",
        avgDrivingHours: "100Hrs",
        avgLabourCost: "300$",
        avgDrivingScore: "70%",
      },
      yearly: {
        totalTrips: "5000",
        avgDistanceDriven: "4524 miles",
        avgDrivingHours: "100Hrs",
        avgLabourCost: "300$",
        avgDrivingScore: "50%",
      },
    },
    tripStats: {
      day: {
        avgTrips: "600",
        observation: "up",
        percentage: "0.5%",

        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 217.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 247.0,
          },
          {
            node: "2022-10-11T13:00:00",
            count: 257.0,
          },
          {
            node: "2022-10-11T14:00:00",
            count: 247.0,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 287.0,
          },
          {
            node: "2022-10-11T16:00:00",
            count: 367.0,
          },
          {
            node: "2022-10-11T17:00:00",
            count: 327.0,
          },
        ],
      },
      weekly: {
        avgTrips: "200",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      monthly: {
        avgTrips: "300",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      yearly: {
        avgTrips: "200",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-05-11T11:00:00",
            count: 117.0,
          },
          {
            node: "2022-06-11T12:00:00",
            count: 147.0,
          },
          {
            node: "2022-07-11T13:00:00",
            count: 157.0,
          },
          {
            node: "2022-08-11T14:00:00",
            count: 147.0,
          },
          {
            node: "2022-09-11T15:00:00",
            count: 187.0,
          },
          {
            node: "2022-10-11T16:00:00",
            count: 267.0,
          },
          {
            node: "2022-11-11T17:00:00",
            count: 227.0,
          },
        ],
      },
    },
    avgDistanceDrivenStats: {
      day: {
        avgDistanceDriven: "3524",
        observation: "up",
        percentage: "3.0%",

        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 1,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 4,
          },
          {
            node: "2022-10-11T13:00:00",
            count: 8,
          },
          {
            node: "2022-10-11T14:00:00",
            count: 3,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 10,
          },
        ],
      },
      weekly: {
        avgDistanceDriven: "1524",
        observation: "up",
        percentage: "3.0%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 1,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 4,
          },
          {
            node: "2022-10-11T13:00:00",
            count: 8,
          },
          {
            node: "2022-10-11T14:00:00",
            count: 3,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 10,
          },
        ],
      },
      monthly: {
        avgDistanceDriven: "4524",
        observation: "up",
        percentage: "3.0%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      yearly: {
        avgDistanceDriven: "5524",
        observation: "up",
        percentage: "3.0%",
        analyticsData: [
          {
            node: "2022-04-11T11:00:00",
            count: 1,
          },
          {
            node: "2022-05-11T12:00:00",
            count: 4,
          },
          {
            node: "2022-06-11T13:00:00",
            count: 8,
          },
          {
            node: "2022-07-11T14:00:00",
            count: 3,
          },
          {
            node: "2022-08-11T15:00:00",
            count: 10,
          },
        ],
      },
    },
    labourCostStats: {
      day: {
        avgLabourCost: "$300",
        observation: "up",
        percentage: "0.5%",

        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 1.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 4.0,
          },
          {
            node: "2022-10-11T13:00:00",
            count: 8.0,
          },
          {
            node: "2022-10-11T14:00:00",
            count: 2.0,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 4.0,
          },
        ],
      },
      weekly: {
        avgLabourCost: "$200",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      monthly: {
        avgLabourCost: "$300",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      yearly: {
        avgLabourCost: "$400",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-03-11T11:00:00",
            count: 1.0,
          },
          {
            node: "2022-04-11T12:00:00",
            count: 4.0,
          },
          {
            node: "2022-05-11T13:00:00",
            count: 8.0,
          },
          {
            node: "2022-06-11T14:00:00",
            count: 2.0,
          },
          {
            node: "2022-07-11T15:00:00",
            count: 4.0,
          },
        ],
      },
    },
    driveTimeStats: {
      day: {
        avgDriveTime: "15Hrs",
        avgDriveObservation: "up",
        avgDrivePercentage: "1.0%",
        avgIdleTime: "6Hrs",
        avgIdleObservation: "down",
        avgIdlePercentage: "0.5%",
        analyticsData: [
          {
            metricName: "Running Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 1.0,
              },
              {
                node: "2022-10-11T14:00:00",
                count: 6.0,
              },
              {
                node: "2022-10-11T16:00:00",
                count: 9.0,
              },
              {
                node: "2022-10-11T18:00:00",
                count: 2.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 7.0,
              },
            ],
          },
          {
            metricName: "Idle Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 2.0,
              },
              {
                node: "2022-10-11T14:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T16:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T18:00:00",
                count: 5.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 15.0,
              },
            ],
          },
        ],
      },
      weekly: {
        avgDriveTime: "15Hrs",
        avgDriveObservation: "up",
        avgDrivePercentage: "1.0%",
        avgIdleTime: "6Hrs",
        avdIdleObservation: "down",
        avgIdlePercentage: "0.5%",
        analyticsData: [
          {
            metricName: "Running Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 0.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 0.0,
              },
            ],
          },
          {
            metricName: "Idle Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
            ],
          },
        ],
      },
      monthly: {
        avgDriveTime: "13Hrs",
        avgDriveObservation: "up",
        avgDrivePercentage: "1.0%",
        avgIdleTime: "12Hrs",
        avdIdleObservation: "down",
        avgIdlePercentage: "0.5%",
        analyticsData: [
          {
            metricName: "Running Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 0.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 0.0,
              },
            ],
          },
          {
            metricName: "Idle Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
            ],
          },
        ],
      },
      yearly: {
        avgDriveTime: "05Hrs",
        avgDriveObservation: "up",
        avgDrivePercentage: "1.0%",
        avgIdleTime: "16Hrs",
        avdIdleObservation: "down",
        avgIdlePercentage: "0.5%",
        analyticsData: [
          {
            metricName: "Running Hours",
            analytics: [
              {
                node: "2022-06-11T11:00:00",
                count: 1.0,
              },
              {
                node: "2022-07-11T14:00:00",
                count: 6.0,
              },
              {
                node: "2022-08-11T16:00:00",
                count: 9.0,
              },
              {
                node: "2022-09-11T18:00:00",
                count: 2.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 7.0,
              },
            ],
          },
          {
            metricName: "Idle Hours",
            analytics: [
              {
                node: "2022-06-11T11:00:00",
                count: 2.0,
              },
              {
                node: "2022-07-11T14:00:00",
                count: 15.0,
              },
              {
                node: "2022-08-11T16:00:00",
                count: 31.0,
              },
              {
                node: "2022-09-11T18:00:00",
                count: 5.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 15.0,
              },
            ],
          },
        ],
      },
    },
    violationsStats: {
      day: {
        overspeeding: "",
        harshBraking: "",
        harshAccelaration: "",
        sharpCorner: "",
        distractions: "",
        otherViolations: "",
        highFatigue: "",
      },
      weekly: {
        overspeeding: "",
        harshBraking: "",
        harshAccelaration: "",
        sharpCorner: "",
        distractions: "",
        otherViolations: "",
        highFatigue: "",
      },
      monthly: {
        overspeeding: "",
        harshBraking: "",
        harshAccelaration: "",
        sharpCorner: "",
        distractions: "",
        otherViolations: "",
        highFatigue: "",
      },
      yearly: {
        overspeeding: "",
        harshBraking: "",
        harshAccelaration: "",
        sharpCorner: "",
        distractions: "",
        otherViolations: "",
        highFatigue: "",
      },
    },
  },
};
export default driverAnalytics;
