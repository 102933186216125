import { styled } from "@mui/material/styles";
export const RootContainer = styled("div")`
  padding: 10px;
  border: 1px solid #dcdada;
  border-radius: 5px;
  margin: 5px 15px;
  background: #ffffff;
  cursor: pointer;
`;
export const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between
  font-size: 13px;
  line-height: 17.7px;
  font-weight: 700;
`;
export const DefaultBody = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
`;
export const ExpandedBody = styled("div")`
  padding: 5px;
`;
export const DetailsContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const DetailItems = styled("div")`
  display: flex;
  flex-direction: column;
`;
export const DetailsContainer1 = styled("div")`
  display: flex;
  flex-direction: column;
`;
export const DetailItems1 = styled("div")`
  flex: 1;
  display: flex;
  align-items: center;
`;
export const DetailItemTitle = styled("div")`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #123d64;
`;
export const DetailItemContent = styled(DetailItemTitle)`
  color: #646464;
`;
export const BodyText = styled("div")`
  color: ${({ expanded, source }) =>
    expanded ? (source ? "#118ed4" : "#F87E3A") : "#646464"};
  font-size: 12px;
  line-height: 16.34px;
  font-weight: ${({ expanded }) => (expanded ? 600 : 400)};
`;
export const DateTimeText = styled("div")`
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
`;
export const FooterText = styled("div")`
  display: flex;
  align-items: center;
`;
export const IconText = styled("div")`
  font-size: 10px;
  line-height: 13.62px;
  font-weight: 600;
`;
export const DetailItems2 = styled("div")`
  font-size: 10px;
  line-height: 13.62px;
  font-weight: 600;
`;
export const DetailItemsValue = styled("div")`
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 700;
  color: ${({ currentLocation }) => (currentLocation ? "#2E8F1E" : "#456EFF")};
`;
export const IconTextValue = styled("div")`
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 700;
  color: #123d64;
`;

export const TitleText = styled("span")`
  margin-left: -5px;
  flex: 1;
`;

export const VideoIcon = styled("span")`
  cursor: not-allowed;
`;
