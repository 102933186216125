import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useStyles from "./styles.js";
const INF_Select = (props) => {
  const { customSelect, tableSelect, selectOptions } = useStyles();
  const {
    selectList,
    handleSelect,
    customWidth,
    customHeight,
    variant,
    selectLabel,
    disabled,
    graphName,
  } = props;
  const [selectedValue, setselectedValue] = useState(
    selectList && selectList[0]?.value
  );

  const handleChange = (e) => {
    setselectedValue(e.target.value);
    handleSelect(e.target.value, graphName);
  };

  useEffect(() => {
    if (selectList && selectList[0] && selectList[0].value !== "Day") {
      setselectedValue(selectList && selectList[0]?.value);
    }
  }, [selectList]);

  return (
    <>
      {variant === "standard" ? (
        <div className={tableSelect}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              {selectLabel}
            </InputLabel>
            <Select
              name="select"
              value={selectedValue}
              onChange={handleChange}
              disabled={disabled}
            >
              {selectList &&
                selectList.map((item) => (
                  <MenuItem className={selectOptions} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      ) : (
        <FormControl
          className={customSelect}
          style={{ width: customWidth, height: customHeight }}
        >
          <Select
            name="select"
            value={selectedValue}
            onChange={handleChange}
            disabled={disabled}
            displayEmpty
            renderValue={() => (selectedValue ? selectedValue : "")}
            // IconComponent={() => <KeyboardArrowDownIcon />}
          >
            {selectList &&
              selectList.map((item) => (
                <MenuItem key={item.label} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
export default INF_Select;
