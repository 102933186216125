export const routes = {
  GET_ROUTES_LIST: "GET_ROUTES_LIST",
  SET_ROUTES_LIST: "SET_ROUTES_LIST",
  GET_ROUTE_INFO: "GET_ROUTE_INFO",
  SET_ROUTE_INFO: "SET_ROUTE_INFO",
  GET_ROUTE_ANALYTICS: "GET_ROUTE_ANALYTICS",
  SET_ROUTE_ANALYTICS: "SET_ROUTE_ANALYTICS",
};

export const getRoutesList = (payload) => ({
  type: routes.GET_ROUTES_LIST,
  payload: payload,
});

export const setRoutesList = (routesList) => ({
  type: routes.SET_ROUTES_LIST,
  routesList,
});

export const getRouteInfo = (payload) => ({
  type: routes.GET_ROUTE_INFO,
  payload: payload,
});

export const setRouteInfo = (routeInfo) => ({
  type: routes.SET_ROUTE_INFO,
  routeInfo,
});

export const getRouteAnalytics = (payload) => ({
  type: routes.GET_ROUTE_ANALYTICS,
  payload: payload,
});

export const setRouteAnalytics = (routeAnalyticsInfo) => ({
  type: routes.SET_ROUTE_ANALYTICS,
  routeAnalyticsInfo,
});

export default routes;
