import React from "react";
import {
  RootContainer,
  TitleContainer,
  DateTimeText,
  TitleContainerElements,
} from "./styles";
import {
  FlexSpace,
  HorizontalSpace,
  VerticalSpace,
} from "elements/Space/space";

const ViolationList = (props) => {
  const {
    data: { violationType, busNo, route, timeStamp },
  } = props;

  return (
    <RootContainer>
      <TitleContainer>
        <TitleContainerElements>{`${violationType} | Bus#${busNo} | Route - ${route}`}</TitleContainerElements>
      </TitleContainer>
      <VerticalSpace count={2.8} />
      <DateTimeText>{timeStamp}</DateTimeText>
    </RootContainer>
  );
};

export default ViolationList;
