import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CloseIconImg from "../../assets/Close.svg";
import TripList from "components/TripList";
import ViolationList from "components/ViolationList";
import InfoSubList from "components/InfoSubList";
import InfoVideoTitle from "components/InfoVideoTitleContainer";
import InfoMainList from "components/InfoMainList";
import InfoDialogMap from "components/InfoDialogMap";
import Stepper from "elements/Stepper";
import BusInfo from "mockdata/busInfo";
import DriverInfo from "mockdata/driverInfo";
import RouteInfo from "mockdata/routeInfo";
import theme from "../../theme";
import useStyles from "./styles";
import SearchBox from "elements/SearchBox";
import busVideo from "assets/videos/busTrip.mp4";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiBackdrop-root": {
    marginTop: "66px !important",
  },
  "& .MuiPaper-root": {
    maxHeight: "calc(100% - 122px)",
    width: "100vw",
    maxWidth: "1722px",
    background: "#000 !important",
    color: "white",
    padding: 24,
  },
  "& .MuiDialog-container": {
    marginTop: "66px !important",
    background: "rgb(0 0 0 / 58%)",
    backdropFilter: "blur(10px)",
    height: "calc(100vh - 66px) !important",
  },
}));

const InfoDialog = (props) => {
  const {
    busDetails,
    pastViolations,
    headerStyle,
    closeButton,
    infoDialogSection,
    tripsSection,
    tripsElementSection,
    violationElementSection,
    violationSection,
    violationSearchIconBg,
    tripsSearchIconBg,
    subListRow,
    subListSection,
    tripsElementListSection,
    infoVideoTitle,
    infoListItem,
    videoMapSection,
    infoMainListSection,
    infoMainListClassName,
    infoMainListClassNameOne,
    searchClass,
    infoDialogClassName,
    pastViolationsClass,
  } = useStyles();

  const {
    dialogBoxTabIndexData,
    handleViewAll,
    infoVideoTitleListData,
    licensePlateNo,
    coOrdinates,
    tabIndex,
  } = props;

  const [open, setOpen] = useState(!false);

  const [infoDialogTripList, setInfoDialogTripList] = useState(
    BusInfo?.busInfo?.trips
  );

  const [infoDialogViolationsList, setInfoDialogViolationsList] = useState(
    BusInfo?.busInfo?.pastViolations
  );

  const [selectedTrip, setSelectedTrip] = useState(0);

  const [routeDetails, setRouteDetails] = useState({});
  const [tripStatus, setTripStatus] = useState("");

  const handleClose = () => {
    setOpen(!open);
    handleViewAll();
  };

  useEffect(() => {
    switch (dialogBoxTabIndexData) {
      case 0:
        setInfoDialogTripList(null);
        setInfoDialogViolationsList(null);

        break;
      case 1:
        setInfoDialogTripList(BusInfo?.busInfo?.trips);
        setInfoDialogViolationsList(BusInfo?.busInfo?.pastViolations);

        break;
      case 2:
        setInfoDialogTripList(DriverInfo?.driverInfo?.trips);
        setInfoDialogViolationsList(DriverInfo?.driverInfo?.pastViolations);

        break;
      case 3:
        setInfoDialogTripList(RouteInfo?.routesInfo?.trips);
        setInfoDialogViolationsList(RouteInfo?.routesInfo?.pastViolations);

        break;
      default:
        break;
    }
  }, [dialogBoxTabIndexData]);

  const handleIsSelectedTrip = (index, routeDetails, tripStatus) => {
    const details = [...routeDetails?.routeStops?.stoppages];
    if (tripStatus === "Completed") {
      details.splice(1, 1);
      const stops = routeDetails?.routeStops;
      const finalRoutes = {
        ...routeDetails,
        routeStops: {
          ...stops,
          stoppages: details,
        },
      };

      setRouteDetails(finalRoutes);
    } else {
      setRouteDetails(routeDetails);
    }
    setTripStatus(tripStatus);
    setSelectedTrip(index);
  };

  useEffect(() => {
    const status = infoDialogTripList && infoDialogTripList[0]?.tripStatus;
    setTripStatus(status);
  }, []);
  useEffect(() => {
    const routeDetails =
      infoDialogTripList && infoDialogTripList[0]?.routeDetails;
    const details = [...routeDetails?.routeStops?.stoppages];
    if (tripStatus === "Completed") {
      details.splice(1, 1);
      const stops = routeDetails?.routeStops;
      const finalRoutes = {
        ...routeDetails,
        routeStops: {
          ...stops,
          stoppages: details,
        },
      };
      setRouteDetails(finalRoutes);
    } else {
      setRouteDetails(routeDetails);
    }
  }, [tripStatus]);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [searchIsOpenOne, setSearchIsOpenTwo] = useState(false);

  const handleSearchIsOpenTripList = () => {
    setSearchIsOpen(!searchIsOpen);
  };
  const handleSearchIsOpenViolationList = () => {
    setSearchIsOpenTwo(!searchIsOpenOne);
  };

  return (
    <>
      <DialogWrapper open={open} className={infoDialogSection}>
        <div className={headerStyle}>Bus#123</div>
        <div className={closeButton}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 12,
              color: theme.palette.white,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Grid container className={busDetails}>
          <Grid item xs={12} sm={12} md={9.5} lg={9.5} xl={9.5}>
            <Grid container className={subListSection}>
              <Grid item xs={12} className={subListRow}>
                <InfoSubList
                  licensePlateNo={BusInfo?.busInfo?.licensePlateNo}
                  regionDepot={BusInfo?.busInfo?.regionDepo}
                  totalTrips={BusInfo?.busInfo?.totalTrips}
                  safetyScore={BusInfo?.busInfo?.safetyScore}
                  totalPassengers={BusInfo?.busInfo?.totalPassengers}
                  totalMiles={BusInfo?.busInfo?.totalMiles}
                  avgFuelEconomy={BusInfo?.busInfo?.avgFuelEconomy}
                  nextMaintanance={BusInfo?.busInfo?.nextMaintanance}
                  highlighted={"safetyScore"}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={3.4} lg={3.4} xl={3.4}>
                    <div className={tripsSection}>
                      <div className={tripsElementSection}>
                        <div>
                          <span>Trips</span>
                        </div>
                        <div className={tripsSearchIconBg}>
                          {!searchIsOpen ? (
                            <SearchOutlinedIcon
                              fontSize="medium"
                              onClick={handleSearchIsOpenTripList}
                            />
                          ) : (
                            <img
                              src={CloseIconImg}
                              alt="search icon"
                              onClick={handleSearchIsOpenTripList}
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        {searchIsOpen && (
                          <SearchBox
                            searchInput={infoDialogClassName}
                            placeHolder={"Search"}
                            // handleSearch={handleSearch}
                            borderRadius={2}
                            borderColor={"1px solid #DCDADA"}
                            searchIsOpen={searchIsOpen}
                          />
                        )}
                      </div>

                      <div
                        className={tripsElementListSection}
                        style={{
                          height: !searchIsOpen
                            ? "calc(100vh - 415px)"
                            : "calc(100vh - 472px)",
                        }}
                      >
                        {infoDialogTripList &&
                        infoDialogTripList?.length > 0 ? (
                          infoDialogTripList?.map((item, index) => {
                            return (
                              <TripList
                                data={item}
                                index={index}
                                handleIsSelected={handleIsSelectedTrip}
                                selected={selectedTrip}
                              />
                            );
                          })
                        ) : (
                          <div>
                            <span style={{ padding: 10, color: "gray" }}>
                              No Result Found
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2.6} lg={2.6} xl={2.6}>
                    <div>
                      <Stepper
                        routeDetails={routeDetails}
                        tripStatus={tripStatus}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={videoMapSection}>
                      <div className={infoVideoTitle}>
                        <InfoVideoTitle
                          dialogBoxTabIndexData={dialogBoxTabIndexData}
                          infoVideoTitleListData={infoVideoTitleListData}
                        />
                      </div>
                      <InfoMainList
                        infoMainListClassNameOne={infoMainListClassNameOne}
                        infoMainListClassName={infoMainListClassName}
                        source={busVideo}
                        tabIndex={tabIndex}
                        speedometers={[]}
                      />
                      <div
                        style={{ overflowY: "hidden" }}
                        className={infoListItem}
                      >
                        <InfoDialogMap coOrdinates={coOrdinates} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
            <div className={violationSection}>
              <div className={violationElementSection}>
                <div>
                  <span>Past Violations</span>
                </div>
                <div className={violationSearchIconBg}>
                  {!searchIsOpenOne ? (
                    <SearchOutlinedIcon
                      fontSize="medium"
                      onClick={handleSearchIsOpenViolationList}
                    />
                  ) : (
                    <img
                      src={CloseIconImg}
                      alt="search icon"
                      onClick={handleSearchIsOpenViolationList}
                    />
                  )}
                </div>
              </div>
              <div>
                {searchIsOpenOne && (
                  <SearchBox
                    searchInput={pastViolationsClass}
                    placeHolder={"Search"}
                    // handleSearch={handleSearch}
                    borderRadius={2}
                    borderColor={"1px solid #DCDADA"}
                    searchIsOpenOne={searchIsOpenOne}
                  />
                )}
              </div>
              {infoDialogViolationsList &&
              infoDialogViolationsList?.length > 0 ? (
                infoDialogViolationsList?.map((item, index) => {
                  return <ViolationList data={item} key={index} />;
                })
              ) : (
                <div>
                  <span style={{ padding: 10, color: "gray" }}>
                    No Result Found
                  </span>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogWrapper>
    </>
  );
};

export default InfoDialog;
