export const buses = {
  GET_BUSES_LIST: "GET_BUSES_LIST",
  SET_BUSES_LIST: "SET_BUSES_LIST",
  GET_BUS_INFO: "GET_BUS_INFO",
  SET_BUS_INFO: "SET_BUS_INFO",
  GET_BUS_ANALYTICS: "GET_BUS_ANALYTICS",
  SET_BUS_ANALYTICS: "SET_BUS_ANALYTICS",
};

export const getBusesList = (payload) => ({
  type: buses.GET_BUSES_LIST,
  payload: payload,
});

export const setBusesList = (busesList) => ({
  type: buses.SET_BUSES_LIST,
  busesList,
});

export const getBusInfo = (payload) => ({
  type: buses.GET_BUS_INFO,
  payload: payload,
});

export const setBusInfo = (busInfo) => ({
  type: buses.SET_BUS_INFO,
  busInfo,
});

export const getBusAnalytics = (payload) => ({
  type: buses.GET_BUS_ANALYTICS,
  payload: payload,
});

export const setBusAnalytics = (busAnalyticsInfo) => ({
  type: buses.SET_BUS_ANALYTICS,
  busAnalyticsInfo,
});

export default buses;
