import React from "react";
import VideoPlayer from "elements/VideoPlayer";
import SpeedoMeter from "elements/SpeedoMeter";
import { SpeedometersContainer } from "./styles";
import theme from "theme";

const InfoMainList = (props) => {
  const {
    tabIndex,
    source,
    speedometers,
    infoMainListClassName,
    infoMainListClassNameOne,
  } = props;

  return (
    <>
      {tabIndex === 1 ? (
        <div className={infoMainListClassNameOne}>
          <VideoPlayer src={source} />
        </div>
      ) : (
        <div className={infoMainListClassName}>
          <SpeedometersContainer>
            <SpeedoMeter
              radius={50}
              currentValue={75}
              totalValue={110}
              label="Driving Score"
              icon="driving-score"
              rotate={112}
              cut={40}
              color="#74BA69"
              thumbType="point"
              strokeWidth={10}
              trackStrokeWidth={10}
              trackStrokeColor={theme.palette.speedometerTrackStrokeColor}
            />
            <SpeedoMeter
              radius={50}
              currentValue={75}
              totalValue={110}
              label="Harsh Acceleration"
              icon="harsh-acceleration"
              rotate={110}
              cut={40}
              color="#74BA69"
              thumbType="point"
              strokeWidth={10}
              trackStrokeWidth={10}
              trackStrokeColor={theme.palette.speedometerTrackStrokeColor}
            />
            <SpeedoMeter
              radius={50}
              currentValue={75}
              totalValue={110}
              label="Corner"
              icon="corner"
              rotate={110}
              cut={40}
              color="#74BA69"
              thumbType="point"
              strokeWidth={10}
              trackStrokeWidth={10}
              trackStrokeColor={theme.palette.speedometerTrackStrokeColor}
            />
            <SpeedoMeter
              radius={50}
              currentValue={75}
              totalValue={110}
              label="Over Speeding"
              icon="overspeeding"
              rotate={110}
              cut={40}
              color="#F04F4F"
              thumbType="point"
              strokeWidth={10}
              trackStrokeWidth={10}
              trackStrokeColor={theme.palette.speedometerTrackStrokeColor}
            />
            <SpeedoMeter
              radius={50}
              currentValue={75}
              totalValue={110}
              label="Harsh Braking"
              icon="harsh-braking"
              rotate={110}
              cut={40}
              color="#74BA69"
              thumbType="point"
              strokeWidth={10}
              trackStrokeWidth={10}
              trackStrokeColor={theme.palette.speedometerTrackStrokeColor}
            />
            <SpeedoMeter
              radius={50}
              currentValue={75}
              totalValue={110}
              label="Idle Hours"
              icon="idle-hours"
              rotate={110}
              cut={40}
              color="#74BA69"
              thumbType="point"
              strokeWidth={10}
              trackStrokeWidth={10}
              trackStrokeColor={theme.palette.speedometerTrackStrokeColor}
            />
            <SpeedoMeter
              radius={50}
              currentValue={75}
              totalValue={110}
              label="Fatigue"
              icon="fatigue"
              rotate={110}
              cut={40}
              color="#74BA69"
              thumbType="thumb"
              strokeWidth={10}
              trackStrokeWidth={10}
              trackStrokeColor={theme.palette.speedometerTrackStrokeColor}
            />
          </SpeedometersContainer>
        </div>
      )}
    </>
  );
};

export default InfoMainList;
