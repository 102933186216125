import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  customSelect: {
    minWidth: "100px !important",
    minheight: "5px !important",
    "& .MuiSelect-select": {
      padding: "10px 11px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "50px",
      fontSize: 14,
      textAlign: "center",
      fontWeight: "bold",
      position: "relative",
      "& .MuiSvgIcon-root": {
        position: "absolute",
        right: "10px",
      },
    },
  },
  tableSelect: {
    "& .MuiInputBase-root": {
      marginTop: 12,
    },
    "& .MuiSelect-select": {
      padding: "0 30px 0 0 !important",
      color: "white !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none !important",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    "& .MuiInput-root": {
      color: "white",
    },
    "& .MuiInput-root:before": {
      display: "none",
    },
    "& .MuiInput-root:after": {
      display: "none",
    },
  },
},{index: 1});
export default useStyles;