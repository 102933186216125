import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AnalyticsList from "components/AnalyticsList";
import AnalyticsTabBar from "components/AnalyticsTabBar";
import { getBusAnalytics } from "redux/actions/busesActions";
import { getDriverAnalytics } from "redux/actions/driversActions";
import { getRouteAnalytics } from "redux/actions/routesActions";
import { formattedAnalyticsListData } from "utils/utils";
import InfoIcon from "elements/InfoIcon";
import CircularAnalytics from "elements/CircularAnalytics";
import upArrow from "../../assets/icons/up-arrow.svg";
import sensexIcon from "../../assets/icons/sensexIcon.svg";
import upArrowDown from "../../assets/icons/up-arrow-red.svg";
import RevenueIcon from "../../assets/revenue.svg";
import FuelIcon from "../../assets/fuel.svg";
import ClockIcon from "../../assets/clock.svg";
import Co2Icon from "../../assets/co2.svg";
import TripIcon from "../../assets/trip.svg";
import MilesIcon from "../../assets/miles.svg";
import CostIcon from "../../assets/cost.svg";
import DrivingScoreIcon from "../../assets/drivingScore.svg";
import PassengerIcon from "../../assets/totalPassenger.svg";
import StopIcon from "../../assets/stops.svg";
import BusIcon from "../../assets/avgBus.svg";
import AlertsIcon from "../../assets/alerts.svg";
import theme from "../../theme";
import useStyles from "./styles";
import AnalyticsGraphContainer from "components/AnalyticsGraphContainer";
import Select from "elements/Select";

function AnalyticsContainer() {
  const {
    analyticsRow,
    graphSection,
    incomeText,
    incomeCurrentText,
    incomeCurrentTextDot,
    incomeCurrentSection,
    incomeLastTextDot,
    avgIncomeText,
    followersText,
    followersPercentage,
    followersPercentageRed,
    arrow,
    followersTextBlue,
    followersTextGreen,
    followersTextGrey,
    analyticsContainerSection,
    analyticsGridSection,
    flex1,
    analyticsGrid,
    infoIconClass,
    analyticsList,
    customSelectButton,
    selectButton,
    analyticsRightColumn,
    expenseGreenChip,
    expenseFollowers,
    expenseFuel,
    expenseList,
    innerExpenseListDriver,
    expensePinkChip,
    expenseBlueChip,
    graphExpenseSection,
    expenseListDriver,
    expenseCircleChip,
    expenseFuelDriver,
    innerExpenseList,
    expenseDriverFollowers,
    analyticsGridList,
    expenseCircleChipLightBlue5,
    expenseCircleChipLightPink1,
    expenseCircleChipLightRed2,
    expenseCircleChipDarkOrange,
    expenseCircleChipPurpleOne,
  } = useStyles();

  const dispatch = useDispatch();

  const busesList = useSelector((state) => state.buses.busAnalyticsInfo);
  const driversList = useSelector((state) => state.drivers.driverAnalyticsInfo);
  const routesList = useSelector((state) => state.routes.routeAnalyticsInfo);

  useEffect(() => {
    dispatch(getBusAnalytics());
    dispatch(getDriverAnalytics());
    dispatch(getRouteAnalytics());
  }, []);

  const tabList = [
    {
      label: "BUSES",
      value: "buses",
      badge: busesList?.busAnalytics?.length,
    },
    {
      label: "DRIVERS",
      value: "drivers",
      badge: driversList?.driverAnalytics?.length,
    },
    {
      label: "ROUTES",
      value: "routes",
      badge: routesList?.routeAnalytics?.length,
    },
  ];

  const [cardList, setCardList] = useState([]);
  const [currentTab, setCurrentTab] = useState("buses");
  const [circularData, setCircularData] = useState([]);
  const [circularInnerText, setCircularInnerText] = useState();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedListItem, setSelectedListItem] = useState(
    currentTab === "buses"
      ? busesList?.overAllAnalytics?.generalStats?.day
      : currentTab === "drivers"
      ? driversList?.overAllAnalytics?.generalStats?.day
      : routesList?.overAllAnalytics?.generalStats?.day
  );

  const [handleSelectedListItemData, setHandleSelectedListItemData] = useState(
    currentTab === "buses"
      ? busesList?.overAllAnalytics
      : currentTab === "drivers"
      ? driversList?.overAllAnalytics
      : routesList?.overAllAnalytics
  );

  const [incomeAnalyticsGraphData, setIncomeAnalyticsGraphData] = useState();

  const [
    distanceTravelledAnalyticsGraphData,
    setDistanceTravelledAnalyticsGraphData,
  ] = useState();

  const [fuelConsumedAnalyticsGraphData, setFuelConsumedAnalyticsGraphData] =
    useState();

  const [
    travellingHoursAnalyticsGraphData,
    setTravellingHoursAnalyticsGraphData,
  ] = useState();

  const [co2EmissionAnalyticsGraphData, setCo2EmissionAnalyticsGraphData] =
    useState();

  const [graph1ObjDataFormate, setGraph1ObjDataFormate] = useState("day");
  const [graph2ObjDataFormate, setGraph2ObjDataFormate] = useState("day");
  const [graph3ObjDataFormate, setGraph3ObjDataFormate] = useState("day");
  const [graph4ObjDataFormate, setGraph4ObjDataFormate] = useState("day");
  const [graph5ObjDataFormate, setGraph5ObjDataFormate] = useState("day");

  const handleCurrentTab = (currentTab) => {
    setCurrentTab(currentTab);
    setCurrentIndex(null);
  };

  useEffect(() => {
    switch (currentTab) {
      case "buses":
        setCardList(formattedAnalyticsListData(currentTab, busesList));
        break;
      case "drivers":
        setCardList(formattedAnalyticsListData(currentTab, driversList));
        break;
      case "routes":
        setCardList(formattedAnalyticsListData(currentTab, routesList));
        break;
      default:
        break;
    }
    handleAnalytics();
  }, [currentTab]);

  useEffect(() => {
    if (currentTab === "buses") {
      setCircularData([
        { title: "Fuel", value: 28, color: theme.palette.lightGreenOne },
        { title: "Maintenance", value: 20, color: theme.palette.lightBlueFive },
        { title: "Others", value: 20, color: theme.palette.lightPinkOne },
      ]);
      setCircularInnerText("Total Expenses");
    } else if (currentTab === "drivers") {
      setCircularData([
        {
          title: "Overspeeding",
          value: 431,
          color: theme.palette.lightBlueSix,
        },
        {
          title: "Harsh Braking",
          value: 365,
          color: theme.palette.lightBlueFive,
        },
        {
          title: "Harsh Acceleration",
          value: 204,
          color: theme.palette.lightPinkOne,
        },
        {
          title: "Sharp Corner",
          value: 247,
          color: theme.palette.lightBlueSix,
        },
        {
          title: "Distractions",
          value: 257,
          color: theme.palette.red,
        },
        {
          title: "Other Violations",
          value: 314,
          color: theme.palette.darkOrange,
        },
        {
          title: "High Fatigue",
          value: 347,
          color: theme.palette.purple,
        },
      ]);
      setCircularInnerText("Violations");
    } else if (currentTab === "routes") {
      setCircularData([
        { title: "Visited", value: 125, color: theme.palette.lightBlueOne },
        { title: "Not Visited ", value: 75, color: theme.palette.lightPinkOne },
      ]);
      setCircularInnerText("Total Stops");
    }
  }, [currentTab]);

  const handleCurrentIndex = (index) => {
    setCurrentIndex(index);
  };

  const handleAnalytics = () => {
    setCurrentIndex(null);
    setSelectedListItem(
      currentTab === "buses"
        ? busesList?.overAllAnalytics?.generalStats?.day
        : currentTab === "drivers"
        ? driversList?.overAllAnalytics?.generalStats?.day
        : routesList?.overAllAnalytics?.generalStats?.day
    );

    setIncomeAnalyticsGraphData(
      currentTab === "buses"
        ? busesList?.overAllAnalytics?.incomeStats[graph1ObjDataFormate]
            ?.analyticsData
        : currentTab === "drivers"
        ? driversList?.overAllAnalytics?.tripStats[graph1ObjDataFormate]
            ?.analyticsData
        : routesList?.overAllAnalytics?.incomeStats[graph1ObjDataFormate]
            ?.analyticsData
    );

    setDistanceTravelledAnalyticsGraphData(
      currentTab === "buses"
        ? busesList?.overAllAnalytics?.distanceTravelledStats[
            graph2ObjDataFormate
          ]?.analyticsData
        : currentTab === "drivers"
        ? driversList?.overAllAnalytics?.avgDistanceDrivenStats[
            graph2ObjDataFormate
          ]?.analyticsData
        : routesList?.overAllAnalytics?.passengersStats[graph2ObjDataFormate]
            ?.analyticsData
    );

    setFuelConsumedAnalyticsGraphData(
      currentTab === "buses"
        ? busesList?.overAllAnalytics?.fuelConsumedStats[graph3ObjDataFormate]
            ?.analyticsData
        : currentTab === "drivers"
        ? driversList?.overAllAnalytics?.labourCostStats[graph3ObjDataFormate]
            ?.analyticsData
        : routesList?.overAllAnalytics?.fuelEconomyStats[graph3ObjDataFormate]
            ?.analyticsData
    );

    setTravellingHoursAnalyticsGraphData(
      currentTab === "buses"
        ? busesList?.overAllAnalytics?.travellingHoursStats[
            graph4ObjDataFormate
          ]?.analyticsData
        : currentTab === "drivers"
        ? driversList?.overAllAnalytics?.driveTimeStats[graph4ObjDataFormate]
            ?.analyticsData
        : routesList?.overAllAnalytics?.avgBusesStats[graph4ObjDataFormate]
            ?.analyticsData
    );

    setCo2EmissionAnalyticsGraphData(
      currentTab === "buses"
        ? busesList?.overAllAnalytics?.co2EmissionStats[graph5ObjDataFormate]
            ?.analyticsData
        : currentTab === "routes"
        ? routesList?.overAllAnalytics?.top5BusyRouteStats?.analytics[
            graph5ObjDataFormate
          ]
        : null
    );
  };

  const handleSelectedListItem = (item) => {
    setHandleSelectedListItemData(item);
    setSelectedListItem(item?.generalStats?.day);

    setIncomeAnalyticsGraphData(
      currentTab === "buses"
        ? item?.incomeStats[graph1ObjDataFormate]?.analyticsData
        : currentTab === "drivers"
        ? item?.tripStats[graph1ObjDataFormate]?.analyticsData
        : item?.incomeStats[graph1ObjDataFormate]?.analyticsData
    );

    setDistanceTravelledAnalyticsGraphData(
      currentTab === "buses"
        ? item?.distanceTravelledStats[graph2ObjDataFormate]?.analyticsData
        : currentTab === "drivers"
        ? item?.avgDistanceDrivenStats[graph2ObjDataFormate]?.analyticsData
        : item?.passengersStats[graph2ObjDataFormate]?.analyticsData
    );

    setFuelConsumedAnalyticsGraphData(
      currentTab === "buses"
        ? item?.fuelConsumedStats[graph3ObjDataFormate]?.analyticsData
        : currentTab === "drivers"
        ? item?.labourCostStats[graph3ObjDataFormate]?.analyticsData
        : item?.fuelEconomyStats[graph3ObjDataFormate]?.analyticsData
    );

    setTravellingHoursAnalyticsGraphData(
      currentTab === "buses"
        ? item?.travellingHoursStats[graph4ObjDataFormate]?.analyticsData
        : currentTab === "drivers"
        ? item?.driveTimeStats[graph4ObjDataFormate]?.analyticsData
        : item?.avgBusesStats[graph4ObjDataFormate]?.analyticsData
    );

    setCo2EmissionAnalyticsGraphData(
      currentTab === "buses"
        ? item?.co2EmissionStats[graph5ObjDataFormate]?.analyticsData
        : currentTab === "routes"
        ? item?.top5BusyRouteStats?.analytics[graph5ObjDataFormate]
        : null
    );
  };

  useEffect(() => {
    getIncomeStatsAnalyticsData();
    getDistanceTravelledStatsAnalyticsData();
    getFuelConsumedStatsAnalyticsData();
    getTravellingHoursStatsAnalyticsData();
    if (currentTab !== "drivers") {
      getCo2EmissionStatsAnalyticsData();
    }
  }, [
    incomeAnalyticsGraphData,
    distanceTravelledAnalyticsGraphData,
    fuelConsumedAnalyticsGraphData,
    travellingHoursAnalyticsGraphData,
    co2EmissionAnalyticsGraphData,
  ]);

  const [incomeStatsAnalyticsGraphData, setIncomeStatsAnalyticsGraphData] =
    useState([]);
  const [
    distanceTravelledStatsAnalyticsGraphData,
    setDistanceTravelledStatsAnalyticsGraphData,
  ] = useState([]);
  const [
    fuelConsumedStatsAnalyticsGraphData,
    setFuelConsumedStatsAnalyticsGraphData,
  ] = useState([]);
  const [
    travellingHoursStatsAnalyticsGraphData,
    setTravellingHoursStatsAnalyticsGraphData,
  ] = useState([]);
  const [
    co2EmissionStatsAnalyticsGraphData,
    setCo2EmissionStatsAnalyticsGraphData,
  ] = useState([]);

  const getIncomeStatsAnalyticsData = () => {
    let data;
    if (currentTab === "buses" || currentTab === "routes") {
      data = [
        {
          data: incomeStatsgraphApiData("Current Year"),
          name: "Current Year",
          color: "#949FFF",
          lineColor: "#949FFF",
        },
        {
          data: incomeStatsgraphApiData("Last Year"),
          name: "Last Year",
          color: "#70CDCD",
          lineColor: "#4ac0c0",
        },
      ];
    } else if (currentTab === "drivers") {
      data = [
        {
          data: incomeStatsgraphApiData(),
          name: "Current Year",
          lineColor: "#0C8ACC",
        },
      ];
    }

    setIncomeStatsAnalyticsGraphData(data);
  };

  const getDistanceTravelledStatsAnalyticsData = () => {
    const data = [
      {
        data: distanceTravelledStatsgraphApiData(),

        color: "#8CB4FF",
      },
    ];
    setDistanceTravelledStatsAnalyticsGraphData(data);
  };

  const getFuelConsumedStatsAnalyticsData = () => {
    const data = [
      {
        data: fuelConsumedStatsgraphApiData(),

        lineColor:
          currentTab === "buses" || currentTab === "routes"
            ? "#00A3FF"
            : "#FABB70",
      },
    ];
    setFuelConsumedStatsAnalyticsGraphData(data);
  };

  const getTravellingHoursStatsAnalyticsData = () => {
    let data;
    if (currentTab === "buses" || currentTab === "drivers") {
      data = [
        {
          data: travellingHoursStatsgraphApiData("Running Hours"),
          name: "Running Hours",
          lineColor: "#65D6CF",
        },
        {
          data: travellingHoursStatsgraphApiData("Idle Hours"),
          name: "Idle Hours",
          lineColor: "#E5456F",
        },
      ];
    } else if (currentTab === "routes") {
      data = [
        {
          data: travellingHoursStatsgraphApiData(),
          name: "Running Hours",
          lineColor: "#65D6CF",
        },
      ];
    }

    setTravellingHoursStatsAnalyticsGraphData(data);
  };

  const getCo2EmissionStatsAnalyticsData = () => {
    let data;
    if (currentTab === "buses") {
      data = [
        {
          data: co2EmissionStatsgraphApiData(),

          color: "#949FFF",
        },
      ];
    } else if (currentTab === "routes") {
      data = [
        {
          data: co2EmissionStatsgraphApiData(),

          color: "#FF7A7A",
        },
      ];
    }

    setCo2EmissionStatsAnalyticsGraphData(data);
  };

  const incomeStatsgraphApiData = (yearType) => {
    let dayDataNew = [];
    if (incomeAnalyticsGraphData) {
      let graphPointsList = incomeAnalyticsGraphData;

      if (graphPointsList) {
        if (yearType) {
          for (let i = 0; i < graphPointsList.length; i++) {
            if (
              graphPointsList[i] &&
              (yearType === "Current Year" || yearType === "Last Year") &&
              graphPointsList[i].metricName === yearType
            ) {
              {
                if (graphPointsList[i].analytics)
                  for (
                    let j = 0;
                    j < graphPointsList[i].analytics.length;
                    j++
                  ) {
                    dayDataNew.push([
                      new Date(graphPointsList[i].analytics[j].node).getTime(),
                      graphPointsList[i].analytics[j].count,
                    ]);
                  }
              }
            }
          }
        } else {
          for (let i = 0; i < graphPointsList.length; i++) {
            dayDataNew.push([
              new Date(graphPointsList[i]?.node).getTime(),
              graphPointsList[i]?.count,
            ]);
          }
        }
      }
    }
    return dayDataNew;
  };

  const distanceTravelledStatsgraphApiData = () => {
    let dayDataNew = [];
    if (distanceTravelledAnalyticsGraphData) {
      let graphPointsList = distanceTravelledAnalyticsGraphData;

      if (graphPointsList) {
        for (let i = 0; i < graphPointsList.length; i++) {
          dayDataNew.push([
            new Date(graphPointsList[i]?.node).getTime(),
            graphPointsList[i]?.count,
          ]);
        }
      }
    }

    return dayDataNew;
  };

  const fuelConsumedStatsgraphApiData = () => {
    let dayDataNew = [];
    if (fuelConsumedAnalyticsGraphData) {
      let graphPointsList = fuelConsumedAnalyticsGraphData;

      if (graphPointsList) {
        for (let i = 0; i < graphPointsList.length; i++) {
          dayDataNew.push([
            new Date(graphPointsList[i]?.node).getTime(),
            graphPointsList[i]?.count,
          ]);
        }
      }
    }
    return dayDataNew;
  };

  const travellingHoursStatsgraphApiData = (yearType) => {
    let dayDataNew = [];
    if (travellingHoursAnalyticsGraphData) {
      let graphPointsList = travellingHoursAnalyticsGraphData;

      if (graphPointsList) {
        if (yearType) {
          for (let i = 0; i < graphPointsList.length; i++) {
            if (
              graphPointsList[i] &&
              (yearType === "Running Hours" || yearType === "Idle Hours") &&
              graphPointsList[i].metricName === yearType
            ) {
              {
                if (graphPointsList[i].analytics)
                  for (
                    let j = 0;
                    j < graphPointsList[i].analytics.length;
                    j++
                  ) {
                    dayDataNew.push([
                      new Date(graphPointsList[i].analytics[j].node).getTime(),
                      graphPointsList[i].analytics[j].count,
                    ]);
                  }
              }
            }
          }
        } else {
          for (let i = 0; i < graphPointsList.length; i++) {
            dayDataNew.push([
              new Date(graphPointsList[i]?.node).getTime(),
              graphPointsList[i]?.count,
            ]);
          }
        }
      }
    }
    return dayDataNew;
  };

  const co2EmissionStatsgraphApiData = (yearType) => {
    let dayDataNew = [];
    if (co2EmissionAnalyticsGraphData) {
      let graphPointsList = co2EmissionAnalyticsGraphData;

      if (graphPointsList) {
        for (let i = 0; i < graphPointsList.length; i++) {
          dayDataNew.push([
            new Date(graphPointsList[i]?.node).getTime(),
            graphPointsList[i]?.count,
          ]);
        }
      }
    }
    return dayDataNew;
  };

  const selectList = [
    { label: "Day", value: "Day" },
    { label: "Week", value: "Week" },
    { label: "Month", value: "Month" },
    { label: "Year", value: "Year" },
  ];
  const monthFomrat = "{value:%m/%e}";
  const dayFormat = "{value:%H:00}";
  const yearFormat = "{value:%b}";
  const [formatGraph1, setFormatGraph1] = useState(dayFormat);
  const [formatGraph2, setFormatGraph2] = useState(dayFormat);
  const [formatGraph3, setFormatGraph3] = useState(dayFormat);
  const [formatGraph4, setFormatGraph4] = useState(dayFormat);
  const [formatGraph5, setFormatGraph5] = useState(dayFormat);

  const handleSelect = (val, graphName) => {
    if (graphName === "graph1") {
      switch (val) {
        case "Day":
          setGraph1ObjDataFormate("day");
          setFormatGraph1(dayFormat);
          setIncomeAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.incomeStats?.day?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.tripStats?.day?.analyticsData
                : routesList?.overAllAnalytics?.incomeStats?.day?.analyticsData
              : currentTab === "buses" || currentTab === "routes"
              ? handleSelectedListItemData?.incomeStats?.day?.analyticsData
              : handleSelectedListItemData?.tripStats?.day?.analyticsData
          );

          break;
        case "Week":
          setGraph1ObjDataFormate("weekly");
          setFormatGraph1(monthFomrat);
          setIncomeAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.incomeStats?.weekly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.tripStats?.weekly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.incomeStats?.weekly
                    ?.analyticsData
              : currentTab === "buses" || currentTab === "routes"
              ? handleSelectedListItemData?.incomeStats?.weekly?.analyticsData
              : handleSelectedListItemData?.tripStats?.weekly?.analyticsData
          );
          break;
        case "Month":
          setGraph1ObjDataFormate("monthly");
          setFormatGraph1(monthFomrat);
          setIncomeAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.incomeStats?.monthly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.tripStats?.monthly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.incomeStats?.monthly
                    ?.analyticsData
              : currentTab === "buses" || currentTab === "routes"
              ? handleSelectedListItemData?.incomeStats?.monthly?.analyticsData
              : handleSelectedListItemData?.tripStats?.monthly?.analyticsData
          );
          break;
        case "Year":
          setGraph1ObjDataFormate("yearly");
          setFormatGraph1(yearFormat);
          setIncomeAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.incomeStats?.yearly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.tripStats?.yearly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.incomeStats?.yearly
                    ?.analyticsData
              : currentTab === "buses" || currentTab === "routes"
              ? handleSelectedListItemData?.incomeStats?.yearly?.analyticsData
              : handleSelectedListItemData?.tripStats?.yearly?.analyticsData
          );
          break;
        default:
          setFormatGraph1(dayFormat);
          setIncomeAnalyticsGraphData(
            currentTab === "buses" || currentTab === "routes"
              ? handleSelectedListItemData?.incomeStats?.day?.analyticsData
              : handleSelectedListItemData?.tripStats?.day?.analyticsData
          );
          break;
      }
    }
    if (graphName === "graph2") {
      switch (val) {
        case "Day":
          setGraph2ObjDataFormate("day");
          setFormatGraph2(dayFormat);
          setDistanceTravelledAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.distanceTravelledStats?.day
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.avgDistanceDrivenStats?.day
                    ?.analyticsData
                : routesList?.overAllAnalytics?.passengersStats?.day
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.distanceTravelledStats?.day
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.avgDistanceDrivenStats?.day
                  ?.analyticsData
              : handleSelectedListItemData?.passengersStats?.day?.analyticsData
          );
          break;
        case "Week":
          setGraph2ObjDataFormate("weekly");
          setFormatGraph2(monthFomrat);
          setDistanceTravelledAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.distanceTravelledStats?.weekly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.avgDistanceDrivenStats?.weekly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.passengersStats?.weekly
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.distanceTravelledStats?.weekly
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.avgDistanceDrivenStats?.weekly
                  ?.analyticsData
              : handleSelectedListItemData?.passengersStats?.weekly
                  ?.analyticsData
          );
          break;
        case "Month":
          setGraph2ObjDataFormate("monthly");
          setFormatGraph2(monthFomrat);
          setDistanceTravelledAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.distanceTravelledStats?.monthly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.avgDistanceDrivenStats?.monthly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.passengersStats?.monthly
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.distanceTravelledStats?.monthly
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.avgDistanceDrivenStats?.monthly
                  ?.analyticsData
              : handleSelectedListItemData?.passengersStats?.monthly
                  ?.analyticsData
          );
          break;
        case "Year":
          setGraph2ObjDataFormate("yearly");
          setFormatGraph2(yearFormat);
          setDistanceTravelledAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.distanceTravelledStats?.yearly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.avgDistanceDrivenStats?.yearly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.passengersStats?.yearly
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.distanceTravelledStats?.yearly
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.avgDistanceDrivenStats?.yearly
                  ?.analyticsData
              : handleSelectedListItemData?.passengersStats?.yearly
                  ?.analyticsData
          );
          break;
        default:
          setFormatGraph2(dayFormat);
          setDistanceTravelledAnalyticsGraphData(
            currentTab === "buses"
              ? handleSelectedListItemData?.distanceTravelledStats?.day
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.avgDistanceDrivenStats?.day
                  ?.analyticsData
              : handleSelectedListItemData?.passengersStats?.day?.analyticsData
          );
          break;
      }
    }
    if (graphName === "graph3") {
      switch (val) {
        case "Day":
          setGraph3ObjDataFormate("day");
          setFormatGraph3(dayFormat);
          setFuelConsumedAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.fuelConsumedStats?.day
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.labourCostStats?.day
                    ?.analyticsData
                : routesList?.overAllAnalytics?.fuelEconomyStats?.day
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.fuelConsumedStats?.day
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.labourCostStats?.day?.analyticsData
              : handleSelectedListItemData?.fuelEconomyStats?.day?.analyticsData
          );
          break;
        case "Week":
          setGraph3ObjDataFormate("weekly");
          setFormatGraph3(monthFomrat);
          setFuelConsumedAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.fuelConsumedStats?.weekly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.labourCostStats?.weekly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.fuelEconomyStats?.weekly
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.fuelConsumedStats?.weekly
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.labourCostStats?.weekly
                  ?.analyticsData
              : handleSelectedListItemData?.fuelEconomyStats?.weekly
                  ?.analyticsData
          );
          break;
        case "Month":
          setGraph3ObjDataFormate("monthly");
          setFormatGraph3(monthFomrat);
          setFuelConsumedAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.fuelConsumedStats?.monthly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.labourCostStats?.monthly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.fuelEconomyStats?.monthly
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.fuelConsumedStats?.monthly
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.labourCostStats?.monthly
                  ?.analyticsData
              : handleSelectedListItemData?.fuelEconomyStats?.monthly
                  ?.analyticsData
          );
          break;
        case "Year":
          setGraph3ObjDataFormate("yearly");
          setFormatGraph3(yearFormat);
          setFuelConsumedAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.fuelConsumedStats?.yearly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.labourCostStats?.yearly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.fuelEconomyStats?.yearly
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.fuelConsumedStats?.yearly
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.labourCostStats?.yearly
                  ?.analyticsData
              : handleSelectedListItemData?.fuelEconomyStats?.yearly
                  ?.analyticsData
          );
          break;
        default:
          setFormatGraph3(dayFormat);
          setFuelConsumedAnalyticsGraphData(
            currentTab === "buses"
              ? handleSelectedListItemData?.fuelConsumedStats?.day
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.labourCostStats?.day?.analyticsData
              : handleSelectedListItemData?.fuelEconomyStats?.day?.analyticsData
          );
          break;
      }
    }
    if (graphName === "graph4") {
      switch (val) {
        case "Day":
          setGraph4ObjDataFormate("day");
          setFormatGraph4(dayFormat);
          setTravellingHoursAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.travellingHoursStats?.day
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.driveTimeStats?.day
                    ?.analyticsData
                : routesList?.overAllAnalytics?.avgBusesStats?.day
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.travellingHoursStats?.day
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.driveTimeStats?.day?.analyticsData
              : handleSelectedListItemData?.avgBusesStats?.day?.analyticsData
          );
          break;
        case "Week":
          setGraph4ObjDataFormate("weekly");
          setFormatGraph4(monthFomrat);
          setTravellingHoursAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.travellingHoursStats?.weekly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.driveTimeStats?.weekly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.avgBusesStats?.weekly
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.travellingHoursStats?.weekly
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.driveTimeStats?.weekly
                  ?.analyticsData
              : handleSelectedListItemData?.avgBusesStats?.weekly?.analyticsData
          );
          break;
        case "Month":
          setGraph4ObjDataFormate("monthly");
          setFormatGraph4(monthFomrat);
          setTravellingHoursAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.travellingHoursStats?.monthly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.driveTimeStats?.monthly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.avgBusesStats?.monthly
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.travellingHoursStats?.monthly
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.driveTimeStats?.monthly
                  ?.analyticsData
              : handleSelectedListItemData?.avgBusesStats?.monthly
                  ?.analyticsData
          );
          break;
        case "Year":
          setGraph4ObjDataFormate("yearly");
          setFormatGraph4(yearFormat);
          setTravellingHoursAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.travellingHoursStats?.yearly
                    ?.analyticsData
                : currentTab === "drivers"
                ? driversList?.overAllAnalytics?.driveTimeStats?.yearly
                    ?.analyticsData
                : routesList?.overAllAnalytics?.avgBusesStats?.yearly
                    ?.analyticsData
              : currentTab === "buses"
              ? handleSelectedListItemData?.travellingHoursStats?.yearly
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.driveTimeStats?.yearly
                  ?.analyticsData
              : handleSelectedListItemData?.avgBusesStats?.yearly?.analyticsData
          );
          break;
        default:
          setFormatGraph4(dayFormat);
          setTravellingHoursAnalyticsGraphData(
            currentTab === "buses"
              ? handleSelectedListItemData?.travellingHoursStats?.day
                  ?.analyticsData
              : currentTab === "drivers"
              ? handleSelectedListItemData?.driveTimeStats?.day?.analyticsData
              : handleSelectedListItemData?.avgBusesStats?.day?.analyticsData
          );
          break;
      }
    }
    if (graphName === "graph5") {
      switch (val) {
        case "Day":
          setGraph5ObjDataFormate("day");
          setFormatGraph5(dayFormat);
          setCo2EmissionAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.co2EmissionStats?.day
                    ?.analyticsData
                : currentTab === "routes"
                ? routesList?.overAllAnalytics?.top5BusyRouteStats?.analytics
                    ?.day
                : null
              : currentTab === "buses"
              ? handleSelectedListItemData?.co2EmissionStats?.day?.analyticsData
              : currentTab === "routes"
              ? handleSelectedListItemData?.top5BusyRouteStats?.analytics?.day
              : null
          );

          break;
        case "Week":
          setGraph5ObjDataFormate("weekly");
          setFormatGraph5(monthFomrat);
          setCo2EmissionAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.co2EmissionStats?.weekly
                    ?.analyticsData
                : currentTab === "routes"
                ? routesList?.overAllAnalytics?.top5BusyRouteStats?.analytics
                    ?.weekly
                : null
              : currentTab === "buses"
              ? handleSelectedListItemData?.co2EmissionStats?.weekly
                  ?.analyticsData
              : currentTab === "routes"
              ? handleSelectedListItemData?.top5BusyRouteStats?.analytics
                  ?.weekly
              : null
          );
          break;
        case "Month":
          setGraph5ObjDataFormate("monthly");
          setFormatGraph5(monthFomrat);
          setCo2EmissionAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.co2EmissionStats?.monthly
                    ?.analyticsData
                : currentTab === "routes"
                ? routesList?.overAllAnalytics?.top5BusyRouteStats?.analytics
                    ?.monthly
                : null
              : currentTab === "buses"
              ? handleSelectedListItemData?.co2EmissionStats?.monthly
                  ?.analyticsData
              : currentTab === "routes"
              ? handleSelectedListItemData?.top5BusyRouteStats?.analytics
                  ?.monthly
              : null
          );
          break;
        case "Year":
          setGraph5ObjDataFormate("yearly");
          setFormatGraph5(yearFormat);
          setCo2EmissionAnalyticsGraphData(
            currentIndex === null
              ? currentTab === "buses"
                ? busesList?.overAllAnalytics?.co2EmissionStats?.yearly
                    ?.analyticsData
                : currentTab === "routes"
                ? routesList?.overAllAnalytics?.top5BusyRouteStats?.analytics
                    ?.yearly
                : null
              : currentTab === "buses"
              ? handleSelectedListItemData?.co2EmissionStats?.yearly
                  ?.analyticsData
              : currentTab === "routes"
              ? handleSelectedListItemData?.top5BusyRouteStats?.analytics
                  ?.yearly
              : null
          );
          break;
        default:
          setFormatGraph5(dayFormat);
          setCo2EmissionAnalyticsGraphData(
            currentTab === "buses"
              ? handleSelectedListItemData?.co2EmissionStats?.day?.analyticsData
              : currentTab === "routes"
              ? handleSelectedListItemData?.top5BusyRouteStats?.analytics?.day
              : null
          );
          break;
      }
    }
  };

  const handleListSelect = (val) => {
    switch (val) {
      case "Day":
        setSelectedListItem(
          currentIndex === null
            ? currentTab === "buses"
              ? busesList?.overAllAnalytics?.generalStats?.day
              : currentTab === "drivers"
              ? driversList?.overAllAnalytics?.generalStats?.day
              : routesList?.overAllAnalytics?.generalStats?.day
            : handleSelectedListItemData?.generalStats?.day
        );
        break;
      case "Week":
        setSelectedListItem(
          currentIndex === null
            ? currentTab === "buses"
              ? busesList?.overAllAnalytics?.generalStats?.weekly
              : currentTab === "drivers"
              ? driversList?.overAllAnalytics?.generalStats?.weekly
              : routesList?.overAllAnalytics?.generalStats?.weekly
            : handleSelectedListItemData?.generalStats?.weekly
        );
        break;
      case "Month":
        setSelectedListItem(
          currentIndex === null
            ? currentTab === "buses"
              ? busesList?.overAllAnalytics?.generalStats?.monthly
              : currentTab === "drivers"
              ? driversList?.overAllAnalytics?.generalStats?.monthly
              : routesList?.overAllAnalytics?.generalStats?.monthly
            : handleSelectedListItemData?.generalStats?.monthly
        );
        break;
      case "Year":
        setSelectedListItem(
          currentIndex === null
            ? currentTab === "buses"
              ? busesList?.overAllAnalytics?.generalStats?.yearly
              : currentTab === "drivers"
              ? driversList?.overAllAnalytics?.generalStats?.yearly
              : routesList?.overAllAnalytics?.generalStats?.yearly
            : handleSelectedListItemData?.generalStats?.yearly
        );
        break;
      default:
        setSelectedListItem(handleSelectedListItemData?.generalStats?.day);
        break;
    }
  };

  return (
    <>
      <Fragment>
        <Box sx={{ flexGrow: 1 }} className={analyticsContainerSection}>
          <Grid container>
            <Grid item xs={12}>
              <AnalyticsTabBar
                buttonList={tabList}
                currentTab={currentTab}
                onChange={handleCurrentTab}
              />
            </Grid>
          </Grid>
          <Grid container className={analyticsRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className={analyticsList}
            >
              <AnalyticsList
                cardList={cardList}
                currentIndex={currentIndex}
                handleCurrentIndex={handleCurrentIndex}
                handleAnalytics={handleAnalytics}
                handleSelectedListItem={handleSelectedListItem}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              xl={9}
              className={analyticsRightColumn}
            >
              <Grid container>
                <Grid item xs={12}>
                  <div className={infoIconClass}>
                    <InfoIcon
                      color={theme.palette.lightBlueFour}
                      title={
                        currentTab === "buses"
                          ? selectedListItem?.revenue
                          : currentTab === "drivers"
                          ? selectedListItem?.totalTrips
                          : selectedListItem?.avgIncome
                      }
                      tagLine={
                        currentTab === "buses"
                          ? "Revenue"
                          : currentTab === "drivers"
                          ? "Total Trips"
                          : currentIndex === null
                          ? "Avg.Income"
                          : "Total Income"
                      }
                      icon={
                        currentTab === "buses"
                          ? RevenueIcon
                          : currentTab === "drivers"
                          ? TripIcon
                          : RevenueIcon
                      }
                      fabWidth={70}
                      fabHeight={70}
                      imgWidth={40}
                      imgHeight={40}
                    />
                    <InfoIcon
                      color={theme.palette.darkGreenOne}
                      title={
                        currentTab === "buses"
                          ? selectedListItem?.avgFuelConsumed
                          : currentTab === "drivers"
                          ? selectedListItem?.avgDistanceDriven
                          : selectedListItem?.avgPassengers
                      }
                      tagLine={
                        currentTab === "buses"
                          ? currentIndex === null
                            ? "Avg.Fuel Consumed"
                            : "Total Fuel Consumed"
                          : currentTab === "drivers"
                          ? currentIndex === null
                            ? "Avg. Distance Driven"
                            : "Total Distance Driven"
                          : currentIndex === null
                          ? "Avg. No. of Passengers"
                          : "Total Passengers"
                      }
                      icon={
                        currentTab === "buses"
                          ? MilesIcon
                          : currentTab === "drivers"
                          ? TripIcon
                          : PassengerIcon
                      }
                      fabWidth={70}
                      fabHeight={70}
                      imgWidth={40}
                      imgHeight={40}
                    />
                    <InfoIcon
                      color={theme.palette.orange}
                      title={
                        currentTab === "buses"
                          ? selectedListItem?.avgRunningHours
                          : currentTab === "drivers"
                          ? selectedListItem?.avgDrivingHours
                          : selectedListItem?.noOfStops
                      }
                      tagLine={
                        currentTab === "buses"
                          ? currentIndex === null
                            ? "Avg.Running Hours"
                            : "Total Running Hours"
                          : currentTab === "drivers"
                          ? currentIndex === null
                            ? "Avg. Driving Hours"
                            : "Total Driving Hours"
                          : "No. of Stops"
                      }
                      icon={
                        currentTab === "buses"
                          ? ClockIcon
                          : currentTab === "drivers"
                          ? ClockIcon
                          : StopIcon
                      }
                      fabWidth={70}
                      fabHeight={70}
                      imgWidth={40}
                      imgHeight={40}
                    />
                    <InfoIcon
                      color={theme.palette.lightBlueOne}
                      title={
                        currentTab === "buses"
                          ? selectedListItem?.avgCO2Emission
                          : currentTab === "drivers"
                          ? selectedListItem?.avgLabourCost
                          : selectedListItem?.avgNoOfBuses
                      }
                      tagLine={
                        currentTab === "buses"
                          ? currentIndex === null
                            ? "Avg. CO2 Emission"
                            : "Total CO2 Emission"
                          : currentTab === "drivers"
                          ? currentIndex === null
                            ? "Avg. Labour Cost"
                            : "Total Labour Cost"
                          : currentIndex === null
                          ? "Avg. No. of Buses"
                          : "Total Buses"
                      }
                      icon={
                        currentTab === "buses"
                          ? Co2Icon
                          : currentTab === "drivers"
                          ? CostIcon
                          : BusIcon
                      }
                      fabWidth={70}
                      fabHeight={70}
                      imgWidth={40}
                      imgHeight={40}
                    />
                    <InfoIcon
                      color={theme.palette.pink}
                      title={
                        currentTab === "buses"
                          ? selectedListItem?.avgPassengerCarried
                          : currentTab === "drivers"
                          ? selectedListItem?.avgDrivingScore
                          : selectedListItem?.alerts
                      }
                      tagLine={
                        currentTab === "buses"
                          ? currentIndex === null
                            ? "Avg. Passenger Carried"
                            : "Total Passenger Carried"
                          : currentTab === "drivers"
                          ? currentIndex === null
                            ? "Avg. Driving Score"
                            : "Driving Score"
                          : "Alerts"
                      }
                      icon={
                        currentTab === "buses"
                          ? PassengerIcon
                          : currentTab === "drivers"
                          ? DrivingScoreIcon
                          : AlertsIcon
                      }
                      fabWidth={70}
                      fabHeight={70}
                      imgWidth={40}
                      imgHeight={40}
                    />
                    <div className={selectButton}>
                      <Select
                        selectList={selectList}
                        handleSelect={handleListSelect}
                        customWidth={"110px"}
                        customHeight={""}
                        graphName={"graph1"}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} className={analyticsGridSection}>
                  {currentTab === "drivers" ? (
                    <div className={analyticsGridSection}>
                      <Grid container className={flex1}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={9}
                          lg={9}
                          xl={9}
                          className={analyticsGridList}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={6}
                              className={analyticsGrid}
                            >
                              <div className={graphSection}>
                                <div className={incomeCurrentSection}>
                                  <p className={incomeText}>
                                    {currentTab === "buses" ||
                                    currentTab === "routes"
                                      ? "Income($)"
                                      : "Trips"}
                                  </p>
                                  {currentTab === "buses" ||
                                  currentTab === "routes" ? (
                                    <div className={incomeCurrentSection}>
                                      <div className={incomeCurrentSection}>
                                        <p className={incomeCurrentTextDot}></p>
                                        <p className={incomeCurrentText}>
                                          Current Year Income
                                        </p>
                                      </div>
                                      <div className={incomeCurrentSection}>
                                        <p className={incomeLastTextDot}></p>
                                        <p className={incomeCurrentText}>
                                          Current Year Income
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className={customSelectButton}>
                                    <Select
                                      selectList={selectList}
                                      handleSelect={handleSelect}
                                      customWidth={"94px"}
                                      customHeight={""}
                                      graphName={"graph1"}
                                    />
                                  </div>
                                </div>
                                <div className={avgIncomeText}>
                                  {currentTab === "buses" ||
                                  currentTab === "routes"
                                    ? currentIndex === null
                                      ? "Avg. Income"
                                      : "Income"
                                    : currentIndex === null
                                    ? "Avg. Trips"
                                    : "Trips"}
                                  <span className={followersText}>
                                    {currentIndex === null
                                      ? driversList?.overAllAnalytics
                                          ?.tripStats[graph1ObjDataFormate]
                                          ?.avgTrips
                                      : handleSelectedListItemData?.tripStats[
                                          graph1ObjDataFormate
                                        ]?.trips}
                                  </span>
                                  <span className={followersPercentage}>
                                    {currentIndex === null ? (
                                      driversList?.overAllAnalytics?.tripStats[
                                        graph1ObjDataFormate
                                      ]?.observation === "up" ? (
                                        <img src={upArrow} className={arrow} />
                                      ) : (
                                        <img
                                          src={upArrowDown}
                                          className={arrow}
                                        />
                                      )
                                    ) : handleSelectedListItemData?.tripStats[
                                        graph1ObjDataFormate
                                      ]?.observation === "up" ? (
                                      <img src={upArrow} className={arrow} />
                                    ) : (
                                      <img
                                        src={upArrowDown}
                                        className={arrow}
                                      />
                                    )}

                                    {currentIndex === null
                                      ? driversList?.overAllAnalytics
                                          ?.tripStats[graph1ObjDataFormate]
                                          ?.percentage
                                      : handleSelectedListItemData?.tripStats[
                                          graph1ObjDataFormate
                                        ]?.percentage}
                                  </span>
                                </div>
                                <AnalyticsGraphContainer
                                  busAnalyticsGraphData={
                                    incomeStatsAnalyticsGraphData
                                  }
                                  type={"area"}
                                  minWidth={500}
                                  height={270}
                                  toolTipShared={true}
                                  splineWidth={2}
                                  dataFormate={formatGraph1}
                                  graphSequence={"graph1"}
                                  tooltipShow={true}
                                  isCrosshair={true}
                                  tabIdentity={currentTab}
                                />
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={6}
                              className={analyticsGrid}
                            >
                              <div className={graphSection}>
                                <div className={incomeCurrentSection}>
                                  <p className={incomeText}>
                                    {currentTab === "buses"
                                      ? "Distance Travelled (Miles)"
                                      : currentTab === "drivers"
                                      ? "Distance Driven (Miles)"
                                      : currentTab === "routes"
                                      ? "Number of Passengers"
                                      : null}
                                  </p>
                                  <div className={customSelectButton}>
                                    <Select
                                      selectList={selectList}
                                      handleSelect={handleSelect}
                                      customWidth={"94px"}
                                      customHeight={""}
                                      graphName={"graph2"}
                                    />
                                  </div>
                                </div>
                                <div className={avgIncomeText}>
                                  {currentTab === "buses"
                                    ? currentIndex === null
                                      ? "Avg Distance Travelled"
                                      : "Total Distance Travelled"
                                    : currentTab === "drivers"
                                    ? currentIndex === null
                                      ? "Avg Distance Driven"
                                      : "Total Distance Travelled"
                                    : currentTab === "routes"
                                    ? currentIndex === null
                                      ? "Avg No. of Passengers"
                                      : "Total Passengers"
                                    : null}
                                  <span className={followersText}>
                                    {currentIndex === null
                                      ? driversList?.overAllAnalytics
                                          ?.avgDistanceDrivenStats[
                                          graph2ObjDataFormate
                                        ]?.avgDistanceDriven
                                      : handleSelectedListItemData
                                          ?.avgDistanceDrivenStats[
                                          graph2ObjDataFormate
                                        ]?.totalDistanceDriven}
                                  </span>
                                  <span className={followersPercentage}>
                                    {currentIndex === null ? (
                                      driversList?.overAllAnalytics
                                        ?.avgDistanceDrivenStats[
                                        graph2ObjDataFormate
                                      ]?.observation === "up" ? (
                                        <img src={upArrow} className={arrow} />
                                      ) : (
                                        <img
                                          src={upArrowDown}
                                          className={arrow}
                                        />
                                      )
                                    ) : handleSelectedListItemData
                                        ?.avgDistanceDrivenStats[
                                        graph2ObjDataFormate
                                      ]?.observation === "up" ? (
                                      <img src={upArrow} className={arrow} />
                                    ) : (
                                      <img
                                        src={upArrowDown}
                                        className={arrow}
                                      />
                                    )}

                                    {currentIndex === null
                                      ? driversList?.overAllAnalytics
                                          ?.avgDistanceDrivenStats[
                                          graph2ObjDataFormate
                                        ]?.percentage
                                      : handleSelectedListItemData
                                          ?.avgDistanceDrivenStats[
                                          graph2ObjDataFormate
                                        ]?.percentage}
                                  </span>
                                </div>
                                <AnalyticsGraphContainer
                                  busAnalyticsGraphData={
                                    distanceTravelledStatsAnalyticsGraphData
                                  }
                                  type={"column"}
                                  minWidth={500}
                                  height={270}
                                  toolTipShared={true}
                                  splineWidth={2}
                                  dataFormate={formatGraph2}
                                  graphSequence={"graph2"}
                                  tooltipShow={true}
                                  isCrosshair={false}
                                  tabIdentity={currentTab}
                                />
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={6}
                              className={analyticsGrid}
                            >
                              <div className={graphSection}>
                                <div className={incomeCurrentSection}>
                                  <p className={incomeText}>
                                    {currentTab === "buses"
                                      ? "Fuel Consumed (Gal)"
                                      : currentTab === "drivers"
                                      ? "Labour Cost ($)"
                                      : currentTab === "routes"
                                      ? "Fuel Economy"
                                      : null}
                                  </p>
                                  <div className={customSelectButton}>
                                    <Select
                                      selectList={selectList}
                                      handleSelect={handleSelect}
                                      customWidth={"94px"}
                                      customHeight={""}
                                      graphName={"graph3"}
                                    />
                                  </div>
                                </div>
                                <div className={avgIncomeText}>
                                  {currentTab === "buses"
                                    ? currentIndex === null
                                      ? "Avg. Fuel Consumed"
                                      : "Consumed"
                                    : currentTab === "drivers"
                                    ? currentIndex === null
                                      ? "Avg Labour Cost"
                                      : "Labour Cost"
                                    : currentTab === "routes"
                                    ? currentIndex === null
                                      ? "Avg Fuel Economy"
                                      : "Fuel Economy"
                                    : null}
                                  <span className={followersTextBlue}>
                                    {currentIndex === null
                                      ? driversList?.overAllAnalytics
                                          ?.labourCostStats[
                                          graph3ObjDataFormate
                                        ]?.avgLabourCost
                                      : handleSelectedListItemData
                                          ?.labourCostStats[
                                          graph3ObjDataFormate
                                        ]?.labourCost}
                                  </span>
                                  <span
                                    className={followersPercentageRed}
                                    style={{
                                      color:
                                        currentIndex === null
                                          ? driversList?.overAllAnalytics
                                              ?.labourCostStats[
                                              graph3ObjDataFormate
                                            ]?.observation === "up"
                                            ? theme.palette.darkGreenTwo
                                            : theme.palette.darkRedOne
                                          : handleSelectedListItemData
                                              ?.labourCostStats[
                                              graph3ObjDataFormate
                                            ]?.observation === "up"
                                          ? theme.palette.darkGreenTwo
                                          : theme.palette.darkRedOne,
                                    }}
                                  >
                                    {currentIndex === null ? (
                                      driversList?.overAllAnalytics
                                        ?.labourCostStats[graph3ObjDataFormate]
                                        ?.observation === "up" ? (
                                        <img src={upArrow} className={arrow} />
                                      ) : (
                                        <img
                                          src={upArrowDown}
                                          className={arrow}
                                        />
                                      )
                                    ) : handleSelectedListItemData
                                        ?.labourCostStats[graph3ObjDataFormate]
                                        ?.observation === "up" ? (
                                      <img src={upArrow} className={arrow} />
                                    ) : (
                                      <img
                                        src={upArrowDown}
                                        className={arrow}
                                      />
                                    )}

                                    {currentIndex === null
                                      ? driversList?.overAllAnalytics
                                          ?.labourCostStats[
                                          graph3ObjDataFormate
                                        ]?.percentage
                                      : handleSelectedListItemData
                                          ?.labourCostStats[
                                          graph3ObjDataFormate
                                        ]?.percentage}
                                  </span>
                                </div>
                                <AnalyticsGraphContainer
                                  busAnalyticsGraphData={
                                    fuelConsumedStatsAnalyticsGraphData
                                  }
                                  type={"spline"}
                                  minWidth={500}
                                  height={270}
                                  toolTipShared={true}
                                  splineWidth={2}
                                  dataFormate={formatGraph3}
                                  graphSequence={"graph3"}
                                  tooltipShow={true}
                                  isCrosshair={true}
                                  tabIdentity={currentTab}
                                />
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={6}
                              className={analyticsGrid}
                            >
                              <div className={graphSection}>
                                <div className={incomeCurrentSection}>
                                  <p className={incomeText}>
                                    {currentTab === "buses"
                                      ? "Travelling Hours"
                                      : currentTab === "drivers"
                                      ? "Drive Time Vs Idle Time"
                                      : currentTab === "routes"
                                      ? "Avg. No. of Buses"
                                      : null}
                                  </p>
                                  {currentTab === "buses" ||
                                  currentTab === "drivers" ? (
                                    <div className={incomeCurrentSection}>
                                      <div className={incomeCurrentSection}>
                                        <p className={incomeCurrentTextDot}></p>
                                        <p className={incomeCurrentText}>
                                          {currentTab === "buses"
                                            ? "Running Hours"
                                            : currentTab === "drivers"
                                            ? "Drive Time"
                                            : null}
                                        </p>
                                      </div>
                                      <div className={incomeCurrentSection}>
                                        <p className={incomeLastTextDot}></p>
                                        <p className={incomeCurrentText}>
                                          {currentTab === "buses"
                                            ? "Idle Hours"
                                            : currentTab === "drivers"
                                            ? "Idle Time"
                                            : null}
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className={customSelectButton}>
                                    <Select
                                      selectList={selectList}
                                      handleSelect={handleSelect}
                                      customWidth={"94px"}
                                      customHeight={""}
                                      graphName={"graph4"}
                                    />
                                  </div>
                                </div>
                                <div className={incomeCurrentSection}>
                                  {currentTab === "buses" ||
                                  currentTab === "drivers" ? (
                                    <>
                                      <div className={avgIncomeText}>
                                        {currentTab === "buses"
                                          ? currentIndex === null
                                            ? "Avg. Running Hours"
                                            : "Running Hours"
                                          : currentTab === "drivers"
                                          ? currentIndex === null
                                            ? "Avg. Drive Time"
                                            : "Drive Time"
                                          : null}

                                        <span className={followersTextGreen}>
                                          {currentIndex === null
                                            ? driversList?.overAllAnalytics
                                                ?.driveTimeStats[
                                                graph4ObjDataFormate
                                              ]?.avgDriveTime
                                            : handleSelectedListItemData
                                                ?.driveTimeStats[
                                                graph4ObjDataFormate
                                              ]?.driveTime}
                                        </span>
                                        <span className={followersPercentage}>
                                          {currentIndex === null ? (
                                            driversList?.overAllAnalytics
                                              ?.driveTimeStats[
                                              graph4ObjDataFormate
                                            ]?.avgDriveObservation === "up" ? (
                                              <img
                                                src={upArrow}
                                                className={arrow}
                                              />
                                            ) : (
                                              <img
                                                src={upArrowDown}
                                                className={arrow}
                                              />
                                            )
                                          ) : handleSelectedListItemData
                                              ?.driveTimeStats[
                                              graph4ObjDataFormate
                                            ]?.driveObservation === "up" ? (
                                            <img
                                              src={upArrow}
                                              className={arrow}
                                            />
                                          ) : (
                                            <img
                                              src={upArrowDown}
                                              className={arrow}
                                            />
                                          )}

                                          {currentIndex === null
                                            ? driversList?.overAllAnalytics
                                                ?.driveTimeStats[
                                                graph4ObjDataFormate
                                              ]?.avgDrivePercentage
                                            : handleSelectedListItemData
                                                ?.driveTimeStats[
                                                graph4ObjDataFormate
                                              ]?.drivePercentage}
                                        </span>
                                      </div>
                                      <div className={avgIncomeText}>
                                        {currentTab === "buses"
                                          ? currentIndex === null
                                            ? "Avg. Idle Hours"
                                            : "Idle Hours"
                                          : currentTab === "drivers"
                                          ? currentIndex === null
                                            ? "Avg. Idle Time"
                                            : "Idle Time"
                                          : null}

                                        <span className={followersTextGreen}>
                                          {currentIndex === null
                                            ? driversList?.overAllAnalytics
                                                ?.driveTimeStats[
                                                graph4ObjDataFormate
                                              ]?.avgIdleTime
                                            : handleSelectedListItemData
                                                ?.driveTimeStats[
                                                graph4ObjDataFormate
                                              ]?.idleTime}
                                        </span>
                                        <span
                                          className={followersPercentageRed}
                                        >
                                          {currentIndex === null ? (
                                            driversList?.overAllAnalytics
                                              ?.driveTimeStats[
                                              graph4ObjDataFormate
                                            ]?.avgIdleObservation === "up" ? (
                                              <img
                                                src={upArrow}
                                                className={arrow}
                                              />
                                            ) : (
                                              <img
                                                src={upArrowDown}
                                                className={arrow}
                                              />
                                            )
                                          ) : handleSelectedListItemData
                                              ?.driveTimeStats[
                                              graph4ObjDataFormate
                                            ]?.idleObservation === "up" ? (
                                            <img
                                              src={upArrow}
                                              className={arrow}
                                            />
                                          ) : (
                                            <img
                                              src={upArrowDown}
                                              className={arrow}
                                            />
                                          )}
                                          {currentIndex === null
                                            ? driversList?.overAllAnalytics
                                                ?.driveTimeStats[
                                                graph4ObjDataFormate
                                              ]?.avgIdlePercentage
                                            : handleSelectedListItemData
                                                ?.driveTimeStats[
                                                graph4ObjDataFormate
                                              ]?.idlePercentage}
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <div className={avgIncomeText}>
                                      <span>Buses</span>
                                      <img src={sensexIcon} className={arrow} />
                                    </div>
                                  )}
                                </div>
                                <AnalyticsGraphContainer
                                  busAnalyticsGraphData={
                                    travellingHoursStatsAnalyticsGraphData
                                  }
                                  type={"spline"}
                                  minWidth={500}
                                  height={270}
                                  toolTipShared={true}
                                  splineWidth={2}
                                  dataFormate={formatGraph4}
                                  graphSequence={"graph4"}
                                  tooltipShow={true}
                                  isCrosshair={true}
                                  dataLabels={false}
                                  tabIdentity={currentTab}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        {currentTab !== "drivers" ? (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={3}
                            lg={3}
                            xl={3}
                            className={analyticsGrid}
                          >
                            <div className={graphSection}>
                              <div className={incomeCurrentSection}>
                                <p className={incomeText}>
                                  {currentTab === "buses"
                                    ? "CO2 Emission (T)"
                                    : currentTab === "routes"
                                    ? "Top 5 Busiest Route"
                                    : null}
                                </p>
                                <div className={customSelectButton}>
                                  <Select
                                    selectList={selectList}
                                    handleSelect={handleSelect}
                                    customWidth={"94px"}
                                    customHeight={""}
                                    graphName={"graph5"}
                                  />
                                </div>
                              </div>
                              {currentTab === "buses" ? (
                                <div className={avgIncomeText}>
                                  Avg. Emission{" "}
                                  <span className={followersTextGrey}>
                                    7.00T
                                  </span>
                                  <span className={followersPercentage}>
                                    <img src={upArrow} />
                                    0.5%
                                  </span>
                                </div>
                              ) : null}
                              <AnalyticsGraphContainer
                                busAnalyticsGraphData={
                                  co2EmissionStatsAnalyticsGraphData
                                }
                                type={"column"}
                                minWidth={500}
                                height={270}
                                toolTipShared={true}
                                splineWidth={2}
                                dataFormate={formatGraph5}
                                graphSequence={"graph5"}
                                tooltipShow={false}
                                isCrosshair={false}
                                tabIdentity={currentTab}
                              />
                            </div>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          lg={3}
                          xl={3}
                          className={analyticsGrid}
                        >
                          <div className={graphSection}>
                            <div className={graphExpenseSection}>
                              <div className={incomeCurrentSection}>
                                <p className={incomeText}>Violations</p>
                                <div>
                                  <Select
                                    selectList={selectList}
                                    handleSelect={handleListSelect}
                                    customWidth={"110px"}
                                    customHeight={""}
                                    graphName={"graph1"}
                                  />
                                </div>
                              </div>
                              <CircularAnalytics
                                data={circularData}
                                circularInnerText={circularInnerText}
                              />
                              <div className={expenseListDriver}>
                                <div className={innerExpenseListDriver}>
                                  <p className={expenseCircleChip}></p>
                                  <p className={expenseFuelDriver}>431</p>
                                  <p className={expenseDriverFollowers}>
                                    Overspeeding
                                  </p>
                                </div>
                                <div className={innerExpenseListDriver}>
                                  <p
                                    className={expenseCircleChipLightBlue5}
                                  ></p>
                                  <p className={expenseFuelDriver}>365</p>
                                  <p className={expenseDriverFollowers}>
                                    Harsh Braking
                                  </p>
                                </div>
                                <div className={innerExpenseListDriver}>
                                  <p
                                    className={expenseCircleChipLightPink1}
                                  ></p>
                                  <p className={expenseFuelDriver}>204</p>
                                  <p className={expenseDriverFollowers}>
                                    Harsh Acceleration
                                  </p>
                                </div>
                                <div className={innerExpenseListDriver}>
                                  <p className={expenseCircleChip}></p>
                                  <p className={expenseFuelDriver}>247</p>
                                  <p className={expenseDriverFollowers}>
                                    Sharp Corner
                                  </p>
                                </div>
                                <div className={innerExpenseListDriver}>
                                  <p className={expenseCircleChipLightRed2}></p>
                                  <p className={expenseFuelDriver}>257</p>
                                  <p className={expenseDriverFollowers}>
                                    Distractions
                                  </p>
                                </div>
                                <div className={innerExpenseListDriver}>
                                  <p
                                    className={expenseCircleChipDarkOrange}
                                  ></p>
                                  <p className={expenseFuelDriver}>314</p>
                                  <p className={expenseDriverFollowers}>
                                    Other Violations
                                  </p>
                                </div>
                                <div className={innerExpenseListDriver}>
                                  <p className={expenseCircleChipPurpleOne}></p>
                                  <p className={expenseFuelDriver}>341</p>
                                  <p className={expenseDriverFollowers}>
                                    High Fatigue
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <div className={analyticsGridSection}>
                      <Grid container className={flex1}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4.5}
                          lg={4.5}
                          xl={4.5}
                          className={analyticsGrid}
                        >
                          <div className={graphSection}>
                            <div className={incomeCurrentSection}>
                              <p className={incomeText}>
                                {currentTab === "buses" ||
                                currentTab === "routes"
                                  ? "Income($)"
                                  : "Trips"}
                              </p>
                              {currentTab === "buses" ||
                              currentTab === "routes" ? (
                                <div className={incomeCurrentSection}>
                                  <div className={incomeCurrentSection}>
                                    <p className={incomeCurrentTextDot}></p>
                                    <p className={incomeCurrentText}>
                                      Current Year Income
                                    </p>
                                  </div>
                                  <div className={incomeCurrentSection}>
                                    <p className={incomeLastTextDot}></p>
                                    <p className={incomeCurrentText}>
                                      Current Year Income
                                    </p>
                                  </div>
                                </div>
                              ) : null}

                              <div className={customSelectButton}>
                                <Select
                                  selectList={selectList}
                                  handleSelect={handleSelect}
                                  customWidth={"94px"}
                                  customHeight={""}
                                  graphName={"graph1"}
                                />
                              </div>
                            </div>
                            <div className={avgIncomeText}>
                              {currentTab === "buses" || currentTab === "routes"
                                ? currentIndex === null
                                  ? "Avg. Income"
                                  : "Income"
                                : currentIndex === null
                                ? "Avg. Trips"
                                : "Trips"}

                              <span className={followersText}>
                                {currentTab === "buses"
                                  ? currentIndex === null
                                    ? busesList?.overAllAnalytics?.incomeStats[
                                        graph1ObjDataFormate
                                      ]?.avgIncome
                                    : handleSelectedListItemData?.incomeStats[
                                        graph1ObjDataFormate
                                      ]?.income
                                  : currentTab === "routes"
                                  ? currentIndex === null
                                    ? routesList?.overAllAnalytics?.incomeStats[
                                        graph1ObjDataFormate
                                      ]?.avgIncome
                                    : handleSelectedListItemData?.incomeStats[
                                        graph1ObjDataFormate
                                      ]?.totalIncome
                                  : null}
                              </span>
                              <span
                                className={followersPercentage}
                                style={{
                                  color:
                                    currentTab === "buses"
                                      ? currentIndex === null
                                        ? busesList?.overAllAnalytics
                                            ?.incomeStats[graph1ObjDataFormate]
                                            ?.observation === "up"
                                          ? theme.palette.darkGreenTwo
                                          : theme.palette.darkRedOne
                                        : handleSelectedListItemData
                                            ?.incomeStats[graph1ObjDataFormate]
                                            ?.observation === "up"
                                        ? theme.palette.darkGreenTwo
                                        : theme.palette.darkRedOne
                                      : currentTab === "routes"
                                      ? currentIndex === null
                                        ? routesList?.overAllAnalytics
                                            ?.incomeStats[graph1ObjDataFormate]
                                            ?.observation === "up"
                                          ? theme.palette.darkGreenTwo
                                          : theme.palette.darkRedOne
                                        : handleSelectedListItemData
                                            ?.incomeStats[graph1ObjDataFormate]
                                            ?.observation === "up"
                                        ? theme.palette.darkGreenTwo
                                        : theme.palette.darkRedOne
                                      : null,
                                }}
                              >
                                {currentTab === "buses" ? (
                                  currentIndex === null ? (
                                    busesList?.overAllAnalytics?.incomeStats[
                                      graph1ObjDataFormate
                                    ]?.observation === "up" ? (
                                      <img src={upArrow} className={arrow} />
                                    ) : (
                                      <img
                                        src={upArrowDown}
                                        className={arrow}
                                      />
                                    )
                                  ) : handleSelectedListItemData?.incomeStats[
                                      graph1ObjDataFormate
                                    ]?.observation === "up" ? (
                                    <img src={upArrow} className={arrow} />
                                  ) : (
                                    <img src={upArrowDown} className={arrow} />
                                  )
                                ) : currentTab === "routes" ? (
                                  currentIndex === null ? (
                                    routesList?.overAllAnalytics?.incomeStats[
                                      graph1ObjDataFormate
                                    ]?.observation === "up" ? (
                                      <img src={upArrow} className={arrow} />
                                    ) : (
                                      <img
                                        src={upArrowDown}
                                        className={arrow}
                                      />
                                    )
                                  ) : handleSelectedListItemData?.incomeStats[
                                      graph1ObjDataFormate
                                    ]?.observation === "up" ? (
                                    <img src={upArrow} className={arrow} />
                                  ) : (
                                    <img src={upArrowDown} className={arrow} />
                                  )
                                ) : null}
                                {currentTab === "buses"
                                  ? currentIndex === null
                                    ? busesList?.overAllAnalytics?.incomeStats[
                                        graph1ObjDataFormate
                                      ]?.percentage
                                    : handleSelectedListItemData?.incomeStats[
                                        graph1ObjDataFormate
                                      ]?.percentage
                                  : currentTab === "routes"
                                  ? currentIndex === null
                                    ? routesList?.overAllAnalytics?.incomeStats[
                                        graph1ObjDataFormate
                                      ]?.percentage
                                    : handleSelectedListItemData?.incomeStats[
                                        graph1ObjDataFormate
                                      ]?.percentage
                                  : null}
                              </span>
                            </div>
                            <AnalyticsGraphContainer
                              busAnalyticsGraphData={
                                incomeStatsAnalyticsGraphData
                              }
                              type={"area"}
                              minWidth={500}
                              height={270}
                              toolTipShared={true}
                              splineWidth={2}
                              dataFormate={formatGraph1}
                              graphSequence={"graph1"}
                              tooltipShow={true}
                              isCrosshair={true}
                              dataLabels={false}
                              tabIdentity={currentTab}
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4.5}
                          lg={4.5}
                          xl={4.5}
                          className={analyticsGrid}
                        >
                          <div className={graphSection}>
                            <div className={incomeCurrentSection}>
                              <p className={incomeText}>
                                {currentTab === "buses"
                                  ? "Distance Travelled (Miles)"
                                  : currentTab === "drivers"
                                  ? "Distance Driven (Miles)"
                                  : currentTab === "routes"
                                  ? "Number of Passengers"
                                  : null}
                              </p>
                              <div className={customSelectButton}>
                                <Select
                                  selectList={selectList}
                                  handleSelect={handleSelect}
                                  customWidth={"94px"}
                                  customHeight={""}
                                  graphName={"graph2"}
                                />
                              </div>
                            </div>
                            <div className={avgIncomeText}>
                              {currentTab === "buses"
                                ? currentIndex === null
                                  ? "Avg Distance Travelled"
                                  : "Total Distance Travelled"
                                : currentTab === "drivers"
                                ? currentIndex === null
                                  ? "Avg Distance Driven"
                                  : "Total Distance Travelled"
                                : currentTab === "routes"
                                ? currentIndex === null
                                  ? "Avg No. of Passengers"
                                  : "Total Passengers"
                                : null}

                              <span className={followersText}>
                                {currentTab === "buses"
                                  ? currentIndex === null
                                    ? busesList?.overAllAnalytics
                                        ?.distanceTravelledStats[
                                        graph2ObjDataFormate
                                      ]?.avgDistanceTravelled
                                    : handleSelectedListItemData
                                        ?.distanceTravelledStats[
                                        graph2ObjDataFormate
                                      ]?.totalDistanceTravelled
                                  : currentTab === "routes"
                                  ? currentIndex === null
                                    ? routesList?.overAllAnalytics
                                        ?.passengersStats[graph2ObjDataFormate]
                                        ?.avgNoOfPassengers
                                    : handleSelectedListItemData
                                        ?.passengersStats[graph2ObjDataFormate]
                                        ?.totalPassengers
                                  : null}
                              </span>
                              <span
                                className={followersPercentage}
                                style={{
                                  color:
                                    currentTab === "buses"
                                      ? currentIndex === null
                                        ? busesList?.overAllAnalytics
                                            ?.distanceTravelledStats[
                                            graph2ObjDataFormate
                                          ]?.observation === "up"
                                          ? theme.palette.darkGreenTwo
                                          : theme.palette.darkRedOne
                                        : handleSelectedListItemData
                                            ?.distanceTravelledStats[
                                            graph2ObjDataFormate
                                          ]?.observation === "up"
                                        ? theme.palette.darkGreenTwo
                                        : theme.palette.darkRedOne
                                      : currentTab === "routes"
                                      ? currentIndex === null
                                        ? routesList?.overAllAnalytics
                                            ?.passengersStats[
                                            graph2ObjDataFormate
                                          ]?.observation === "up"
                                          ? theme.palette.darkGreenTwo
                                          : theme.palette.darkRedOne
                                        : handleSelectedListItemData
                                            ?.passengersStats[
                                            graph2ObjDataFormate
                                          ]?.observation === "up"
                                        ? theme.palette.darkGreenTwo
                                        : theme.palette.darkRedOne
                                      : null,
                                }}
                              >
                                {currentTab === "buses" ? (
                                  currentIndex === null ? (
                                    busesList?.overAllAnalytics
                                      ?.distanceTravelledStats[
                                      graph2ObjDataFormate
                                    ]?.observation === "up" ? (
                                      <img src={upArrow} className={arrow} />
                                    ) : (
                                      <img
                                        src={upArrowDown}
                                        className={arrow}
                                      />
                                    )
                                  ) : handleSelectedListItemData
                                      ?.distanceTravelledStats[
                                      graph2ObjDataFormate
                                    ]?.observation === "up" ? (
                                    <img src={upArrow} className={arrow} />
                                  ) : (
                                    <img src={upArrowDown} className={arrow} />
                                  )
                                ) : currentTab === "routes" ? (
                                  currentIndex === null ? (
                                    routesList?.overAllAnalytics
                                      ?.passengersStats[graph2ObjDataFormate]
                                      ?.observation === "up" ? (
                                      <img src={upArrow} className={arrow} />
                                    ) : (
                                      <img
                                        src={upArrowDown}
                                        className={arrow}
                                      />
                                    )
                                  ) : handleSelectedListItemData
                                      ?.passengersStats[graph2ObjDataFormate]
                                      ?.observation === "up" ? (
                                    <img src={upArrow} className={arrow} />
                                  ) : (
                                    <img src={upArrowDown} className={arrow} />
                                  )
                                ) : null}
                                {currentTab === "buses"
                                  ? currentIndex === null
                                    ? busesList?.overAllAnalytics
                                        ?.distanceTravelledStats[
                                        graph2ObjDataFormate
                                      ]?.percentage
                                    : handleSelectedListItemData
                                        ?.distanceTravelledStats[
                                        graph2ObjDataFormate
                                      ]?.percentage
                                  : currentTab === "routes"
                                  ? currentIndex === null
                                    ? routesList?.overAllAnalytics
                                        ?.passengersStats[graph2ObjDataFormate]
                                        ?.percentage
                                    : handleSelectedListItemData
                                        ?.passengersStats[graph2ObjDataFormate]
                                        ?.percentage
                                  : null}
                              </span>
                            </div>
                            <AnalyticsGraphContainer
                              busAnalyticsGraphData={
                                distanceTravelledStatsAnalyticsGraphData
                              }
                              type={"column"}
                              minWidth={500}
                              height={270}
                              toolTipShared={true}
                              splineWidth={2}
                              dataFormate={formatGraph2}
                              graphSequence={"graph2"}
                              tooltipShow={true}
                              isCrosshair={false}
                              dataLabels={false}
                              tabIdentity={currentTab}
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          lg={3}
                          xl={3}
                          className={analyticsGrid}
                        >
                          <div className={graphSection}>
                            <div className={graphExpenseSection}>
                              <div className={incomeCurrentSection}>
                                <p className={incomeText}>
                                  {currentTab === "buses"
                                    ? "Expenses"
                                    : currentTab === "routes"
                                    ? "Stops"
                                    : null}

                                  <span className={followersPercentageRed}>
                                    {currentTab === "buses" ? (
                                      currentIndex === null ? (
                                        busesList?.overAllAnalytics
                                          ?.expensesStats?.day?.observation ===
                                        "up" ? (
                                          <img
                                            src={upArrow}
                                            className={arrow}
                                          />
                                        ) : (
                                          <img
                                            src={upArrowDown}
                                            className={arrow}
                                          />
                                        )
                                      ) : handleSelectedListItemData
                                          ?.expensesStats?.day?.observation ===
                                        "up" ? (
                                        <img src={upArrow} className={arrow} />
                                      ) : (
                                        <img
                                          src={upArrowDown}
                                          className={arrow}
                                        />
                                      )
                                    ) : null}
                                    {currentTab === "buses"
                                      ? currentIndex === null
                                        ? busesList?.overAllAnalytics
                                            ?.expensesStats?.day?.percentage
                                        : handleSelectedListItemData
                                            ?.expensesStats?.day?.percentage
                                      : null}
                                  </span>
                                </p>
                                <div>
                                  <Select
                                    selectList={selectList}
                                    handleSelect={handleListSelect}
                                    customWidth={"110px"}
                                    customHeight={""}
                                    graphName={"graph1"}
                                  />
                                </div>
                              </div>
                              <CircularAnalytics
                                data={circularData}
                                circularInnerText={circularInnerText}
                              />
                              <div className={expenseList}>
                                <div className={innerExpenseList}>
                                  <p className={expenseGreenChip}></p>
                                  <p className={expenseFuel}>Fuel</p>
                                  <p className={expenseFollowers}>$60K</p>
                                </div>
                                <div className={innerExpenseList}>
                                  <p className={expensePinkChip}></p>
                                  <p className={expenseFuel}>Maintenance </p>
                                  <p className={expenseFollowers}>$40K</p>
                                </div>
                                <div className={innerExpenseList}>
                                  <p className={expenseBlueChip}></p>
                                  <p className={expenseFuel}>Others</p>
                                  <p className={expenseFollowers}>$24K</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container className={flex1}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4.5}
                          lg={4.5}
                          xl={4.5}
                          className={analyticsGrid}
                        >
                          <div className={graphSection}>
                            <div className={incomeCurrentSection}>
                              <p className={incomeText}>
                                {currentTab === "buses"
                                  ? "Fuel Consumed (Gal)"
                                  : currentTab === "drivers"
                                  ? "Labour Cost ($)"
                                  : currentTab === "routes"
                                  ? "Fuel Economy"
                                  : null}
                              </p>
                              <div className={customSelectButton}>
                                <Select
                                  selectList={selectList}
                                  handleSelect={handleSelect}
                                  customWidth={"94px"}
                                  customHeight={""}
                                  graphName={"graph3"}
                                />
                              </div>
                            </div>
                            <div className={avgIncomeText}>
                              {currentTab === "buses"
                                ? currentIndex === null
                                  ? "Avg. Fuel Consumed"
                                  : "Consumed"
                                : currentTab === "drivers"
                                ? currentIndex === null
                                  ? "Avg Labour Cost"
                                  : "Labour Cost"
                                : currentTab === "routes"
                                ? currentIndex === null
                                  ? "Avg Fuel Economy"
                                  : "Fuel Economy"
                                : null}

                              <span className={followersTextBlue}>
                                {currentTab === "buses"
                                  ? currentIndex === null
                                    ? busesList?.overAllAnalytics
                                        ?.fuelConsumedStats[
                                        graph3ObjDataFormate
                                      ]?.avgFuelConsumed
                                    : handleSelectedListItemData
                                        ?.fuelConsumedStats[
                                        graph3ObjDataFormate
                                      ]?.fuelConsumed
                                  : currentTab === "routes"
                                  ? currentIndex === null
                                    ? routesList?.overAllAnalytics
                                        ?.fuelEconomyStats[graph3ObjDataFormate]
                                        ?.avgFuelEconomy
                                    : handleSelectedListItemData
                                        ?.fuelEconomyStats[graph3ObjDataFormate]
                                        ?.fuelEconomy
                                  : null}
                              </span>
                              <span className={followersPercentageRed}>
                                {currentTab === "routes" ? (
                                  <img src={sensexIcon} className={arrow} />
                                ) : currentTab === "buses" ? (
                                  currentIndex === null ? (
                                    busesList?.overAllAnalytics
                                      ?.fuelConsumedStats[graph3ObjDataFormate]
                                      ?.observation === "up" ? (
                                      <img src={upArrow} className={arrow} />
                                    ) : (
                                      <img
                                        src={upArrowDown}
                                        className={arrow}
                                      />
                                    )
                                  ) : handleSelectedListItemData
                                      ?.fuelConsumedStats[graph3ObjDataFormate]
                                      ?.observation === "up" ? (
                                    <img src={upArrow} className={arrow} />
                                  ) : (
                                    <img src={upArrowDown} className={arrow} />
                                  )
                                ) : null}
                                {currentTab === "buses"
                                  ? currentIndex === null
                                    ? busesList?.overAllAnalytics
                                        ?.fuelConsumedStats[
                                        graph3ObjDataFormate
                                      ]?.percentage
                                    : handleSelectedListItemData
                                        ?.fuelConsumedStats[
                                        graph3ObjDataFormate
                                      ]?.percentage
                                  : null}
                              </span>
                            </div>
                            <AnalyticsGraphContainer
                              busAnalyticsGraphData={
                                fuelConsumedStatsAnalyticsGraphData
                              }
                              type={"spline"}
                              minWidth={500}
                              height={270}
                              toolTipShared={true}
                              splineWidth={2}
                              dataFormate={formatGraph3}
                              graphSequence={"graph3"}
                              tooltipShow={true}
                              isCrosshair={true}
                              dataLabels={false}
                              tabIdentity={currentTab}
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4.5}
                          lg={4.5}
                          xl={4.5}
                          className={analyticsGrid}
                        >
                          <div className={graphSection}>
                            <div className={incomeCurrentSection}>
                              <p className={incomeText}>
                                {currentTab === "buses"
                                  ? "Travelling Hours"
                                  : currentTab === "drivers"
                                  ? "Drive Time Vs Idle Time"
                                  : currentTab === "routes"
                                  ? "Avg. No. of Buses"
                                  : null}
                              </p>
                              {currentTab === "buses" ||
                              currentTab === "drivers" ? (
                                <div className={incomeCurrentSection}>
                                  <div className={incomeCurrentSection}>
                                    <p className={incomeCurrentTextDot}></p>
                                    <p className={incomeCurrentText}>
                                      {currentTab === "buses"
                                        ? "Running Hours"
                                        : currentTab === "drivers"
                                        ? "Drive Time"
                                        : null}
                                    </p>
                                  </div>
                                  <div className={incomeCurrentSection}>
                                    <p className={incomeLastTextDot}></p>
                                    <p className={incomeCurrentText}>
                                      {currentTab === "buses"
                                        ? "Idle Hours"
                                        : currentTab === "drivers"
                                        ? "Idle Time"
                                        : null}
                                    </p>
                                  </div>
                                </div>
                              ) : null}

                              <div className={customSelectButton}>
                                <Select
                                  selectList={selectList}
                                  handleSelect={handleSelect}
                                  customWidth={"94px"}
                                  customHeight={""}
                                  graphName={"graph4"}
                                />
                              </div>
                            </div>
                            <div className={incomeCurrentSection}>
                              {currentTab === "buses" ||
                              currentTab === "drivers" ? (
                                <>
                                  <div className={avgIncomeText}>
                                    {currentTab === "buses"
                                      ? currentIndex === null
                                        ? "Avg. Running Hours"
                                        : "Running Hours"
                                      : currentTab === "drivers"
                                      ? currentIndex === null
                                        ? "Avg. Drive Time"
                                        : "Drive Time"
                                      : null}

                                    <span className={followersTextGreen}>
                                      {currentTab === "buses"
                                        ? currentIndex === null
                                          ? busesList?.overAllAnalytics
                                              ?.travellingHoursStats[
                                              graph4ObjDataFormate
                                            ]?.avgRunningHours
                                          : handleSelectedListItemData
                                              ?.travellingHoursStats[
                                              graph4ObjDataFormate
                                            ]?.runningHours
                                        : null}
                                    </span>
                                    <span className={followersPercentage}>
                                      {currentTab === "buses" ? (
                                        currentIndex === null ? (
                                          busesList?.overAllAnalytics
                                            ?.travellingHoursStats[
                                            graph4ObjDataFormate
                                          ]?.runningHrsObservation === "up" ? (
                                            <img
                                              src={upArrow}
                                              className={arrow}
                                            />
                                          ) : (
                                            <img
                                              src={upArrowDown}
                                              className={arrow}
                                            />
                                          )
                                        ) : handleSelectedListItemData
                                            ?.travellingHoursStats[
                                            graph4ObjDataFormate
                                          ]?.runningHrsObservation === "up" ? (
                                          <img
                                            src={upArrow}
                                            className={arrow}
                                          />
                                        ) : (
                                          <img
                                            src={upArrowDown}
                                            className={arrow}
                                          />
                                        )
                                      ) : null}
                                      {currentTab === "buses"
                                        ? currentIndex === null
                                          ? busesList?.overAllAnalytics
                                              ?.travellingHoursStats[
                                              graph4ObjDataFormate
                                            ]?.runningHrsPercentage
                                          : handleSelectedListItemData
                                              ?.travellingHoursStats[
                                              graph4ObjDataFormate
                                            ]?.runningHrsPercentage
                                        : null}
                                    </span>
                                  </div>
                                  <div className={avgIncomeText}>
                                    {currentTab === "buses"
                                      ? currentIndex === null
                                        ? "Avg. Idle Hours"
                                        : "Idle Hours"
                                      : currentTab === "drivers"
                                      ? currentIndex === null
                                        ? "Avg. Idle Time"
                                        : "Idle Time"
                                      : null}

                                    <span className={followersTextGreen}>
                                      {currentTab === "buses"
                                        ? currentIndex === null
                                          ? busesList?.overAllAnalytics
                                              ?.travellingHoursStats[
                                              graph4ObjDataFormate
                                            ]?.avgIdleHours
                                          : handleSelectedListItemData
                                              ?.travellingHoursStats[
                                              graph4ObjDataFormate
                                            ]?.idleHours
                                        : null}
                                    </span>
                                    <span className={followersPercentageRed}>
                                      {currentTab === "buses" ? (
                                        currentIndex === null ? (
                                          busesList?.overAllAnalytics
                                            ?.travellingHoursStats[
                                            graph4ObjDataFormate
                                          ]?.idleHrsObservation === "up" ? (
                                            <img
                                              src={upArrow}
                                              className={arrow}
                                            />
                                          ) : (
                                            <img
                                              src={upArrowDown}
                                              className={arrow}
                                            />
                                          )
                                        ) : handleSelectedListItemData
                                            ?.travellingHoursStats[
                                            graph4ObjDataFormate
                                          ]?.idleHrsObservation === "up" ? (
                                          <img
                                            src={upArrow}
                                            className={arrow}
                                          />
                                        ) : (
                                          <img
                                            src={upArrowDown}
                                            className={arrow}
                                          />
                                        )
                                      ) : null}
                                      {currentTab === "buses"
                                        ? currentIndex === null
                                          ? busesList?.overAllAnalytics
                                              ?.travellingHoursStats[
                                              graph4ObjDataFormate
                                            ]?.idleHrsPercentage
                                          : handleSelectedListItemData
                                              ?.travellingHoursStats[
                                              graph4ObjDataFormate
                                            ]?.idleHrsPercentage
                                        : null}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <div className={avgIncomeText}>
                                  <span>Buses</span>
                                  {/* <img src={sensexIcon} className={arrow} /> */}
                                </div>
                              )}
                            </div>
                            <AnalyticsGraphContainer
                              busAnalyticsGraphData={
                                travellingHoursStatsAnalyticsGraphData
                              }
                              type={"spline"}
                              minWidth={500}
                              height={270}
                              toolTipShared={true}
                              splineWidth={2}
                              dataFormate={formatGraph4}
                              graphSequence={"graph4"}
                              tooltipShow={true}
                              isCrosshair={true}
                              dataLabels={false}
                              tabIdentity={currentTab}
                            />
                          </div>
                        </Grid>
                        {currentTab !== "drivers" ? (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={3}
                            lg={3}
                            xl={3}
                            className={analyticsGrid}
                          >
                            <div className={graphSection}>
                              <div className={incomeCurrentSection}>
                                <p className={incomeText}>
                                  {currentTab === "buses"
                                    ? "CO2 Emission (T)"
                                    : currentTab === "routes"
                                    ? "Top 5 Busiest Route"
                                    : null}
                                </p>
                                <div className={customSelectButton}>
                                  <Select
                                    selectList={selectList}
                                    handleSelect={handleSelect}
                                    customWidth={"94px"}
                                    customHeight={""}
                                    graphName={"graph5"}
                                  />
                                </div>
                              </div>
                              {currentTab === "buses" ? (
                                <div className={avgIncomeText}>
                                  {currentIndex === null
                                    ? "Avg. Emission"
                                    : "Emission"}
                                  <span className={followersTextGrey}>
                                    {currentTab === "buses"
                                      ? currentIndex === null
                                        ? busesList?.overAllAnalytics
                                            ?.co2EmissionStats[
                                            graph5ObjDataFormate
                                          ]?.avgEmission
                                        : handleSelectedListItemData
                                            ?.co2EmissionStats[
                                            graph5ObjDataFormate
                                          ]?.emission
                                      : null}
                                  </span>
                                  <span
                                    className={followersPercentage}
                                    style={{
                                      color:
                                        currentIndex === null
                                          ? busesList?.overAllAnalytics
                                              ?.co2EmissionStats[
                                              graph5ObjDataFormate
                                            ]?.observation === "up"
                                            ? theme.palette.darkGreenTwo
                                            : theme.palette.darkRedOne
                                          : handleSelectedListItemData
                                              ?.co2EmissionStats[
                                              graph5ObjDataFormate
                                            ]?.observation === "up"
                                          ? theme.palette.darkGreenTwo
                                          : theme.palette.darkRedOne,
                                    }}
                                  >
                                    {currentTab === "buses" ? (
                                      currentIndex === null ? (
                                        busesList?.overAllAnalytics
                                          ?.co2EmissionStats[
                                          graph5ObjDataFormate
                                        ]?.observation === "up" ? (
                                          <img
                                            src={upArrow}
                                            className={arrow}
                                          />
                                        ) : (
                                          <img
                                            src={upArrowDown}
                                            className={arrow}
                                          />
                                        )
                                      ) : handleSelectedListItemData
                                          ?.co2EmissionStats[
                                          graph5ObjDataFormate
                                        ]?.observation === "up" ? (
                                        <img src={upArrow} className={arrow} />
                                      ) : (
                                        <img
                                          src={upArrowDown}
                                          className={arrow}
                                        />
                                      )
                                    ) : null}
                                    {currentTab === "buses"
                                      ? currentIndex === null
                                        ? busesList?.overAllAnalytics
                                            ?.co2EmissionStats[
                                            graph5ObjDataFormate
                                          ]?.percentage
                                        : handleSelectedListItemData
                                            ?.co2EmissionStats[
                                            graph5ObjDataFormate
                                          ]?.percentage
                                      : null}
                                  </span>
                                </div>
                              ) : null}

                              <AnalyticsGraphContainer
                                busAnalyticsGraphData={
                                  co2EmissionStatsAnalyticsGraphData
                                }
                                type={"column"}
                                minWidth={500}
                                height={270}
                                toolTipShared={true}
                                splineWidth={2}
                                dataFormate={formatGraph5}
                                graphSequence={"graph5"}
                                tooltipShow={false}
                                isCrosshair={false}
                                dataLabels={
                                  currentTab !== "routes" ? false : true
                                }
                                tabIdentity={currentTab}
                              />
                            </div>
                          </Grid>
                        ) : null}
                      </Grid>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    </>
  );
}

export default AnalyticsContainer;
