import {useState, useEffect} from 'react';
import {
  RootContainer,
  ItemContainer,
  ItemValue,
  ItemLabel,
  VerticalSpace
} from './styles'
import caseConvert from 'js-convert-case'

const InfoSubList = ({highlighted, ...props}) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    const propItems = []
    Object.keys(props).forEach(key => {
      propItems.push({key, value: props[key]})
    });

    setItems(propItems)
  }, [])

  return (
    <RootContainer>
      {
        items.map(item => {
          return (
            <ItemContainer key={item.key+item.value} >
              <ItemValue highlighted={item.key === highlighted} >{item.value}</ItemValue>
              <VerticalSpace count={3} />
              <ItemLabel>{caseConvert.toHeaderCase(item.key)}</ItemLabel>
            </ItemContainer>
          )
        })
      }
    </RootContainer>
  )
};

export default InfoSubList;

/*
Implementation ====> <InfoSubList total_buses="PROPS1" prop2="PROPS2" prop3="PROPS3" highlighted="prop3" />

Data we need to render are passed as props. "highlighted" prop tells which props's value should be in green
*/