const routeAnalytics = {
  routeAnalytics: [
    {
      route: "123",
      generalStats: {
        day: {
          avgIncome: "$50K",
          avgPassengers: "2546",
          noOfStops: "10",
          avgNoOfBuses: "10",
          alerts: "124",
        },
        weekly: {
          avgIncome: "$500K",
          avgPassengers: "2546",
          noOfStops: "10",
          avgNoOfBuses: "10",
          alerts: "424",
        },
        monthly: {
          avgIncome: "$60K",
          avgPassengers: "2546",
          noOfStops: "10",
          avgNoOfBuses: "10",
          alerts: "524",
        },
        yearly: {
          avgIncome: "$800K",
          avgPassengers: "2546",
          noOfStops: "10",
          avgNoOfBuses: "10",
          alerts: "624",
        },
      },
      incomeStats: {
        day: {
          totalIncome: "$100K",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 197.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 217.0,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 257.0,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 287.0,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 327.0,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 357.0,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 387.0,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 327.0,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 247.0,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 277.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 117.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 157.0,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 187.0,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 267.0,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 227.0,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 257.0,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 287.0,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 247.0,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 277.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncome: "$200K",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncome: "$300K",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncome: "$400K",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
      },
      passengersStats: {
        day: {
          totalPassengers: "2546",
          observation: "up",
          percentage: "3.0%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          totalPassengers: "3546",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          totalPassengers: "4546",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          totalPassengers: "5546",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      fuelEconomyStats: {
        day: {
          fuelEconomy: "70 Miles/Gal",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          fuelEconomy: "80 Miles/Gal",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          fuelEconomy: "90 Miles/Gal",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          fuelEconomy: "100 Miles/Gal",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      avgBusesStats: {
        day: {
          buses: "",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          buses: "",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          buses: "",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          buses: "",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      top5BusyRouteStats: {
        analytics: {
          day: [
            {
              node: "2022-10-11T11:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 6.0,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T14:00:00",
              count: 8.0,
            },
            {
              node: "2022-10-11T15:00:00",
              count: 7.0,
            },
          ],
          weekly: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
          monthly: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
          yearly: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      stopsStats: {
        day: {
          visited: "",
          notVisited: "",
        },
        weekly: {
          visited: "",
          notVisited: "",
        },
        monthly: {
          visited: "",
          notVisited: "",
        },
        yearly: {
          visited: "",
          notVisited: "",
        },
      },
    },
    {
      route: "2",
      generalStats: {
        day: {
          avgIncome: "$500K",
          avgPassengers: "5546",
          noOfStops: "20",
          avgNoOfBuses: "10",
          alerts: "524",
        },
        weekly: {
          avgIncome: "$500K",
          avgPassengers: "5546",
          noOfStops: "20",
          avgNoOfBuses: "10",
          alerts: "624",
        },
        monthly: {
          avgIncome: "$500K",
          avgPassengers: "5546",
          noOfStops: "20",
          avgNoOfBuses: "10",
          alerts: "724",
        },
        yearly: {
          avgIncome: "$500K",
          avgPassengers: "5546",
          noOfStops: "20",
          avgNoOfBuses: "10",
          alerts: "824",
        },
      },
      incomeStats: {
        day: {
          totalIncome: "$200K",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncome: "$100K",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncome: "$300K",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncome: "$500K",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
      },
      passengersStats: {
        day: {
          totalPassengers: "5546",
          observation: "up",
          percentage: "3.0%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          totalPassengers: "6546",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          totalPassengers: "7546",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          totalPassengers: "8546",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      fuelEconomyStats: {
        day: {
          fuelEconomy: "90 Miles/Gal",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          fuelEconomy: "100 Miles/Gal",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          fuelEconomy: "70 Miles/Gal",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          fuelEconomy: "80 Miles/Gal",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      avgBusesStats: {
        day: {
          buses: "",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          buses: "",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          buses: "",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          buses: "",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      top5BusyRouteStats: {
        analytics: {
          day: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
          weekly: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
          monthly: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
          yearly: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      stopsStats: {
        day: {
          visited: "",
          notVisited: "",
        },
        weekly: {
          visited: "",
          notVisited: "",
        },
        monthly: {
          visited: "",
          notVisited: "",
        },
        yearly: {
          visited: "",
          notVisited: "",
        },
      },
    },
  ],
  overAllAnalytics: {
    generalStats: {
      day: {
        avgIncome: "$700K",
        avgPassengers: "254665",
        noOfStops: "200",
        avgNoOfBuses: "50",
        alerts: "12432",
      },
      weekly: {
        avgIncome: "$5000K",
        avgPassengers: "2546",
        noOfStops: "10",
        avgNoOfBuses: "10",
        alerts: "424",
      },
      monthly: {
        avgIncome: "$600K",
        avgPassengers: "2546",
        noOfStops: "10",
        avgNoOfBuses: "10",
        alerts: "524",
      },
      yearly: {
        avgIncome: "$8000K",
        avgPassengers: "2546",
        noOfStops: "10",
        avgNoOfBuses: "10",
        alerts: "624",
      },
    },
    incomeStats: {
      day: {
        avgIncome: "$500K",
        observation: "down",
        percentage: "0.5%",
        analyticsData: [
          {
            metricName: "Current Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 117.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 217.0,
              },
              {
                node: "2022-10-11T13:00:00",
                count: 227.0,
              },
              {
                node: "2022-10-11T14:00:00",
                count: 257.0,
              },
              {
                node: "2022-10-11T15:00:00",
                count: 287.0,
              },
              {
                node: "2022-10-11T16:00:00",
                count: 327.0,
              },
              {
                node: "2022-10-11T17:00:00",
                count: 357.0,
              },
              {
                node: "2022-10-11T18:00:00",
                count: 387.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 327.0,
              },
              {
                node: "2022-10-11T21:00:00",
                count: 347.0,
              },
              {
                node: "2022-10-11T22:00:00",
                count: 377.0,
              },
            ],
          },
          {
            metricName: "Last Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 117.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 147.0,
              },
              {
                node: "2022-10-11T13:00:00",
                count: 157.0,
              },
              {
                node: "2022-10-11T14:00:00",
                count: 147.0,
              },
              {
                node: "2022-10-11T15:00:00",
                count: 187.0,
              },
              {
                node: "2022-10-11T16:00:00",
                count: 267.0,
              },
              {
                node: "2022-10-11T17:00:00",
                count: 227.0,
              },
              {
                node: "2022-10-11T18:00:00",
                count: 257.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 287.0,
              },
              {
                node: "2022-10-11T21:00:00",
                count: 247.0,
              },
              {
                node: "2022-10-11T22:00:00",
                count: 277.0,
              },
            ],
          },
        ],
      },
      weekly: {
        avgIncome: "$100K",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            metricName: "Current Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 0.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 0.0,
              },
            ],
          },
          {
            metricName: "Last Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
            ],
          },
        ],
      },
      monthly: {
        avgIncome: "$200K",
        observation: "down",
        percentage: "0.5%",
        analyticsData: [
          {
            metricName: "Current Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 0.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 0.0,
              },
            ],
          },
          {
            metricName: "Last Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
            ],
          },
        ],
      },
      yearly: {
        avgIncome: "$400K",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            metricName: "Current Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 0.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 0.0,
              },
            ],
          },
          {
            metricName: "Last Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
            ],
          },
        ],
      },
    },
    passengersStats: {
      day: {
        avgNoOfPassengers: "1546",
        observation: "up",
        percentage: "2.0%",

        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 1,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 4,
          },
          {
            node: "2022-10-11T13:00:00",
            count: 8,
          },
          {
            node: "2022-10-11T14:00:00",
            count: 3,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 10,
          },
          {
            node: "2022-10-11T16:00:00",
            count: 5,
          },
        ],
      },
      weekly: {
        avgNoOfPassengers: "2546",
        observation: "up",
        percentage: "2.0%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      monthly: {
        avgNoOfPassengers: "3546",
        observation: "up",
        percentage: "2.0%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      yearly: {
        avgNoOfPassengers: "4546",
        observation: "down",
        percentage: "3.0%",
        analyticsData: [
          {
            node: "2022-06-11T11:00:00",
            count: 3,
          },
          {
            node: "2022-07-11T12:00:00",
            count: 5,
          },
          {
            node: "2022-08-11T13:00:00",
            count: 8,
          },
          {
            node: "2022-09-11T14:00:00",
            count: 7,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 13,
          },
          {
            node: "2022-11-11T16:00:00",
            count: 6,
          },
        ],
      },
    },
    fuelEconomyStats: {
      day: {
        avgFuelEconomy: "50 Miles/Gal",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 1.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 5.0,
          },
          {
            node: "2022-10-11T13:00:00",
            count: 8.0,
          },
          {
            node: "2022-10-11T14:00:00",
            count: 2.0,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 4.0,
          },
          {
            node: "2022-10-11T16:00:00",
            count: 1.0,
          },
        ],
      },
      weekly: {
        avgFuelEconomy: "80 Miles/Gal",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      monthly: {
        avgFuelEconomy: "90 Miles/Gal",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      yearly: {
        avgFuelEconomy: "100 Miles/Gal",
        analyticsData: [
          {
            node: "2022-03-11T11:00:00",
            count: 1.0,
          },
          {
            node: "2022-04-11T12:00:00",
            count: 6.0,
          },
          {
            node: "2022-05-11T13:00:00",
            count: 2.0,
          },
          {
            node: "2022-06-11T14:00:00",
            count: 5.0,
          },
          {
            node: "2022-07-11T15:00:00",
            count: 3.0,
          },
          {
            node: "2022-08-11T16:00:00",
            count: 1.0,
          },
        ],
      },
    },
    avgBusesStats: {
      day: {
        buses: "",
        analyticsData: [
          {
            node: "2022-06-11T11:00:00",
            count: 3,
          },
          {
            node: "2022-07-11T12:00:00",
            count: 5,
          },
          {
            node: "2022-08-11T13:00:00",
            count: 8,
          },
          {
            node: "2022-09-11T14:00:00",
            count: 7,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 13,
          },
          {
            node: "2022-11-11T16:00:00",
            count: 6,
          },
        ],
      },
      weekly: {
        buses: "",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      monthly: {
        buses: "",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      yearly: {
        buses: "",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
    },
    top5BusyRouteStats: {
      analytics: {
        day: [
          {
            node: "2022-10-11T11:00:00",
            count: 2.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 6.0,
          },
          {
            node: "2022-10-11T13:00:00",
            count: 1.0,
          },
          {
            node: "2022-10-11T14:00:00",
            count: 8.0,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 7.0,
          },
        ],
        weekly: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
        monthly: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
        yearly: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
    },
    stopsStats: {
      day: {
        visited: "",
        notVisited: "",
      },
      weekly: {
        visited: "",
        notVisited: "",
      },
      monthly: {
        visited: "",
        notVisited: "",
      },
      yearly: {
        visited: "",
        notVisited: "",
      },
    },
  },
};
export default routeAnalytics;
