const useAuth = () => {
    //get item from localstorage
    let user;
    const _user = localStorage.getItem("user")
    if (_user) {
        user = JSON.parse(_user)
        //console.log("user", user)
    }
    if (user) {
        return {
            auth: true,
            role: user.role,
        }
    } else {
        return {
            auth: false,
            role: null,
        }
    }
};

export default useAuth;