import { all, takeLatest } from "redux-saga/effects";
import { handleLogin } from "./handlers/login";
import {
  handleBusesList,
  handleBusesInfo,
  handleBusesAnalyticsInfo,
} from "./handlers/buses";
import {
  handleDriversList,
  handleDriverInfo,
  handleDriversAnalyticsInfo,
} from "./handlers/drivers";
import {
  handleRoutesList,
  handleRouteInfo,
  handleRoutesAnalyticsInfo,
} from "./handlers/routes";
import { handleNotificationsList } from "./handlers/notifications";
import login from "../actions/loginActions";
import buses from "redux/actions/busesActions";
import drivers from "redux/actions/driversActions";
import routes from "redux/actions/routesActions";
import notifications from "redux/actions/notificationActions";

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchBusesList(),
    watchBusInfo(),
    watchDriversList(),
    watchDriverInfo(),
    watchRoutesList(),
    watchRouteInfo(),
    watchNotifications(),
    watchBusesAnalyticsInfo(),
    watchDriversAnalyticsInfo(),
    watchRoutesAnalyticsInfo(),
  ]);
}

export function* watchLogin() {
  yield takeLatest(login.GET_USER_LOGIN, handleLogin);
}

export function* watchBusesList() {
  yield takeLatest(buses.GET_BUSES_LIST, handleBusesList);
}

export function* watchBusInfo() {
  yield takeLatest(buses.GET_BUS_INFO, handleBusesInfo);
}

export function* watchDriversList() {
  yield takeLatest(drivers.GET_DRIVERS_LIST, handleDriversList);
}

export function* watchDriverInfo() {
  yield takeLatest(drivers.GET_DRIVER_INFO, handleDriverInfo);
}

export function* watchRoutesList() {
  yield takeLatest(routes.GET_ROUTES_LIST, handleRoutesList);
}

export function* watchRouteInfo() {
  yield takeLatest(routes.GET_ROUTE_INFO, handleRouteInfo);
}

export function* watchNotifications() {
  yield takeLatest(notifications.GET_NOTIFICATIONS, handleNotificationsList);
}

export function* watchBusesAnalyticsInfo() {
  yield takeLatest(buses.GET_BUS_ANALYTICS, handleBusesAnalyticsInfo);
}

export function* watchDriversAnalyticsInfo() {
  yield takeLatest(drivers.GET_DRIVER_ANALYTICS, handleDriversAnalyticsInfo);
}
export function* watchRoutesAnalyticsInfo() {
  yield takeLatest(routes.GET_ROUTE_ANALYTICS, handleRoutesAnalyticsInfo);
}
