import { useState, useEffect } from "react";
import GoogleMap from "../../elements/GoogleMap";
import { useLoadScript } from "@react-google-maps/api";
import appData from "../../constants/appData";
import { RootContainer } from "./styles";

const containerStyle = {
  width: "100%",
  minHeight: "330px",
};

const InfoDialogMap = (props) => {
  const { coOrdinates } = props;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: appData.apiKey,
    libraries: appData.libraries,
  });

  return (
    <RootContainer>
      <GoogleMap
        customapContainerStyle={containerStyle}
        coOrdinates={coOrdinates}
        zoom={9}
      />
    </RootContainer>
  );
};

export default InfoDialogMap;
