export const drivers = {
  GET_DRIVERS_LIST: "GET_DRIVERS_LIST",
  SET_DRIVERS_LIST: "SET_DRIVERS_LIST",
  GET_DRIVER_INFO: "GET_DRIVER_INFO",
  SET_DRIVER_INFO: "SET_DRIVER_INFO",
  GET_DRIVER_ANALYTICS: "GET_DRIVER_ANALYTICS",
  SET_DRIVER_ANALYTICS: "SET_DRIVER_ANALYTICS",
};

export const getDriversList = (payload) => ({
  type: drivers.GET_DRIVERS_LIST,
  payload: payload,
});

export const setDriversList = (driversList) => ({
  type: drivers.SET_DRIVERS_LIST,
  driversList,
});

export const getDriverInfo = (payload) => ({
  type: drivers.GET_DRIVER_INFO,
  payload: payload,
});

export const setDriverInfo = (driverInfo) => ({
  type: drivers.SET_DRIVER_INFO,
  driverInfo,
});

export const getDriverAnalytics = (payload) => ({
  type: drivers.GET_DRIVER_ANALYTICS,
  payload: payload,
});

export const setDriverAnalytics = (driverAnalyticsInfo) => ({
  type: drivers.SET_DRIVER_ANALYTICS,
  driverAnalyticsInfo,
});

export default drivers;
