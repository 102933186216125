import {useState} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AnalyticsContainer from "components/AnalyticsContainer";
import { getUserLogout } from "redux/actions/loginActions";
import useStyles from "./styles";

const Analytics = () => {
  const { busDetails } = useStyles();

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleLogout = () => {
    dispatch(getUserLogout());
    localStorage.removeItem("user");
    navigation("/login");
  };

  return (
    <>
      <Header tabIndex={1} handleLogout={handleLogout} />
        <AnalyticsContainer />
      <Footer />
    </>
  );
};

export default Analytics;
