export const notifications = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS" 
};

export const getNotifications = (payload) => ({
  type: notifications.GET_NOTIFICATIONS,
  payload: payload
});

export const setNotifications = (notificationsData) => ({
  type: notifications.SET_NOTIFICATIONS,
  notificationsData
});


export default notifications;