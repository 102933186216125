import { HorizontalSpace } from 'elements/Space';
import {
    RootContainer,
    TabButton,
    TabButtonTopBar,
    TabButtonContent,
    TabButtonText,
    TabBadge
} from './styles'; 

const AnalyticsTabBar = (props) => {
    const {
        buttonList, //[{label: "tab label", value: "some value", badge}]
        currentTab,
        onChange
    } = props;

    return (
        <RootContainer>
            {
                buttonList?.map(button => (
                    <TabButton focused={currentTab === button.value} onClick={() => onChange(button.value)} >
                        <TabButtonTopBar focused={currentTab === button.value} />
                        <TabButtonContent>
                            <TabButtonText focused={currentTab === button.value} >{button.label}</TabButtonText>
                            <HorizontalSpace count={2.5} />
                            <TabBadge focused={currentTab === button.value} >{button.badge}</TabBadge>
                        </TabButtonContent>
                    </TabButton>
                ))
            }
        </RootContainer>
    )
}

export default AnalyticsTabBar;