import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getUserLogout } from "../../redux/actions/loginActions";
import DashBoardContainer from "../../components/DashboardContainer";

const DashBoard = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleLogout = () => {
    dispatch(getUserLogout());
    localStorage.removeItem("user");
    navigation("/login");
  };

  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header tabIndex={0} handleLogout={handleLogout} />
          <DashBoardContainer />
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default DashBoard;
