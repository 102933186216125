import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  GoogleMap,
  MarkerF,
  PolylineF,
  InfoWindowF,
} from "@react-google-maps/api";
import Avatar from "@mui/material/Avatar";
import {
  routeDestinationIcon,
  routeSourceIcon,
  routeStopIcon,
  sourceLocation,
  destinationGreenIcon,
  rightArrowExtended,
  busesIcon,
  distanceCoveredIcon,
  driverIcon,
  fuelIcon,
  stopsIcon,
  speedIcon,
  videoIcon,
} from "assets/icons";
import theme from "../../theme";
import greenBus from "../../assets/green_bus.svg";
import movingBus from "../../assets/green_bus_moving.svg";
import redBus from "../../assets/redBus.svg";
import orangeBus from "../../assets/orangeBus.svg";
import Button from "../Button/button";
import { FlexSpace, HorizontalSpace, VerticalSpace } from "../Space/space";
import {
  RootContainer,
  TitleContainer,
  DefaultBody,
  BodyText,
  BodyTextLine2,
  BodyTextLine3,
  DateTimeText,
  ExpandedBody,
  DetailItems,
  FooterText,
  RootSubContainer,
  DetailItems2,
  DetailItemsValue,
  DetailItems1,
  DetailsContainer1,
  DetailsContainer,
  IconText,
  IconTextValue,
  DetailItemTitle,
  DetailItemContent,
  VideoIcon,
} from "./styles";

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 108px)",
  [theme.breakpoints.down(600)]: {
    height: "calc(100vh - 180px)",
  },
};

const GMap = (props) => {
  const {
    coOrdinates,
    cardList,
    tabIndex,
    handleViewDetails,
    type,
    handleExpandingList,
    customapContainerStyle,
    handleClickDrawRoute,
    zoom,
    datafromList,
  } = props;

  const notificationsListData = useSelector(
    (state) => state.notifications.notificationsData
  );

  const [eventsList, setEventsList] = useState();
  const [incidentsList, setIncidentsList] = useState();
  const [oprAlertsList, setOprAlertsList] = useState();

  useEffect(() => {
    if (notificationsListData) {
      setEventsList(notificationsListData?.notifications?.events?.eventsList);
      setOprAlertsList(
        notificationsListData?.notifications?.oprAlerts?.oprAlertsList
      );
      setIncidentsList(
        notificationsListData?.notifications?.incidents?.incidentsList
      );
    }
  }, []);

  let [points, setPoints] = useState([]);
  const [progress, setProgress] = useState([]);
  let [data, setData] = useState(points);
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {
    setActiveMarker(null);
  }, [cardList]);

  let velocity = 500;
  let initialDate = new Date();

  const fetchDirection = async () => {
    const directionService = new window.google.maps.DirectionsService();

    const results = await directionService.route({
      origin: coOrdinates?.sourceCoOrdinates,
      destination: coOrdinates?.destinationCoOrdinates,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    setPoints(JSON.parse(JSON.stringify(results?.routes[0]?.overview_path)));
  };

  const calculatePath = () => {
    data = points.map((coordinates, i, array) => {
      if (i === 0) {
        return { ...coordinates, distance: 0 }; // it begins here!
      }
      const { lat: lat1, lng: lng1 } = coordinates;
      const latLong1 = new window.google.maps.LatLng(lat1, lng1);

      const { lat: lat2, lng: lng2 } = array[0];
      const latLong2 = new window.google.maps.LatLng(lat2, lng2);

      // in meters:
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          latLong1,
          latLong2
        );
      return { ...coordinates, distance };
    });
    setData(data);
  };

  const getDistance = () => {
    const differentInTime = (new Date() - initialDate) / 1000; // pass to seconds
    return differentInTime * velocity; // d = v*t -- thanks Newton!
  };

  const moveObject = () => {
    const distance = getDistance();
    if (!distance) {
      return;
    }
    let progress = data.filter(
      (coordinates) => coordinates.distance < distance
    );

    const nextLine = data.find(
      (coordinates) => coordinates.distance > distance
    );

    if (!nextLine) {
      // this.setState({ progress });
      setProgress(progress);
      // window.clearInterval(interval);
      return; // it's the end!
    }
    const lastLine = progress[progress.length - 1];

    const lastLineLatLng = new window.google.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    );

    const nextLineLatLng = new window.google.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    );

    // distance of this line
    const totalDistance = nextLine.distance - lastLine.distance;
    const percentage = (distance - lastLine.distance) / totalDistance;

    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );

    const angle = window.google.maps.geometry.spherical.computeHeading(
      lastLineLatLng,
      nextLineLatLng
    );
    const actualAngle = angle - 90;

    const markers = document.querySelectorAll(`[src="${movingBus}"]`);

    if (markers && markers.length) {
      // when it hasn't loaded, it's null
      [...markers].map(
        (marker) => (marker.style.transform = `rotate(${actualAngle}deg)`)
      );
    }

    progress = progress.concat(position);

    setProgress(progress);
  };

  useEffect(() => {
    if (coOrdinates === "") {
      setPoints([]);
    } else {
      fetchDirection();
    }
  }, [coOrdinates]);

  useEffect(() => {
    if (points?.length > 0) {
      calculatePath();
    }
  }, [points]);

  useEffect(() => {
    if (coOrdinates !== undefined) {
      const timer = setInterval(() => {
        moveObject();
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [data]);

  let lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 4,
  };

  let icon1 = {
    url: movingBus,
    scaledSize: new window.google.maps.Size(60, 60),
    anchor: new window.google.maps.Point(30, 30),
    scale: 0.7,
  };

  const stops = [
    coOrdinates?.sourceCoOrdinates,
    coOrdinates?.nextStopCoOrdinates,
    coOrdinates?.destinationCoOrdinates,
  ];

  const handleActiveMarker = (marker, index) => {
    handleExpandingList(index);
    if (marker === activeMarker) {
      setActiveMarker(null);
      return;
    }
    handleClickDrawRoute(marker);
    setActiveMarker(marker.busNo);
  };

  useEffect(() => {
    if (datafromList !== null) {
      setActiveMarker(datafromList?.busNo);
    } else {
      setActiveMarker(null);
    }
    if (datafromList === activeMarker) {
      setActiveMarker(null);
      return;
    }
  }, [datafromList]);

  return (
    <div>
      <GoogleMap
        center={{
          lat: coOrdinates?.sourceCoOrdinates?.lat
            ? coOrdinates?.sourceCoOrdinates?.lat
            : 10.364558407178833,
          lng: coOrdinates?.sourceCoOrdinates?.lng
            ? coOrdinates?.sourceCoOrdinates?.lng
            : -84.47743152619893,
        }}
        zoom={zoom}
        mapContainerStyle={
          customapContainerStyle ? customapContainerStyle : containerStyle
        }
      >
        {tabIndex === 0 &&
          eventsList &&
          eventsList.map((marker, index) => (
            <MarkerF
              position={{
                lat: marker?.currentLocation?.coOrdinates?.lat,
                lng: marker?.currentLocation?.coOrdinates?.lng,
              }}
              icon={{
                url: greenBus,
              }}
              key={index}
              onClick={() => handleActiveMarker(marker, index)}
            >
              {activeMarker === marker.busNo ? (
                <InfoWindowF
                  onCloseClick={() => setActiveMarker(null)}
                  position={{
                    lat: marker?.currentLocation?.coOrdinates?.lat,
                    lng: marker?.currentLocation?.coOrdinates?.lng,
                  }}
                >
                  <RootContainer>
                    <TitleContainer>
                      <span style={{ marginLeft: "-5px" }}>
                        {marker.incident} | BUS#{marker.busNo} | Route -{" "}
                        {marker.route}
                      </span>
                    </TitleContainer>
                    <VerticalSpace count={1} />
                    <ExpandedBody>
                      <VerticalSpace count={1} />

                      <DefaultBody>
                        <img src={sourceLocation} />
                        <HorizontalSpace count={0.5} />
                        <BodyText expanded={true} source={true}>
                          {marker.source.locality}
                        </BodyText>
                        <HorizontalSpace count={1} />
                        <img src={rightArrowExtended} />
                        <HorizontalSpace count={2} />
                        <img src={destinationGreenIcon} />
                        <HorizontalSpace count={0.5} />
                        <BodyText expanded={true} source={false}>
                          {marker.destination.locality}
                        </BodyText>
                      </DefaultBody>
                      <VerticalSpace count={3} />
                      <DetailItems>
                        <BodyTextLine2>
                          Driver - {marker.driver} | License Number -{" "}
                          {marker.licenceNo}
                        </BodyTextLine2>
                        <VerticalSpace count={4} />
                        <BodyTextLine3>
                          Current Location - {marker.currentLocation.locality}
                        </BodyTextLine3>
                      </DetailItems>
                      <VerticalSpace count={4} />
                      <FooterText>
                        <Button
                          disable={true}
                          variant="contained"
                          handleClick={(e) =>
                            handleViewDetails(tabIndex, marker)
                          }
                        >
                          Take Action
                        </Button>
                        <FlexSpace />
                        <DateTimeText>{marker.timeStamp}</DateTimeText>
                      </FooterText>
                    </ExpandedBody>
                  </RootContainer>
                </InfoWindowF>
              ) : null}
            </MarkerF>
          ))}
        {tabIndex === 0 &&
          incidentsList &&
          incidentsList.map((marker, index) => (
            <MarkerF
              position={{
                lat: marker?.currentLocation?.coOrdinates?.lat,
                lng: marker?.currentLocation?.coOrdinates?.lng,
              }}
              icon={{
                url: redBus,
              }}
              key={index}
              onClick={() => handleActiveMarker(marker, index)}
            >
              {activeMarker === marker.busNo ? (
                <InfoWindowF
                  onCloseClick={() => setActiveMarker(null)}
                  position={{
                    lat: marker?.currentLocation?.coOrdinates?.lat,
                    lng: marker?.currentLocation?.coOrdinates?.lng,
                  }}
                >
                  <RootContainer>
                    <TitleContainer>
                      <span style={{ marginLeft: "-5px" }}>
                        {marker.incident} | BUS#{marker.busNo} | Route -{" "}
                        {marker.route}
                      </span>
                    </TitleContainer>
                    <VerticalSpace count={1} />
                    <ExpandedBody>
                      <VerticalSpace count={1} />

                      <DefaultBody>
                        <img src={sourceLocation} />
                        <HorizontalSpace count={0.5} />
                        <BodyText expanded={true} source={true}>
                          {marker.source.locality}
                        </BodyText>
                        <HorizontalSpace count={1} />
                        <img src={rightArrowExtended} />
                        <HorizontalSpace count={2} />
                        <img src={destinationGreenIcon} />
                        <HorizontalSpace count={0.5} />
                        <BodyText expanded={true} source={false}>
                          {marker.destination.locality}
                        </BodyText>
                      </DefaultBody>
                      <VerticalSpace count={3} />
                      <DetailItems>
                        <BodyTextLine2>
                          Driver - {marker.driver} | License Number -{" "}
                          {marker.licenceNo}
                        </BodyTextLine2>
                        <VerticalSpace count={4} />
                        <BodyTextLine3>
                          Current Location - {marker.currentLocation.locality}
                        </BodyTextLine3>
                      </DetailItems>
                      <VerticalSpace count={4} />
                      <FooterText>
                        <Button
                          disable={true}
                          variant="contained"
                          handleClick={(e) =>
                            handleViewDetails(tabIndex, marker)
                          }
                        >
                          Take Action
                        </Button>
                        <FlexSpace />
                        <DateTimeText>{marker.timeStamp}</DateTimeText>
                      </FooterText>
                    </ExpandedBody>
                  </RootContainer>
                </InfoWindowF>
              ) : null}
            </MarkerF>
          ))}
        {tabIndex === 0 &&
          oprAlertsList &&
          oprAlertsList.map((marker, index) => (
            <MarkerF
              position={{
                lat: marker?.currentLocation?.coOrdinates?.lat,
                lng: marker?.currentLocation?.coOrdinates?.lng,
              }}
              icon={{
                url: orangeBus,
              }}
              key={index}
              onClick={() => handleActiveMarker(marker, index)}
            >
              {activeMarker === marker.busNo ? (
                <InfoWindowF
                  onCloseClick={() => setActiveMarker(null)}
                  position={{
                    lat: marker?.currentLocation?.coOrdinates?.lat,
                    lng: marker?.currentLocation?.coOrdinates?.lng,
                  }}
                >
                  <RootContainer>
                    <TitleContainer>
                      <span style={{ marginLeft: "-5px" }}>
                        {marker.incident} | BUS#{marker.busNo} | Route -{" "}
                        {marker.route}
                      </span>
                    </TitleContainer>
                    <VerticalSpace count={1} />
                    <ExpandedBody>
                      <VerticalSpace count={1} />

                      <DefaultBody>
                        <img src={sourceLocation} />
                        <HorizontalSpace count={0.5} />
                        <BodyText expanded={true} source={true}>
                          {marker.source.locality}
                        </BodyText>
                        <HorizontalSpace count={1} />
                        <img src={rightArrowExtended} />
                        <HorizontalSpace count={2} />
                        <img src={destinationGreenIcon} />
                        <HorizontalSpace count={0.5} />
                        <BodyText expanded={true} source={false}>
                          {marker.destination.locality}
                        </BodyText>
                      </DefaultBody>
                      <VerticalSpace count={3} />
                      <DetailItems>
                        <BodyTextLine2>
                          Driver - {marker.driver} | License Number -{" "}
                          {marker.licenceNo}
                        </BodyTextLine2>
                        <VerticalSpace count={4} />
                        <BodyTextLine3>
                          Current Location - {marker.currentLocation.locality}
                        </BodyTextLine3>
                      </DetailItems>
                      <VerticalSpace count={4} />
                      <FooterText>
                        <Button
                          disable={true}
                          variant="contained"
                          handleClick={(e) =>
                            handleViewDetails(tabIndex, marker)
                          }
                        >
                          Take Action
                        </Button>
                        <FlexSpace />
                        <DateTimeText>{marker.timeStamp}</DateTimeText>
                      </FooterText>
                    </ExpandedBody>
                  </RootContainer>
                </InfoWindowF>
              ) : null}
            </MarkerF>
          ))}

        {tabIndex !== 0 &&
          type !== "completedRoutes" &&
          cardList &&
          cardList.map(
            (marker, index) =>
              marker.currentLocationCoOrdinates && (
                <MarkerF
                  position={{
                    lat: marker.currentLocationCoOrdinates?.lat,
                    lng: marker.currentLocationCoOrdinates?.lng,
                  }}
                  icon={{
                    url: greenBus,
                  }}
                  key={index}
                  onClick={() => handleActiveMarker(marker, index)}
                >
                  {activeMarker === marker.busNo ? (
                    <InfoWindowF
                      onCloseClick={() => setActiveMarker(null)}
                      position={{
                        lat: marker.currentLocationCoOrdinates?.lat,
                        lng: marker.currentLocationCoOrdinates?.lng,
                      }}
                    >
                      <RootSubContainer>
                        <TitleContainer>
                          {tabIndex === 1 || tabIndex === 3 ? (
                            <>
                              <span style={{ marginLeft: "-5px" }}>
                                {tabIndex === 1
                                  ? `${"BUS#"}${marker.title}`
                                  : marker.title}
                              </span>
                              <DefaultBody>
                                <img src={sourceLocation} />
                                <HorizontalSpace count={0.5} />
                                <BodyText expanded={true} source={true}>
                                  {marker.source}
                                </BodyText>
                                <HorizontalSpace count={1} />
                                <img src={rightArrowExtended} />
                                <HorizontalSpace count={2} />
                                <img src={destinationGreenIcon} />
                                <HorizontalSpace count={0.5} />
                                <BodyText expanded={true} source={false}>
                                  {marker.destination}
                                </BodyText>
                              </DefaultBody>
                              {tabIndex === 1 && (
                                <VideoIcon>
                                  <img src={videoIcon} />
                                </VideoIcon>
                              )}
                            </>
                          ) : (
                            <>
                              <Avatar alt="Remy Sharp" src={marker.imageUrl} />
                              <HorizontalSpace count={3} />
                              <span style={{ marginLeft: "-5px" }}>
                                {marker.title}
                              </span>
                            </>
                          )}
                        </TitleContainer>
                        <VerticalSpace count={1} />
                        <ExpandedBody>
                          {tabIndex === 2 && <VerticalSpace count={4} />}
                          {tabIndex === 2 && (
                            <DefaultBody>
                              <img src={sourceLocation} />
                              <HorizontalSpace count={0.5} />
                              <BodyText expanded={true} source={true}>
                                {marker.source}
                              </BodyText>
                              <HorizontalSpace count={1} />
                              <img src={rightArrowExtended} />
                              <HorizontalSpace count={2} />
                              <img src={destinationGreenIcon} />
                              <HorizontalSpace count={0.5} />
                              <BodyText expanded={true} source={false}>
                                {marker.destination}
                              </BodyText>
                            </DefaultBody>
                          )}
                          <VerticalSpace count={3} />
                          {tabIndex === 1 || tabIndex === 3 ? (
                            <DetailsContainer1>
                              <DetailsContainer>
                                {tabIndex === 1 ? (
                                  <DetailItems1>
                                    <img src={speedIcon} />
                                    <HorizontalSpace count={2} />
                                    <DetailItems>
                                      <IconTextValue>
                                        {marker.speed}
                                      </IconTextValue>
                                      <IconText>Speed</IconText>
                                    </DetailItems>
                                  </DetailItems1>
                                ) : (
                                  <DetailItems1>
                                    <img src={busesIcon} />
                                    <HorizontalSpace count={2} />
                                    <DetailItems>
                                      <IconTextValue>
                                        {marker.buses}
                                      </IconTextValue>
                                      <IconText>Buses</IconText>
                                    </DetailItems>
                                  </DetailItems1>
                                )}
                                <DetailItems1>
                                  <img src={fuelIcon} />
                                  <HorizontalSpace count={2} />
                                  <DetailItems>
                                    <IconTextValue>
                                      {marker.fuelConsumed}
                                    </IconTextValue>
                                    <IconText>fuel Consumed</IconText>
                                  </DetailItems>
                                </DetailItems1>
                                <DetailItems1>
                                  <img src={distanceCoveredIcon} />
                                  <HorizontalSpace count={2} />
                                  <DetailItems>
                                    <IconTextValue>
                                      {marker.distance}
                                    </IconTextValue>
                                    <IconText>Distance Covered</IconText>
                                  </DetailItems>
                                </DetailItems1>
                                <DetailItems1>
                                  <img src={stopsIcon} />
                                  <HorizontalSpace count={2} />
                                  <DetailItems>
                                    <IconTextValue>
                                      {marker.stops}
                                    </IconTextValue>
                                    <IconText>Stops</IconText>
                                  </DetailItems>
                                </DetailItems1>
                                <DetailItems1>
                                  <img src={driverIcon} />
                                  <HorizontalSpace count={2} />
                                  <DetailItems>
                                    <IconTextValue>
                                      {marker.driver}
                                    </IconTextValue>
                                    <IconText>Driver</IconText>
                                  </DetailItems>
                                </DetailItems1>
                              </DetailsContainer>
                              <VerticalSpace count={6} />
                              <DetailItems1>
                                <DetailItems>
                                  <DetailItemsValue currentLocation={true}>
                                    {marker.currentLocation}
                                  </DetailItemsValue>
                                  <DetailItems2>Current Location</DetailItems2>
                                </DetailItems>
                                <HorizontalSpace count={10} />
                                <DetailItems>
                                  <DetailItemsValue currentLocation={false}>
                                    {marker.nextStop}
                                  </DetailItemsValue>
                                  <DetailItems2>Next Stop</DetailItems2>
                                </DetailItems>
                              </DetailItems1>
                            </DetailsContainer1>
                          ) : (
                            <DetailsContainer>
                              <DetailItems>
                                <DetailItemTitle>
                                  {marker.licenseNumber}
                                </DetailItemTitle>
                                <DetailItemContent>
                                  License Number
                                </DetailItemContent>
                              </DetailItems>
                              <HorizontalSpace count={10} />
                              <DetailItems>
                                <DetailItemTitle>
                                  {marker.busNo}
                                </DetailItemTitle>
                                <DetailItemContent>
                                  Bus Number
                                </DetailItemContent>
                              </DetailItems>
                              <HorizontalSpace count={10} />
                              <DetailItems>
                                <DetailItemTitle>
                                  {marker.busPlateNo}
                                </DetailItemTitle>
                                <DetailItemContent>
                                  Bus Licence Plate Number
                                </DetailItemContent>
                              </DetailItems>
                            </DetailsContainer>
                          )}
                          <VerticalSpace count={6} />
                          <FooterText>
                            <Button
                              variant="contained"
                              handleClick={(e) =>
                                handleViewDetails(tabIndex, marker)
                              }
                            >
                              view Details
                            </Button>
                            <FlexSpace />
                            <DateTimeText>{marker.timeStamp}</DateTimeText>
                          </FooterText>
                        </ExpandedBody>
                      </RootSubContainer>
                    </InfoWindowF>
                  ) : null}
                </MarkerF>
              )
          )}

        {points && points.length > 0 && (
          <PolylineF
            path={points}
            options={{
              strokeColor: "#123D64",
              strokeWeight: 10,
              strokeOpacity: 0,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "0",
                  repeat: "20px",
                },
              ],
            }}
          />
        )}

        {coOrdinates &&
          stops &&
          stops.length &&
          stops.map((stop, index) => (
            <>
              <MarkerF
                key={stop?.id}
                position={{
                  lat: stop?.lat,
                  lng: stop?.lng,
                }}
                icon={{
                  url:
                    index === 0
                      ? routeSourceIcon
                      : index === stops.length - 1
                      ? routeDestinationIcon
                      : routeStopIcon,
                }}
                title={stop?.id}
                label={`${index + 1}`}
              />
            </>
          ))}

        {coOrdinates &&
          points &&
          points.length > 0 &&
          progress &&
          type !== "completedRoutes" && (
            <>
              <PolylineF path={progress} options={{ strokeColor: "#1D5472" }} />
              <MarkerF icon={icon1} position={progress[progress.length - 1]} />
            </>
          )}
      </GoogleMap>
    </div>
  );
};

export default GMap;
