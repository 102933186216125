import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const INF_Chart = (props) => {
  const {
    type,
    splineWidth,
    width,
    height,
    dataPoints,
    format,
    graphSequence,
    tooltipShow,
    isCrosshair,
    dataLabels,
    tabIdentity,
  } = props;
  const [toolTipBg, setToolTipBg] = useState();
  const [tBorder, setTBorder] = useState();

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: type,
          plotBackgroundColor: "transparent",
          backgroundColor: "transparent",
          height: height,
          width: width,
        },
        scrollbar: {
          barBackgroundColor: "gray",
          barBorderRadius: 7,
          barBorderWidth: 0,
          buttonBackgroundColor: "gray",
          buttonBorderWidth: 0,
          buttonBorderRadius: 7,
          trackBackgroundColor: "none",
          trackBorderWidth: 1,
          trackBorderRadius: 8,
          trackBorderColor: "#CCC",
        },
        title: false,
        legend: false,
        xAxis: {
          crosshair: isCrosshair
            ? {
                enabled: false,
                width: 2,
                color: "gray",
                dashStyle: "ShortDash",
              }
            : false,
          type: "datetime",
          labels: {
            overflow: "justify",
            format: format,
          },
        },
        yAxis: {
          title: false,
          gridLineDashStyle: "ShortDash",
          labels: {
            // style: {
            //   backgroundImage:
            //     "linear-gradient(to top, rgba(148, 159, 255, 0), #949FFF)",
            //   fontSize: "10px",
            //   fontFamily: "QualcommNext-Regular",
            // },
          },
        },

        tooltip: {
          shared: false,
          useHTML: true,
          backgroundColor: toolTipBg,
          borderColor: tBorder,
          padding: 4,
          className: "tooltipStyle",
          style: {
            color: "#FFFFFF",
            fontWeight: "bold",
          },
          formatter: function () {
            if (tooltipShow) {
              const value = this.y;

              if (graphSequence === "graph1") {
                if (tabIdentity === "drivers") {
                  if (this.color == "#949FFF") {
                    setToolTipBg("#4FBAF0");
                    setTBorder("#4FBAF0");
                  }
                } else if (this.color == "#949FFF") {
                  setToolTipBg("#949FFF");
                  setTBorder("#485AFF");
                } else {
                  setToolTipBg("#70CDCD");
                  setTBorder("#4ac0c0");
                }
              } else if (graphSequence === "graph2") {
                if (this.color == "#8CB4FF") {
                  setToolTipBg("#4967AD");
                  setTBorder("#6290FD");
                }
              } else if (graphSequence === "graph3") {
                if (tabIdentity === "drivers") {
                  if (this.color == "#7cb5ec") {
                    setToolTipBg("#FABB70");
                    setTBorder("#FABB70");
                  }
                } else if (this.color == "#7cb5ec") {
                  setToolTipBg("#00A3FF");
                  setTBorder("#0A8AD1");
                }
              } else if (graphSequence === "graph4") {
                if (this.color == "#7cb5ec") {
                  setToolTipBg("#65D6CF");
                  setTBorder("#65D6CF");
                } else {
                  setToolTipBg("#E5456F");
                  setTBorder("#C21B47");
                }
              }

              return ` <table>
          <tr>
            <td style="text-align: center;">
                ${
                  tabIdentity === "buses"
                    ? graphSequence === "graph1"
                      ? this.color == "#7cb5ec"
                        ? `$${value}K`
                        : `$${value}K`
                      : graphSequence === "graph2"
                      ? this.color == "#8CB4FF"
                        ? `${value} Miles`
                        : `${value} Miles`
                      : graphSequence === "graph3"
                      ? this.color == "#7cb5ec"
                        ? `${value} Gal`
                        : `${value} Gal`
                      : graphSequence === "graph4"
                      ? this.color == "#7cb5ec"
                        ? `${value}Hrs`
                        : `${value}Hrs`
                      : null
                    : tabIdentity === "drivers"
                    ? graphSequence === "graph1"
                      ? this.color == "#949FFF"
                        ? `${value}`
                        : `${value}`
                      : graphSequence === "graph2"
                      ? this.color == "#8CB4FF"
                        ? `${value} Miles`
                        : `${value} Miles`
                      : graphSequence === "graph3"
                      ? this.color == "#7cb5ec"
                        ? `${value}$`
                        : `${value}$`
                      : graphSequence === "graph4"
                      ? this.color == "#7cb5ec"
                        ? `${value}Hrs`
                        : `${value}Hrs`
                      : null
                    : tabIdentity === "routes"
                    ? graphSequence === "graph1"
                      ? this.color == "#7cb5ec"
                        ? `$${value}K`
                        : `$${value}K`
                      : graphSequence === "graph2"
                      ? this.color == "#8CB4FF"
                        ? `${value}`
                        : `${value}`
                      : graphSequence === "graph3"
                      ? this.color == "#7cb5ec"
                        ? `${value} Miles/Gal`
                        : `${value} Miles/Gal`
                      : graphSequence === "graph4"
                      ? this.color == "#7cb5ec"
                        ? `${value}`
                        : `${value}`
                      : null
                    : null
                }
            </td>
          </tr>
        </table>`;

              // return `<table>
              //               <tr>
              //               <td style="font-weight:bold;color:white ">
              //                 ${this.points.reduce(function (s, point) {
              //                   return (
              //                     point.series.name + ": " + point.y + "<br/>" + s
              //                   );
              //                 }, `<b> ${new Date(this.x).toLocaleDateString(
              //                   "nl-Nl"
              //                 )} </b>`)}
              //               </td>
              //         </tr>
              //       </table>`;
            } else {
              return false;
            }
          },
        },

        plotOptions: {
          column: {
            dataLabels: {
              enabled: dataLabels,
              format:
                '<div style="font-size: 11px; line-height: 20px">${y}K</div>',
              backgroundColor: "#456EFF",
              color: "white",
              y: -4,
              shadow: true,
              align: "center",
              shape: "circle",
            },
          },
          series: {
            fillColor:
              tabIdentity === "buses" || tabIdentity === "routes"
                ? null
                : {
                    linearGradient: [0, 0, 0, 300],
                    stops: [
                      // [0, "#949FFF"],
                      [0, "#0C8ACC"],
                      [
                        1,
                        Highcharts.color("#FFFFFF").setOpacity(0).get("rgba"),
                      ],
                    ],
                  },
            borderColor: "transparent",
            marker: {
              enabled: false,
            },

            lineWidth: splineWidth,
          },
        },
        credits: {
          enabled: false,
        },
        series: dataPoints,
      }}
    />
  );
};

export default INF_Chart;
