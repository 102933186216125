import {
  FlexSpace,
  HorizontalSpace,
  VerticalSpace,
} from "elements/Space/space";
import {
  infoBusIdIcon,
  infoDistanceCoveredIcon,
  infoDriverIcon,
  infoFuelConsumedIcon,
  infoSpeedIcon,
  infoStopsIcon,
  infoViolationsIcon,
  infoWorkStatusIcon,
} from "assets/icons";
import {
  RootContainer,
  ItemContainer,
  ItemValue,
  ItemLabel,
  ItemValueContainer,
} from "./styles";

const InfoVideoTitle = (prop) => {
  const {
    dialogBoxTabIndexData,
    infoVideoTitleListData: {
      speed,
      fuelConsumed,
      distance,
      stops,
      driver,
      workStatus,
      busNo,
      violations,
    },
  } = prop;

  return (
    <RootContainer>
      <ItemContainer>
        {dialogBoxTabIndexData === 1 ? (
          <img src={infoSpeedIcon} />
        ) : dialogBoxTabIndexData === 2 ? (
          <img src={infoWorkStatusIcon} />
        ) : dialogBoxTabIndexData === 3 ? (
          <img src={infoBusIdIcon} />
        ) : null}
        <HorizontalSpace count={2} />
        <ItemValueContainer>
          <ItemValue>
            {dialogBoxTabIndexData === 1
              ? speed
              : dialogBoxTabIndexData === 2
              ? workStatus
              : dialogBoxTabIndexData === 3
              ? busNo
              : null}
          </ItemValue>
          <ItemLabel>
            {dialogBoxTabIndexData === 1
              ? "Speed"
              : dialogBoxTabIndexData === 2
              ? "Work Status"
              : dialogBoxTabIndexData === 3
              ? "Bus ID"
              : null}
          </ItemLabel>
        </ItemValueContainer>
      </ItemContainer>
      <ItemContainer>
        {dialogBoxTabIndexData === 1 || dialogBoxTabIndexData === 3 ? (
          <img src={infoFuelConsumedIcon} />
        ) : dialogBoxTabIndexData === 2 ? (
          <img src={infoSpeedIcon} />
        ) : null}
        <HorizontalSpace count={2} />
        <ItemValueContainer>
          <ItemValue>
            {dialogBoxTabIndexData === 1 || dialogBoxTabIndexData === 3
              ? fuelConsumed
              : dialogBoxTabIndexData === 2
              ? speed
              : null}
          </ItemValue>
          <ItemLabel>
            {dialogBoxTabIndexData === 1 || dialogBoxTabIndexData === 3
              ? "Fuel Consumed"
              : dialogBoxTabIndexData === 2
              ? "Speed"
              : null}
          </ItemLabel>
        </ItemValueContainer>
      </ItemContainer>
      <ItemContainer>
        <img src={infoDistanceCoveredIcon} />
        <HorizontalSpace count={2} />
        <ItemValueContainer>
          <ItemValue>{distance}</ItemValue>
          <ItemLabel>Distance Covered</ItemLabel>
        </ItemValueContainer>
      </ItemContainer>
      <ItemContainer>
        {dialogBoxTabIndexData === 1 || dialogBoxTabIndexData === 3 ? (
          <img src={infoStopsIcon} />
        ) : dialogBoxTabIndexData === 2 ? (
          <img src={infoBusIdIcon} />
        ) : null}
        <HorizontalSpace count={2} />
        <ItemValueContainer>
          <ItemValue>
            {dialogBoxTabIndexData === 1 || dialogBoxTabIndexData === 3
              ? stops
              : dialogBoxTabIndexData === 2
              ? busNo
              : null}
          </ItemValue>
          <ItemLabel>
            {dialogBoxTabIndexData === 1 || dialogBoxTabIndexData === 3
              ? "Stops"
              : dialogBoxTabIndexData === 2
              ? "Bus ID"
              : null}
          </ItemLabel>
        </ItemValueContainer>
      </ItemContainer>
      <ItemContainer>
        {dialogBoxTabIndexData === 1 || dialogBoxTabIndexData === 3 ? (
          <img src={infoDriverIcon} />
        ) : dialogBoxTabIndexData === 2 ? (
          <img src={infoViolationsIcon} />
        ) : null}
        <HorizontalSpace count={2} />
        <ItemValueContainer>
          <ItemValue>
            {dialogBoxTabIndexData === 1 || dialogBoxTabIndexData === 3
              ? driver
              : dialogBoxTabIndexData === 2
              ? violations
              : null}
          </ItemValue>
          <ItemLabel>
            {dialogBoxTabIndexData === 1 || dialogBoxTabIndexData === 3
              ? "Driver"
              : dialogBoxTabIndexData === 2
              ? "Violations"
              : null}
          </ItemLabel>
        </ItemValueContainer>
      </ItemContainer>
    </RootContainer>
  );
};
export default InfoVideoTitle;
