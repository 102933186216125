import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  tabSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: " 8px 16px 0 16px",
    borderBottom: "1px solid #d8d8d8",
    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
    },
    "& .MuiTabs-root": {
      marginTop: "6px !important",
    },
    "& .MuiTab-root": {
      fontFamily: "OpenSans-Bold",
      fontSize: 11,
      padding: "0px !important",
      paddingBottom: "8px !important",
      [theme.breakpoints.up("xl")]: {
        fontSize: 12,
      },
    },
    "& .MuiTab-root.Mui-selected": {
      color: theme.palette.tabsColor,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.tabsColor,
      borderRadius: "0px 0px 50px 50px !important",
      transform: "rotate(-180deg)",
      height: 4,
    },
  },
  customNotificationTabs: {
    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
      minWidth: "64px !important",
    },
  },
  searchIcon: {
    display: "flex",
    alignItems: "center",
    "& :first-child": {
      marginRight: 11,
      width: "26px !important",
      cursor: "pointer",
    },
    "& :last-child": {
      width: "38px !important",
    },
  },
  searchClass: {
    margin: "5px 15px 0px 15px",
    border: "1px solid #d7d7d7",
    borderRadius: 6,
    "& .MuiIconButton-root": {
      marginRight: 7,
    },
  },
}));

export default useStyles;
