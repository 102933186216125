import drivers from "redux/actions/driversActions";

const initialState = {
  driversList: [],
  driverInfo: {},
  driverAnalyticsInfo: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case drivers.SET_DRIVERS_LIST:
      const { driversList } = action;
      return { ...state, driversList };
    case drivers.SET_DRIVER_INFO:
      const { driverInfo } = action;
      return { ...state, driverInfo };
    case drivers.SET_DRIVER_ANALYTICS:
      const { driverAnalyticsInfo } = action;
      return { ...state, driverAnalyticsInfo };
    default:
      return state;
  }
};
