import { put } from "redux-saga/effects";
import { setUserLogin } from "../../actions/loginActions";
import fetchAPIServices from "../../../services/fetchAPIServices";
import { loginApi } from "../../../services/endPoints";

export function* handleLogin(action) {
  try {
    if (action && action.payload && action.payload.logout) {
      yield put(setUserLogin({}));
    } else {
      const { fetchLogin } = fetchAPIServices;
      const response = yield fetchLogin(loginApi, action.payload);
      if (response) {
        yield put(setUserLogin(response));
      } else {
        yield put(setUserLogin({}));
      }
    }
  } catch (error) {
    console.log(error);
  }
}
