import { styled } from "@mui/material/styles";

export const RootContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #242424;
  border-radius: 6px;
  flex: 1;
`;

export const ItemContainer = styled("div")`
  display: flex;
  align-items: flex-start;
`;

export const ItemValueContainer = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ItemValue = styled("div")`
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  line-height: 17.7px;
`;

export const ItemLabel = styled("div")`
  font-size: 11px;
  font-weight: 600;
  line-height: 14.98px;
  color: #8a8a8a;
`;

export const VerticalSpace = styled("div")`
  height: ${({ count }) => count * 4}px;
`;
