import { Chip, ChipLabel, ChipBadgeContainer } from "./styles";

const ChipButton = (props) => {
  const { data, index, handleClick } = props;

  const handleChip = () => {
    handleClick(data, index);
  };

  return (
    <Chip focused={data.focused} highlight={data.color} onClick={handleChip}>
      <ChipLabel focused={data.focused}>{data.label}</ChipLabel>
      <ChipBadgeContainer>{data.count}</ChipBadgeContainer>
    </Chip>
  );
};

export default ChipButton;
