import {styled} from '@mui/material/styles';

export const RootContainer = styled('div')`
    margin: 13px 15px;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
`;

export const LabelText = styled('div')`
    font-size: 10px;
    line-height: 13.62px;
    font-weight: 600;
    color: #8A8A8A;
`;

export const LabelContainer = styled("div")`
    margin-top: -15px;
    text-align: center;
`;

export const ProgressMask = styled("img")`
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    position: absolute;
    top: 50%;
    transform: translateY(-65%);
    z-index: 1;
`;

export const ValueContainer = styled("div")`
    position: absolute;
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: ${({color}) => color};
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
`;

export const ProgressBarStroke = styled("div")`
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: ${({radius}) => radius*1.6}px;
    height: ${({radius}) => radius*1.6}px;
    border: 2px dashed ${({color}) => color};
    border-radius: ${({radius}) => radius*2}px;
`;