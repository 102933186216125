import { ThemeContext } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import TransportBg from "../../assets/login-bg.jpg";
import theme from "../../theme";
const useStyles = makeStyles(() => ({
  loginBg: {
    backgroundImage: `url("${TransportBg}")`,
    background: "#848484",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoSection: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loginFieldBox: {
    maxWidth: 486,
    margin: "0 auto",
    padding: "22px 60px 60px 50px",
    background: "#000",
    background: "rgba(0, 0, 0, 0.69)",
    boxShadow: "0px 0px 5px 3px rgb(0 0 0 / 10%)",
    borderRadius: "13px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px !important",
      margin: "24px !important",
    },
  },
  logoImage: {
    marginBottom: 24,
  },
  loginHeading: {
    color: theme.palette.white,
    textAlign: "center",
    margin: "30px 0 40px 0 !important",
    fontFamily: "Roboto-Light",
  },
  customTextField: {
    marginBottom: 30,
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInputLabel-root": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      color: "white",
      background: "rgb(0 0 0 / 40%)",
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "&.MuiInputBase-root": {
      color: "white",
    },
  },
  loginButton: {
    marginBottom: 50,
    "& .MuiButton-root": {
      color: "#fff",
      fontSize: "20px",
      border: "0.5px solid #FFFFFF",
      borderRadius: "24.1792px",
      lineHeight: "21px",
      padding: "16px 2px",
      textTransform: "capitalize !important",
    },
  },
  poweredBySection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  poweredBy: {
    fontWeight: 200,
    fontSize: "16px !important",
    lineHeight: "19px !important",
    textAlign: "center",
    color: "#FFFFFF",
  },
  formikErrorClass: {
    color: "white",
    padding: 0,
    margin: 0,
    paddingTop: 5,
  },
}));
export default useStyles;
