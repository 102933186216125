import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import {
  RootContainer,
  TitleContainer,
  DefaultBody,
  BodyText,
  DateTimeText,
  ExpandedBody,
  DetailsContainer,
  DetailItems,
  DetailItemTitle,
  DetailItemContent,
  FooterText,
  DetailItems1,
  IconText,
  DetailsContainer1,
  DetailItems2,
  DetailItemsValue,
  IconTextValue,
  TitleText,
  VideoIcon,
} from "./styles";
import {
  FlexSpace,
  HorizontalSpace,
  VerticalSpace,
} from "elements/Space/space";
import {
  sourceLocation,
  destinationLocation,
  rightArrowExtended,
  busesIcon,
  distanceCoveredIcon,
  driverIcon,
  fuelIcon,
  stopsIcon,
  speedIcon,
  videoIcon,
} from "assets/icons";
import Button from "elements/Button";
const DashboardListItem = (props) => {
  const {
    data,
    data: {
      title,
      source,
      destination,
      timeStamp,
      driver,
      licenseNumber,
      currentLocation,
      nextStop,
      speed,
      stops,
      fuelConsumed,
      distance,
      busNo,
      busPlateNo,
      imageUrl,
      buses,
    },
    tabIndex,
    handleIsOpen,
    index,
    selected,
    handleViewAll,
    chipButtonTabIndex,
  } = props;

  return (
    <RootContainer
      onClick={() => handleIsOpen(index, tabIndex, data, chipButtonTabIndex)}
    >
      <TitleContainer>
        {selected === index && tabIndex === 2 ? (
          <Avatar alt="Remy Sharp" src={imageUrl} />
        ) : null}
        <HorizontalSpace count={3} />
        <TitleText>{title}</TitleText>
        {selected === index && tabIndex === 1 && (
          <VideoIcon>
            <img src={videoIcon} />
          </VideoIcon>
        )}
      </TitleContainer>
      <VerticalSpace count={2} />
      {selected !== index ? (
        <DefaultBody>
          <img src={sourceLocation} />
          <HorizontalSpace count={0.5} />
          <BodyText expanded={null} source={null}>
            {source}
          </BodyText>
          <HorizontalSpace count={1} />
          <img src={rightArrowExtended} />
          <HorizontalSpace count={2} />
          <img src={destinationLocation} />
          <HorizontalSpace count={0.5} />
          <BodyText expanded={null} source={null}>
            {destination}
          </BodyText>
          <FlexSpace />
          <DateTimeText>{timeStamp}</DateTimeText>
        </DefaultBody>
      ) : (
        <ExpandedBody>
          <DefaultBody>
            <img src={sourceLocation} />
            <HorizontalSpace count={0.5} />
            <BodyText expanded={true} source={true}>
              {source}
            </BodyText>
            <HorizontalSpace count={1} />
            <img src={rightArrowExtended} />
            <HorizontalSpace count={2} />
            <img src={destinationLocation} />
            <HorizontalSpace count={0.5} />
            <BodyText expanded={true} source={false}>
              {destination}
            </BodyText>
          </DefaultBody>
          <VerticalSpace count={3} />
          {tabIndex === 0 ? (
            <DetailItems>
              <BodyText expanded={null} source={null}>
                {driver} | {licenseNumber}
              </BodyText>
              <VerticalSpace count={4} />
              <BodyText expanded={null} source={null}>
                Current Location - {currentLocation}
              </BodyText>
            </DetailItems>
          ) : tabIndex === 1 || tabIndex === 3 ? (
            <DetailsContainer1>
              <DetailsContainer>
                {tabIndex === 1 ? (
                  <DetailItems1>
                    <img src={speedIcon} />
                    <HorizontalSpace count={2} />
                    <DetailItems>
                      <IconTextValue>{speed}</IconTextValue>
                      <IconText>Speed</IconText>
                    </DetailItems>
                  </DetailItems1>
                ) : (
                  <DetailItems1>
                    <img src={busesIcon} />
                    <HorizontalSpace count={2} />
                    <DetailItems>
                      <IconTextValue>{buses}</IconTextValue>
                      <IconText>Buses</IconText>
                    </DetailItems>
                  </DetailItems1>
                )}
                <DetailItems1>
                  <img src={fuelIcon} />
                  <HorizontalSpace count={2} />
                  <DetailItems>
                    <IconTextValue>{fuelConsumed}</IconTextValue>
                    <IconText>fuel Consumed</IconText>
                  </DetailItems>
                </DetailItems1>
                <DetailItems1>
                  <img src={distanceCoveredIcon} />
                  <HorizontalSpace count={2} />
                  <DetailItems>
                    <IconTextValue>{distance}</IconTextValue>
                    <IconText>Distance Covered</IconText>
                  </DetailItems>
                </DetailItems1>
                <DetailItems1>
                  <img src={stopsIcon} />
                  <HorizontalSpace count={2} />
                  <DetailItems>
                    <IconTextValue>{stops}</IconTextValue>
                    <IconText>Stops</IconText>
                  </DetailItems>
                </DetailItems1>
                <DetailItems1>
                  <img src={driverIcon} />
                  <HorizontalSpace count={2} />
                  <DetailItems>
                    <IconTextValue>{driver}</IconTextValue>
                    <IconText>Driver</IconText>
                  </DetailItems>
                </DetailItems1>
              </DetailsContainer>
              <VerticalSpace count={6} />
              <DetailItems1>
                <DetailItems>
                  <DetailItemsValue currentLocation={true}>
                    {currentLocation}
                  </DetailItemsValue>
                  <DetailItems2>Current Location</DetailItems2>
                </DetailItems>
                <HorizontalSpace count={10} />
                <DetailItems>
                  <DetailItemsValue currentLocation={false}>
                    {nextStop}
                  </DetailItemsValue>
                  <DetailItems2>Next Stop</DetailItems2>
                </DetailItems>
              </DetailItems1>
            </DetailsContainer1>
          ) : (
            <DetailsContainer>
              <DetailItems>
                <DetailItemTitle>{licenseNumber}</DetailItemTitle>
                <DetailItemContent>License Number</DetailItemContent>
              </DetailItems>
              <DetailItems>
                <DetailItemTitle>{busNo}</DetailItemTitle>
                <DetailItemContent>Bus Number</DetailItemContent>
              </DetailItems>
              <DetailItems>
                <DetailItemTitle>{busPlateNo}</DetailItemTitle>
                <DetailItemContent>Bus Licence Plate Number</DetailItemContent>
              </DetailItems>
            </DetailsContainer>
          )}
          <VerticalSpace count={4} />
          <FooterText>
            {tabIndex === 0 ? (
              <Button
                disable={true}
                variant="contained"
                handleClick={(e) => handleViewAll(tabIndex)}
              >
                Take Action
              </Button>
            ) : (
              <Button
                disable={false}
                variant="contained"
                handleClick={(e) => handleViewAll(tabIndex)}
              >
                View Details
              </Button>
            )}

            <FlexSpace />
            <DateTimeText>{timeStamp}</DateTimeText>
          </FooterText>
        </ExpandedBody>
      )}
    </RootContainer>
  );
};
export default DashboardListItem;
