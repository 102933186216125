import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  analyticsContainerSection: {
    height: "calc(100vh - 108px) !important",
  },
  graphSection: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
    padding: 12,
    margin: 10,
    flex: 1,
  },
  incomeText: {
    fontFamily: "OpenSans-Bold",
    fontSize: 16,
    lineHeight: "22px",
    color: theme.palette.blue,
    marginRight: 16,
    [theme.breakpoints.down(1537)]: {
      fontSize: 13,
    },
  },
  incomeCurrentText: {
    fontFamily: "OpenSans-Medium",
    fontSize: 13,
    lineHeight: "22px",
    color: theme.palette.darkGreyOne,
    marginRight: 8,
    [theme.breakpoints.down(1537)]: {
      fontSize: 10,
      marginRight: 5,
    },
  },
  incomeCurrentTextDot: {
    width: 12,
    height: 12,
    backgroundColor: theme.palette.lightBlueOne,
    borderRadius: "50%",
    marginRight: 7,
    [theme.breakpoints.down(1537)]: {
      width: 9,
      height: 9,
      marginRight: 5,
    },
  },
  incomeCurrentSection: {
    display: "flex",
    alignItems: "center",
  },
  incomeLastTextDot: {
    width: 12,
    height: 12,
    backgroundColor: theme.palette.darkGreenOne,
    borderRadius: "50%",
    marginRight: 7,
    [theme.breakpoints.down(1537)]: {
      width: 9,
      height: 9,
    },
  },
  avgIncomeText: {
    fontFamily: "OpenSans-Medium",
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.darkGreyOne,
    marginRight: 8,
    marginTop: 2,
    [theme.breakpoints.down(1537)]: {
      fontSize: 9,
    },
  },
  followersText: {
    fontFamily: "OpenSans-Bold",
    fontSize: 15,
    lineHeight: "20px",
    color: theme.palette.lightBlueTwo,
    [theme.breakpoints.down(1537)]: {
      fontSize: 12,
    },
  },
  followersTextBlue: {
    fontFamily: "OpenSans-Bold",
    fontSize: 15,
    lineHeight: "20px",
    color: theme.palette.lightBlueThree,
    [theme.breakpoints.down(1537)]: {
      fontSize: 12,
    },
  },
  followersTextGreen: {
    fontFamily: "OpenSans-Bold",
    fontSize: 15,
    lineHeight: "20px",
    color: theme.palette.darkGreenOne,
    [theme.breakpoints.down(1537)]: {
      fontSize: 12,
    },
  },
  followersTextGrey: {
    fontFamily: "OpenSans-Bold",
    fontSize: 15,
    lineHeight: "20px",
    color: theme.palette.darkGreyTwo,
    [theme.breakpoints.down(1537)]: {
      fontSize: 12,
    },
  },
  followersPercentage: {
    fontFamily: "OpenSans-Medium",
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.darkGreenTwo,
    marginLeft: 7,
    [theme.breakpoints.down(1537)]: {
      fontSize: 10,
    },
  },
  followersPercentageRed: {
    fontFamily: "OpenSans-Medium",
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.darkRedOne,
    marginLeft: 7,
    [theme.breakpoints.down(1537)]: {
      fontSize: 10,
    },
  },
  arrow: {
    marginRight: 2,
  },
  analyticsGridSection: {
    display: "flex",
    flexDirection: "column !important",
    height: "calc(100vh - 144px) !important",
  },
  flex1: {
    flex: 1,
  },
  analyticsGrid: {
    display: "flex",
    flexDirection: "column !important",
    height: "100% !important",
  },
}));

export default useStyles;
