import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles(
  {
    stepperContainer: {
      "& ul": {
        padding: "6px 0",
      },
      "& li::before": {
        display: "none",
      },
      "& .MuiTimelineContent-root": {
        padding: "6px 0 6px 16px",
      },
      "& .MuiTimelineDot-defaultGrey": {
        backgroundColor: "unset !important",
        boxShadow: "unset !important",
      },
      "& .MuiTimelineDot-root": {
        padding: 0,
        marginTop: 0,
        marginBottom: 0,
      },
      "& .MuiTimelineSeparator-root": {
        height: "90px",
      },
    },
    routeDetailasContainer: {
      padding: "10px 10px 10px 14px",
      border: "1px solid #dcdada",
      background: "#3a3a3a",
      borderRadius: "5px",
      cursor: "pointer",
      borderTopLeftRadius: "0px !important",
      borderBottomLeftRadius: "0px !important",
      height: "calc(100vh - 354px)",
      overflow: "auto",
      boxSizing: "border-box",
      [theme.breakpoints.down("lg")]: {
        height: "calc(100vh - 400px)",
      },
    },
    routesHeading: {
      fontFamily: "OpenSans-Bold",
      fontSize: 16,
      lineHeight: "22px",
      margin: "0 0 10px 0",
      [theme.breakpoints.down("lg")]: {
        fontSize: 14,
      },
    },
    routesSubHeading: {
      fontFamily: "OpenSans-Medium",
      fontSize: 13,
      lineHeight: "22px",
      margin: 0,
      [theme.breakpoints.down("lg")]: {
        fontSize: 12,
      },
    },
    routeName: {
      fontFamily: "OpenSans-Medium",
      fontSize: 11,
      lineHeight: "15px",
      marginBottom: 5,
      color: "#AFAFAF",
      [theme.breakpoints.down("lg")]: {
        fontSize: 10,
      },
    },
    routeArea: {
      fontFamily: "OpenSans-Medium",
      fontSize: 12,
      lineHeight: "16px",
      marginBottom: 7,
      [theme.breakpoints.down("lg")]: {
        fontSize: 11,
      },
    },
    routeTimestamp: {
      fontFamily: "OpenSans-Medium",
      fontSize: 9,
      lineHeight: "12px",
      marginBottom: 1,
    },
    routeNameSpan: {
      background: "#FB4A4A",
      borderRadius: 2,
      padding: 2,
      color: "#fff",
      marginLeft: 10,
      fontSize: 10,
    },
    routeNameSpanNextStop: {
      background: "#79BE6D",
      borderRadius: 2,
      padding: 2,
      color: "#fff",
      marginLeft: 10,
      fontSize: 10,
    },
    timeLineConnector: {
      left: "-2px",
      width: "2px",
      position: "relative",
      backgroundColor: "#1D5472",
    },
    timeLineConnectorDashed: {
      left: "-2px",
      position: "relative",
      backgroundColor: "unset",
      width: "unset",
      borderLeft: "3px dashed #456EFF",
    },
  },
  { index: 1 }
);
export default useStyles;
