import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Tabs from "../../elements/Tabs";
import { Typography } from "@mui/material";
import DashboardList from "../DashboardList";
import upArrow from "../../assets/icons/up-arrow.svg";
import upArrowDown from "../../assets/icons/up-arrow-red.svg";
import Chart from "elements/Chart";
import useStyles from "./styles";

const AnalyticsGraphContainer = (props) => {
  const {} = useStyles();
  const {
    busAnalyticsGraphData,
    type,
    minWidth,
    height,
    toolTipShared,
    splineWidth,
    dataFormate,
    graphSequence,
    tooltipShow,
    isCrosshair,
    dataLabels,
    tabIdentity,
  } = props;

  return (
    <>
      <Chart
        type={type}
        minWidth={minWidth}
        height={height}
        dataPoints={busAnalyticsGraphData}
        format={dataFormate}
        toolTipShared={toolTipShared}
        splineWidth={splineWidth}
        graphSequence={graphSequence}
        tooltipShow={tooltipShow}
        isCrosshair={isCrosshair}
        dataLabels={dataLabels}
        tabIdentity={tabIdentity}
      />
    </>
  );
};

export default AnalyticsGraphContainer;
