const routesInfo = {
  routesInfo: {
    route: "Route#123",
    totalBuses: "475",
    avgRevenue: "$124K",
    regionDepot: "Costa Rica",
    totalTrips: "17451",
    safetyScore: "75",
    fuelUsage: "20 Gal",
    totalPassengers: "25896",
    totalHours: "255467",
    totalViolations: "578",
    totalMiles: "1500",
    trips: [
      {
        tripNo: "1",
        tripStatus: "In Progress",
        totalOccupancy: "20/40",
        busId: "Bus#123",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        stops: "03",
        driver: "Tim John",
        occupancy: "80",
        safetyScore: "75",
        harshAccelaration: "00",
        overspeeding: "10",
        corner: "0",
        fuelEfficiency: "12",
        harshBraking: "0",
        idleHours: "0",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "2",
        tripStatus: "In Progress",
        totalOccupancy: "20/40",
        busId: "Bus#123",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        stops: "03",
        driver: "Tim John",
        occupancy: "80",
        safetyScore: "75",
        harshAccelaration: "00",
        overspeeding: "10",
        corner: "0",
        fuelEfficiency: "12",
        harshBraking: "0",
        idleHours: "0",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "3",
        tripStatus: "Completed",
        totalOccupancy: "20/40",
        busId: "Bus#123",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        stops: "03",
        driver: "Tim John",
        occupancy: "80",
        safetyScore: "75",
        harshAccelaration: "00",
        overspeeding: "10",
        corner: "0",
        fuelEfficiency: "12",
        harshBraking: "0",
        idleHours: "0",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "4",
        tripStatus: "Completed",
        totalOccupancy: "20/40",
        busId: "Bus#123",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        stops: "03",
        driver: "Tim John",
        occupancy: "80",
        safetyScore: "75",
        harshAccelaration: "00",
        overspeeding: "10",
        corner: "0",
        fuelEfficiency: "12",
        harshBraking: "0",
        idleHours: "0",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "5",
        tripStatus: "Completed",
        totalOccupancy: "20/40",
        busId: "Bus#123",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        stops: "03",
        driver: "Tim John",
        occupancy: "80",
        safetyScore: "75",
        harshAccelaration: "00",
        overspeeding: "10",
        corner: "0",
        fuelEfficiency: "12",
        harshBraking: "0",
        idleHours: "0",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "6",
        tripStatus: "Completed",
        totalOccupancy: "20/40",
        busId: "Bus#123",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        stops: "03",
        driver: "Tim John",
        occupancy: "80",
        safetyScore: "75",
        harshAccelaration: "00",
        overspeeding: "10",
        corner: "0",
        fuelEfficiency: "12",
        harshBraking: "0",
        idleHours: "0",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "7",
        tripStatus: "Completed",
        totalOccupancy: "20/40",
        busId: "Bus#123",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        stops: "03",
        driver: "Tim John",
        occupancy: "80",
        safetyScore: "75",
        harshAccelaration: "00",
        overspeeding: "10",
        corner: "0",
        fuelEfficiency: "12",
        harshBraking: "0",
        idleHours: "0",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
    ],
    pastViolations: [
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
    ],
  },
};
export default routesInfo;
