import React, { useEffect, useState } from "react";
import DashboardListItem from "components/DashboardListItem";
import ChipButton from "elements/ChipButton";
import useStyles from "./styles";
import InfoDialog from "components/InfoDialog";

const DashboardList = (props) => {
  const {
    chipButtonSection,
    notificationListSection,
    notificationListNewClass,
  } = useStyles();
  const {
    tabIndex,
    cardList,
    chipButtons,
    coOrdinates,
    expandListFromMap,
    indexFromMap,
    chipButtonTabIndex,
    searchIsOpen,
  } = props;

  const [cardListData, setCardListData] = useState(cardList);

  const [tabIndexChild1, setTabIndexChild1] = useState(0);
  const [chipButtonTabIndex1, setChipButtonTabIndex1] =
    useState(chipButtonTabIndex);
  const [selected, setSelected] = useState(null);
  const [infoVideoTitleListData, setInfoVideoTitleListData] = useState();
  const [viewAllIsOpen, setViewAllIsOpen] = useState(false);
  const [dialogBoxTabIndexData, setDialogBoxTabIndexData] = useState(null);

  useEffect(() => {
    setCardListData(cardList);
    setSelected(null);
  }, [cardList]);

  useEffect(() => {
    if (selected === indexFromMap) {
      setSelected(null);
    } else {
      setSelected(indexFromMap);
    }
    setTabIndexChild1(tabIndex);
    setChipButtonTabIndex1(chipButtonTabIndex);
  }, [expandListFromMap, indexFromMap]);

  const handleChipButton = (data, index) => {
    props.handleChipButton(data, index);
  };

  const handleIsOpen = (index, tabIndexChild, data, chipButtonTabIndex) => {
    if (selected === index) {
      setSelected(null);
      props.handleClickFromListToMap(null);
    } else {
      setSelected(index);
      props.handleClickFromListToMap(data);
    }

    setTabIndexChild1(tabIndexChild);
    setChipButtonTabIndex1(chipButtonTabIndex);
    setInfoVideoTitleListData(data);
    props.handleClickDrawRoute(data);
  };

  const handleViewAll = (tabIndexData) => {
    setViewAllIsOpen(!viewAllIsOpen);
    setDialogBoxTabIndexData(tabIndexData);
  };
  console.log(searchIsOpen, "test");

  return (
    <>
      <div className={chipButtonSection}>
        {tabIndex === 0 || tabIndex === 3
          ? chipButtons && chipButtons.length > 0
            ? chipButtons.map((item, index) => {
                return (
                  <ChipButton
                    key={index}
                    data={item}
                    index={index}
                    handleClick={handleChipButton}
                  />
                );
              })
            : null
          : null}
      </div>
      <div
        className={notificationListSection}
        style={{
          height: !searchIsOpen ? "calc(100vh - 249px)" : "calc(100vh - 300px)",
        }}
      >
        {cardListData && cardListData.length > 0 ? (
          cardListData.map((item, index) => {
            return (
              <DashboardListItem
                key={index}
                data={item}
                tabIndex={tabIndex}
                index={index}
                handleIsOpen={handleIsOpen}
                selected={
                  tabIndexChild1 === tabIndex &&
                  chipButtonTabIndex1 === chipButtonTabIndex
                    ? selected
                    : null
                }
                handleViewAll={handleViewAll}
                chipButtonTabIndex={chipButtonTabIndex}
              />
            );
          })
        ) : (
          <div>
            <span style={{ padding: 10, color: "gray" }}>No Result Found</span>
          </div>
        )}
      </div>
      {viewAllIsOpen && (
        <InfoDialog
          handleViewAll={handleViewAll}
          dialogBoxTabIndexData={dialogBoxTabIndexData}
          tabIndex={tabIndex}
          infoVideoTitleListData={infoVideoTitleListData}
          coOrdinates={coOrdinates}
        />
      )}
    </>
  );
};
export default DashboardList;
