const routes = {
  activeRoutes: {
    totalCount: "3",
    routes: [
      {
        route: "Route#1",
        busId: "Bus#1",
        noOfBuses: "02",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        stops: "02/05",
        driver: "Tim John",
        timeStamp: "08-05-2022 | 10:00 AM",
        source: {
          area: "Florrencia, Costa Rica",
          coOrdinates: {
            lat: 10.364558407178833,
            lng: -84.47743152619893,
          },
        },
        destination: {
          area: "Jicarito, Costa Rica",
          coOrdinates: {
            lat: 10.605794407884536,
            lng: -84.704989450137,
          },
        },
        currentLocation: {
          area: "Tabacon, Costa Rica",
          coOrdinates: {
            lat: 10.364558407178833,
            lng: -84.47743152619893,
          },
        },
        nextStop: {
          area: "Muelle San Carlos",
          coOrdinates: {
            lat: 10.473848132759747,
            lng: -84.46963269522386,
          },
        },
      },
      {
        route: "Route#2",
        busId: "Bus#2",
        noOfBuses: "02",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        stops: "02/05",
        driver: "Tim John",
        timeStamp: "08-05-2022 | 10:00 AM",
        source: {
          area: "San Jorge",
          coOrdinates: {
            lat: 10.717084314358102,
            lng: -84.67959872583144,
          },
        },
        destination: {
          area: "Boco Arenal",
          coOrdinates: {
            lat: 10.535121220817171,
            lng: -84.48273248094928,
          },
        },
        currentLocation: {
          area: "San Jorge",
          coOrdinates: {
            lat: 10.717084314358102,
            lng: -84.67959872583144,
          },
        },
        nextStop: {
          area: "Santa Rosa de Pocosol",
          coOrdinates: {
            lat: 10.628080138363762,
            lng: -84.52937188836226,
          },
        },
      },
      {
        route: "Route#3",
        busId: "Bus#3",
        noOfBuses: "02",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        stops: "02/05",
        driver: "Tim John",
        timeStamp: "08-05-2022 | 10:00 AM",
        source: {
          area: "San Rafael de Guatuso",
          coOrdinates: {
            lat: 10.666413876081828,
            lng: -84.8112894535626,
          },
        },
        destination: {
          area: "Pocosol",
          coOrdinates: {
            lat: 10.381380505208913,
            lng: -84.61877218354736,
          },
        },
        currentLocation: {
          area: "San Rafael de Guatuso",
          coOrdinates: {
            lat: 10.666413876081828,
            lng: -84.8112894535626,
          },
        },
        nextStop: {
          area: "La Fortuna",
          coOrdinates: {
            lat: 10.470200326746983,
            lng: -84.64022251241668,
          },
        },
      },
    ],
  },
  completedRoutes: {
    totalCount: "3",
    routes: [
      {
        route: "Route#1",
        busId: "Bus#1",
        noOfBuses: "02",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        stops: "02/05",
        driver: "Tim John",
        timeStamp: "08-05-2022 | 10:00 AM",
        source: {
          area: "Florrencia, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
        destination: {
          area: "Jicarito, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
        currentLocation: {
          area: "Tabacon, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
        nextStop: {
          area: "San Jose, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
      },
      {
        route: "Route#2",
        busId: "Bus#2",
        noOfBuses: "02",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        stops: "02/05",
        driver: "Tim John",
        timeStamp: "08-05-2022 | 10:00 AM",
        source: {
          area: "Florrencia, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
        destination: {
          area: "Palma, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
        currentLocation: {
          area: "Mirador, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
        nextStop: {
          area: "Pataste, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
      },
      {
        route: "Route#3",
        busId: "Bus#3",
        noOfBuses: "02",
        fuelConsumed: "20 Gal",
        distanceCovered: "20 Miles",
        stops: "02/05",
        driver: "Tim John",
        timeStamp: "08-05-2022 | 10:00 AM",
        source: {
          area: "Florrencia, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
        destination: {
          area: "Jicarito, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
        currentLocation: {
          area: "Mirador, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
        nextStop: {
          area: "Pataste, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
        },
      },
    ],
  },
};
export default routes;
