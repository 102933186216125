import * as React from "react";
import { Button } from "@mui/material";
import useStyles from "./styles.js";

const INF_Button = (props) => {
  const { loginButton, CustomButton, CustomButtonDisable } = useStyles();
  const { variant, handleClick, type, children, disable, buttonStyles } = props;

  const handleButtonClick = () => {
    handleClick();
  };
  return (
    <>
      <Button
        variant={variant || "outlined"}
        className={
          variant
            ? disable
              ? CustomButtonDisable
              : buttonStyles
              ? buttonStyles
              : CustomButton
            : loginButton
        }
        onClick={handleButtonClick}
        type={type}
        disabled={disable}
      >
        {children}
      </Button>
    </>
  );
};
export default INF_Button;
