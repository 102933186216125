import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles(
  {
    RootContainer: {
      boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.05)",
      borderRadius: "5px",
      background: theme.palette.grey8,
      flex: "1",
    },
    TitleContainer: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      lineHeight: "17.7px",
      fontFamily: "Opensans-Bold",
      borderRadius: "5px",
      padding: "15px 5px 15px 10px",
      margin: "15px",
      cursor: "pointer",
    },
    ButtonContainer: {
      display: "flex",
      fontSize: "13px",
      lineHeight: "17.7px",
      fontFamily: "Opensans-Bold",
      borderRadius: "5px",
      margin: "20px 15px 15px 15px",
      cursor: "pointer",
      "& .MuiTextField-root": {
        height: "50px",
        paddingRight: "10px",
        flex: 1,
        "& label": {
          top: "-3px",
          color: theme.palette.lightGrey,
        },
        "& .MuiInputBase-root": {
          height: "50px",
          backgroundColor: theme.palette.white,
        },
      },
    },
    buttonStyles: {
      backgroundColor: `${theme.palette.darkBlue} !important`,
      borderRadius: "5px !important",
      width: "130px",
      height: "50px",
      fontFamily: "Opensans-Bold !important",
      fontSize: "12px !important",
      lineHeight: "16px !important",
    },
    buttonStylesDisable: {
      backgroundColor: `${theme.palette.lightGreyOne} !important`,
      border: `1px solid ${theme.palette.lightGreyTwo}`,
      borderRadius: "5px !important",
      width: "130px",
      height: "50px",
      fontFamily: "Opensans-Bold !important",
      fontSize: "12px !important",
      lineHeight: "16px !important",
    },
    title: {
      flex: 1,
      "& button": {
        backgroundColor: "#466475 !important",
        borderRadius: "5px !important",
        width: "140px",
        height: "50px",
      },
    },
    flex1: {
      flex: 1,
    },
  },
  { index: 1 }
);

export default useStyles;
