import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
const styles = makeStyles(
  () => ({
    root: {
      flexGrow: 1,
    },
    videoSection: {
      padding: 24,
    },
    logoImg: {
      display: "flex",
      alignItems: "center",
      background: theme.palette.lightBlueEight,
      borderRadius: "10px",
      padding: "4px 12px",
      "& :first-child": {
        cursor: "pointer",
        // width: 70,
        // [theme.breakpoints.down("900")]: {
        //   width: 50,
        // },
      },
      "& :last-child": {
        [theme.breakpoints.down("900")]: {
          // width: 140,
        },
      },
    },
    header: {
      alignItems: "center",
      background: "#FFFFFF",
      boxShadow: "0px 4px 4px 3px rgba(0, 0, 0, 0.05)",
      padding: "4px 10px",
      zIndex: 1500,
    },
    headerRight: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("600")]: {
        justifyContent: "center",
      },
    },
    avatharSection: {
      display: "flex",
      alignItems: "center",
      marginRight: 2,
      "& .MuiIconButton-root": {
        zIndex: 1500,
      },
    },
    avatharBackground: {
      backgroundColor: "#424242 !important",
    },
    logoSection: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(600)]: {
        backgroundColor: theme.palette.lightBlueEight,
      },
    },
    logoutBtnBg: {
      background: "#1f4a91",
      padding: "3px 17px",
      color: "white",
      fontWeight: 600,
      borderRadius: 5,
    },
    avatharName: {
      marginLeft: 10,
      "& p": {
        margin: 0,
      },
      "& :first-child": {
        fontFamily: "OpenSans-Medium",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        color: "#444444",
        textTransform: "uppercase",
        [theme.breakpoints.down(600)]: {
          fontSize: "15px",
        },
      },
      "& :last-child": {
        fontFamily: "OpenSans-Regular",
        fontWeight: 600,
        fontSize: "13px",
        lineHeight: "19px",
        color: "#444444",
        [theme.breakpoints.down(600)]: {
          fontSize: "10px",
        },
      },
    },
    avatharIcon: {
      "&.MuiIconButton-root": {
        color: "#444444",
      },
    },
    moreIconClass: {
      height: 50,
    },
    logoutSection: {
      display: "flex",
      alignItems: "center",
    },
    logoutImg: {
      width: 20,
      marginRight: 7,
    },
    logoutText: {
      fontSize: "14px !important",
      fontWeight: "700 !important",
      color: "#716F6F",
    },
    customMenu: {
      "& .MuiMenu-list": {
        background: "#fff !important",
        marginTop: "10px !important",
        border: "1px solid #d7d7d7 !important",
        borderRadius: "4px !important",
        marginLeft: 26,
      },
      "& .MuiPaper-root": {
        background: "none !important",
        boxShadow: "none !important",
      },
      "& .MuiList-root": {
        "&::before": {
          width: 10,
          height: 10,
          content: '""',
          transform: "rotate(225deg)",
          boxSizing: "border-box",
          backgroundColor: "#fff",
          position: "absolute",
          right: 17,
          top: -6,
          border: "1px solid #d7d7d7",
          borderStyle: "solid",
          borderWidth: "0px 1px 1px 0px",
        },
      },
      "& .MuiButtonBase-root": {
        padding: "0px 14px !important",
        "&:hover": {
          background: "transparent !important",
        },
      },
    },
    // MenuClassStyle: {
    //   background: `url(${MenuBgImage})`,
    // },
    LogoutContent: {
      padding: "0 !important",
    },
    companyName: {
      "& p": {
        margin: 0,
        fontSize: "12px",
      },
      "& :first-child": {
        fontSize: 22,
        fontWeight: 800,
        lineHeight: "20px",
        color: "#2c2c2c",
      },
      "& :last-child": {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 2,
        color: "#2c2c2c",
      },
      avatharIcon: {
        color: "#d7d7d7",
      },
    },
    tabStyle: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: "30px",
      marginTop: "10px",
      [theme.breakpoints.down(600)]: {
        marginRight: "8px",
      },
    },

    serverStatusContainer: {
      display: "flex",
      alignItems: "center",
      marginRight: "20px",
    },
    serverStatusStyle: {
      fontFamily: "Open Sans",
      fontWeight: "700",
      lineHeight: "16.34px",
      fontSize: "12px",
      color: "#FFFFFF",
      borderRadius: "5px",
      padding: "4px 0",
      width: "134px",
      textAlign: "center",
    },
    tabMainClass: {
      "& .MuiButtonBase-root": {
        fontFamily: "Roboto-Bold",
        fontWeight: "900",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#8A8D8F",
        padding: "0",
        paddingTop: "13px !important",
        marginRight: "50px !important",
        zIndex: 1500,
        [theme.breakpoints.down(600)]: {
          marginRight: "8px !important",
          fontSize: "12px",
        },
      },
      "& .Mui-selected": {
        color: "#123D64 !important",
      },
      "& .MuiTab-root": {
        textTransform: "uppercase",
        alignItems: "flex-end !important",
        marginRight: 5,
        justifyContent: "flex-start !important",
      },
      "& .MuiTabs-indicator": {
        background: "#123D64 !important",
        width: "34.51px !important",
        height: "3px !important",
        borderRadius: "2.98541px",
        position: "absolute",
        bottom: 10,
        transform: "translate(55px, 1px) !important",
      },
    },
  }),
  { index: 1 }
);

export default styles;
