import routes from "redux/actions/routesActions";

const initialState = {
  routesList: [],
  routeInfo: {},
  routeAnalyticsInfo: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case routes.SET_ROUTES_LIST:
      const { routesList } = action;
      return { ...state, routesList };
    case routes.SET_ROUTE_INFO:
      const { routeInfo } = action;
      return { ...state, routeInfo };
    case routes.SET_ROUTE_ANALYTICS:
      const { routeAnalyticsInfo } = action;
      return { ...state, routeAnalyticsInfo };
    default:
      return state;
  }
};
