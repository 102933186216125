const palette = {
  //  mode: 'light',
  palette: {
    primary: {
      main: "",
      light: "",
      dark: "",
    },
    secondary: {
      main: "",
      light: "",
      dark: "",
    },
  },
  success: {},
  error: {},
  warning: {},
  info: {},
  black: "#000000",
  white: "#ffffff",
  grey1: "#d7d7d7",
  grey2: "#e9e9e9",
  grey3: "#d9d9d9",
  grey4: "#242424",
  grey5: "#999999",
  grey6: "#F8F9FA",
  grey7: "#A4A4A4",
  grey8: "#e8e8e8",
  grey9: "#F5F5F5",
  grey10: "#4E4E4E",
  lightGrey: "#A8A8A8",
  darkGreyOne: "#6B6B6B",
  darkGreyTwo: "#757575",
  darkGreyThree: "#797676",
  red: "#FF4359",
  darkRedOne: "#FF7A7A",
  darkRedTwo: "#FF435A",
  green: "",
  lightGreenOne: "#9CE3E3",
  darkGreenOne: "#70CDCD",
  darkGreenTwo: "#6EB662",
  blue: "#084476",
  lightBlueOne: "#949FFF",
  lightBlueTwo: "#6978FA",
  lightBlueThree: "#00A3FF",
  lightBlueFour: "#5784F1",
  lightBlueFive: "#60D5FA",
  lightBlueSix: "#7BA0FC",
  lightBlueSeven: "#5FB5E5",
  lightBlueEight: "#F4FAFE",
  yellow: "#FACD89",
  orange: "#FABB70",
  darkOrange: "#F87E3A",
  purple: "#A97FED",
  purpleOne: "#8C45FF",
  pink: "#FE9EAF",
  lightPinkOne: "#FFA8A8",
  tabsColor: "#397FBF",
  analyticsTabButtonColor: "#218AC5",
  analyticsTabButtonBorder: "#153B50",
  speedometerTrackStrokeColor: "#293338",
  darkBlue: "#466475",
  lightGreyOne: "#AAAAAA",
  lightGreyTwo: "#DCDADA",
};

export default palette;
