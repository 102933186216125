import { styled } from "@mui/material/styles";
export const RootContainer = styled("div")`
  padding: 10px 20px;
  border: 1px solid #a6a6a6;
  border-radius: 5px;
  margin: 0px 15px 19px 15px;
  cursor: pointer;
  color: #ffffff;
`;
export const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 15.23px;
  font-weight: 700;
`;

export const TitleContainerElements = styled("div")`
  font-size: 13px;
  line-height: 15.23px;
  font-family: "Roboto-Regular";
`;

export const DateTimeText = styled("div")`
  font-size: 10px;
  line-height: 11.72px;
  font-family: "Roboto-Regular";
`;
