import { put } from "redux-saga/effects";
import {
  setDriverInfo,
  setDriversList,
  setDriverAnalytics,
} from "redux/actions/driversActions";
// import fetchAPIServices from "../../../services/fetchAPIServices";
// import { loginApi } from "../../../services/endPoints";
import drivers from "mockdata/drivers";
import driverInfo from "mockdata/driverInfo";
import driverAnalytics from "mockdata/driverAnalytics";

export function* handleDriversList(action) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    //  const response = yield fetchLogin(loginApi, action.payload);
    if (drivers) {
      yield put(setDriversList(drivers));
    } else {
      yield put(setDriversList({}));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleDriverInfo(action) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    //  const response = yield fetchLogin(loginApi, action.payload);
    if (driverInfo) {
      yield put(setDriverInfo(driverInfo));
    } else {
      yield put(setDriverInfo({}));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleDriversAnalyticsInfo(action) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    //  const response = yield fetchLogin(loginApi, action.payload);
    if (driverAnalytics) {
      yield put(setDriverAnalytics(driverAnalytics));
    } else {
      yield put(setDriverAnalytics({}));
    }
  } catch (error) {
    console.log(error);
  }
}
