import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Avatar from "@mui/material/Avatar";
import MoreIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Logo from "../../assets/SmartTransportlogo.svg";
import LogoContent from "../../assets/zyter-content-logo.svg";
import Logout from "../../assets/logoutIcon.svg";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "../../elements/Tabs";
import useStyles from "./styles";

const Header = (props) => {
  const {} = props;
  const {
    logoImg,
    header,
    headerRight,
    avatharName,
    avatharSection,
    avatharIcon,
    logoSection,
    avatharBackground,
    logoutImg,
    logoutSection,
    customMenu,
    MenuItemClass,
    logoutText,
    tabStyle,
    serverStatusStyle,
    serverStatusContainer,
    tabMainClass,
  } = useStyles();
  const [name, setName] = useState({
    firstName: "",
    lastName: "",
    initials: "",
  });
  const [isServerConnected, setIsServerConnected] = useState(true);

  const user = useSelector((state) => state.login.loginData);

  useEffect(() => {
    if (user) {
      let userFirstName = "",
        userLastName = "",
        initial1 = "",
        initial2 = "";
      let fname = user?.firstName;
      let lname = user?.lastName;
      if (fname?.split("").length > 0) {
        userFirstName = fname.split("")[0].toUpperCase() + fname.substring(1);
      }
      if (lname?.split("").length > 0) {
        userLastName = lname.split("")[0].toUpperCase() + lname.substring(1);
      }
      if (userFirstName && userFirstName?.split("").length > 0) {
        initial1 = userFirstName.split("")[0].toUpperCase();
      }
      if (userLastName && userLastName?.split("").length > 0) {
        initial2 = userLastName.split("")[0].toUpperCase();
      }
      setName({
        firstName: userFirstName,
        lastName: userLastName,
        initials: initial1 + initial2,
      });
    }
  }, [user]);
  const [anchorElUser, setAnchorElUser] = useState(null);
  // const [tabIsVisible, settabIsVisible] = useState(tabVisibility);
  const menuOptions = ["Logout"];
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    props.handleLogout();
  };
  const navigate = useNavigate();
  const handleload = () => {
    // navigate("/test")
  };
  //Tabs data
  const handleTabs = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        navigate("/dashboard");
        break;
      case 1:
        navigate("/analytics");
        break;
      default:
        break;
    }
  };
  const tabsList = [
    { name: "Dashboard", val: 0 },
    { name: "Analytics", val: 1 },
  ];
  return (
    <Fragment>
      <Grid container className={header}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <div className={logoSection}>
            <div className={logoImg}>
              <img src={Logo} alt="logo" onClick={handleload} />
              {/* <img src={LogoContent} alt="logo" onClick={handleload} /> */}
            </div>
          </div>
        </Grid>
        <Grid className={headerRight} item xs={12} sm={6} md={6} lg={6} xl={6}>
          <div className={tabStyle}>
            <Tabs
              initialIndex={props.tabIndex ? props.tabIndex : 0}
              tabsList={tabsList}
              handleTabs={handleTabs}
              dashboardNotificationClassName={tabMainClass}
            />
          </div>
          <div>
            <div className={avatharSection}>
              <Avatar className={avatharBackground}>{name.initials}</Avatar>
              <div className={avatharName}>
                <p>{name.firstName + " " + name.lastName}</p>
                <p>Supervisor</p>
              </div>
              <IconButton onClick={handleOpenUserMenu} className={avatharIcon}>
                <MoreIcon sx={{ fontSize: 36 }} />
              </IconButton>
              <Menu
                className={customMenu}
                sx={{ mt: "25px" }}
                anchorOrigin={{
                  background: "transparent",
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {menuOptions.map((menuOptions) => (
                  <MenuItem
                    className={MenuItemClass}
                    key={menuOptions}
                    onClick={handleCloseUserMenu}
                  >
                    <div className={logoutSection}>
                      <img className={logoutImg} src={Logout} alt="logout" />
                      <Typography
                        className={logoutText}
                        onClick={handleLogout}
                        textAlign="center"
                      >
                        {menuOptions}
                      </Typography>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default Header;
