import notifications from "redux/actions/notificationActions";

const initialState = {
  notificationsData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case notifications.SET_NOTIFICATIONS:
      const { notificationsData } = action;
      return { ...state, notificationsData };
    default:
      return state;
  }
};
