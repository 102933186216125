import { styled } from "@mui/material/styles";

export const RootContainer = styled("div")`
  background-color: #f9f9f9;
  display: flex;
  // width: 100vw;
  flex-direction: row;
`;

export const TabButton = styled("div")`
  background-color: ${({ focused }) => (focused ? "#218AC5" : "transparent")};
  color: ${({ focused }) => (focused ? "#FFF" : "#999")};
  transition: all 0.3s ease;
  cursor: pointer;
`;

export const TabButtonTopBar = styled("div")`
  height: 8px;
  width: 100%;
  background-color: ${({ focused }) => (focused ? "#153B50" : "transparent")};
  transition: all 0.3s ease;
`;

export const TabButtonContent = styled("div")`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  @media (max-width: 1537px) {
    padding: 6px 16px;
  }
`;

export const TabButtonText = styled("div")`
  color: ${({ focused }) => (focused ? "#FFF" : "#999")};
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  transition: all 0.3s ease;
  @media (max-width: 1537px) {
    font-size: 15px !important;
    line-height: 23px;
    font-weight: 600;
  }
  @media (max-width: 600px) {
    font-size: 12px !important;
    line-height: 23px;
    font-weight: 600;
  }
`;

export const TabBadge = styled("div")`
  width: 32px;
  height: 32px;
  background-color: ${({ focused }) => (focused ? "#153B50" : "#999")};
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  transition: all 0.3s ease;
`;
