import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@material-ui/lab";
import Icon from "@material-ui/core/Icon";
import {
  routeDestinationIcon,
  routeSourceIcon,
  routeStopIcon,
  currentLocationIcon,
} from "assets/icons";
import useStyles from "./styles";

const Stepper = (props) => {
  const { routeDetails, tripStatus } = props;
  const {
    stepperContainer,
    routeDetailasContainer,
    routesHeading,
    routesSubHeading,
    routeName,
    routeArea,
    routeTimestamp,
    routeNameSpan,
    timeLineConnector,
    timeLineConnectorDashed,
    routeNameSpanNextStop,
  } = useStyles();
  return (
    <div className={routeDetailasContainer}>
      <p className={routesHeading}>Route Details - {routeDetails.route}</p>
      <p className={routesSubHeading}>Occupancy - {routeDetails.occupancy}</p>
      <div className={stepperContainer}>
        <Timeline>
          {routeDetails?.routeStops?.stoppages?.map((route, index) => {
            return (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    {index === 0 ? (
                      <Icon>
                        <img src={routeStopIcon} />
                      </Icon>
                    ) : index ===
                      routeDetails?.routeStops?.stoppages?.length - 1 ? (
                      <Icon>
                        <img
                          width={"20px"}
                          height={"20px"}
                          src={routeDestinationIcon}
                        />
                      </Icon>
                    ) : tripStatus !== "Completed" && index === 1 ? (
                      <Icon>
                        <img
                          width={"20px"}
                          height={"20px"}
                          src={currentLocationIcon}
                        />
                      </Icon>
                    ) : (
                      <Icon>
                        <img
                          width={"20px"}
                          height={"20px"}
                          src={routeSourceIcon}
                        />
                      </Icon>
                    )}
                  </TimelineDot>
                  {index ===
                  routeDetails?.routeStops?.stoppages?.length - 1 ? null : (
                    <TimelineConnector
                      className={
                        tripStatus !== "Completed" && index === 0
                          ? timeLineConnector
                          : tripStatus === "Completed"
                          ? timeLineConnector
                          : timeLineConnectorDashed
                      }
                    />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <p className={routeName}>
                    {route?.name}
                    {tripStatus !== "Completed" && route?.observation && (
                      <span
                        className={
                          index === 2 ? routeNameSpanNextStop : routeNameSpan
                        }
                      >
                        {tripStatus !== "Completed" && route?.observation}
                      </span>
                    )}
                  </p>
                  <p className={routeArea}>{route?.area}</p>
                  <p className={routeTimestamp}>{route?.timeStamp}</p>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </div>
    </div>
  );
};

export default Stepper;
