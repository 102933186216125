import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  dashboardContent: {
    minHeight: "calc(100vh - 108px) !important",
    "& .google-map-code": {
      height: "100%",
    },
  },
  notificationSection: {},
}));

export default useStyles;
