import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import DashboardMap from "../DashboardMap";
import DashboardListContainer from "../DashboardListContainer";
import { getNotifications } from "redux/actions/notificationActions";
import { getBusesList } from "redux/actions/busesActions";
import { getDriversList } from "redux/actions/driversActions";
import { getRoutesList } from "redux/actions/routesActions";
import { formattedCardListData } from "../../utils/utils";
import useStyles from "./styles";

//Footer Component

const DashboardContainer = (props) => {
  const { dashboardContent, notificationSection } = useStyles();

  const dispatch = useDispatch();

  const notificationsListData = useSelector(
    (state) => state.notifications.notificationsData
  );

  const busesListData = useSelector((state) => state.buses.busesList);

  const driversListData = useSelector((state) => state.drivers.driversList);

  const routesListData = useSelector((state) => state.routes.routesList);

  const notifcationChipButtons = [
    { label: "Events", count: "1", focused: false, color: "green" },
    { label: "Incidents", count: "1", focused: true, color: "red" },
    { label: "Opr.Alerts", count: "1", focused: false, color: "orange" },
  ];

  const routeChipButtons = [
    { label: "Active Routes", count: "3", focused: true, color: "blue" },
    { label: "Completed Routes", count: "3", focused: false, color: "orange" },
  ];
  const [chipButtons, setChipButtons] = useState(notifcationChipButtons);

  const [coOrdinates, setCoOrdinates] = useState();
  const [dataList, setDataList] = useState(notificationsListData);
  const [cardList, setCardList] = useState({});
  const [chipButtonTabIndex, setChipButtonTabIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [type, setType] = useState("");
  const [expandListFromMap, setExpandListFromMap] = useState(false);
  const [indexFromMap, setIndexFromMap] = useState(null);
  const [datafromList, setDatafromList] = useState(null);

  useEffect(() => {
    dispatch(getNotifications());
    dispatch(getBusesList());
    dispatch(getDriversList());
    dispatch(getRoutesList());
  }, []);

  useEffect(() => {
    if (tabIndex === 0) {
      setDataList(notificationsListData);
    }
  }, [notificationsListData, dataList]);

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        setDataList(notificationsListData);
        setChipButtons(getFormattedChipButtonsData(notifcationChipButtons, 0));
        break;
      case 1:
        setDataList(busesListData);
        break;
      case 2:
        setDataList(driversListData);
        break;
      case 3:
        setDataList(routesListData);
        setChipButtons(getFormattedChipButtonsData(routeChipButtons, 0));
        break;
      default:
        break;
    }
  }, [tabIndex]);

  useEffect(() => {
    if (tabIndex === 0) {
      setCardList(formattedCardListData(tabIndex, dataList, "events"));
    } else if (tabIndex === 3) {
      setCardList(formattedCardListData(tabIndex, dataList, "activeRoutes"));
    } else {
      setCardList(formattedCardListData(tabIndex, dataList));
    }
  }, [dataList]);

  const handleClickDrawRoute = (data) => {
    setCoOrdinates(data);
    setType("");
  };

  const handleTabIndex = (index) => {
    setTabIndex(index);
  };

  const getFormattedChipButtonsData = (list, chipBtnIndex) => {
    for (const [index] of list.entries()) {
      list[index].tabIndex = tabIndex;
      if (chipBtnIndex === index) list[index].focused = true;
      else list[index].focused = false;
    }
    return list;
  };

  const handleChipButton = (data, index) => {
    setCoOrdinates("");
    if (data) {
      if (data.tabIndex === 0) {
        setChipButtons(
          getFormattedChipButtonsData(notifcationChipButtons, index)
        );
        switch (data.label) {
          case "Events":
            setCardList(formattedCardListData(tabIndex, dataList, "events"));
            setChipButtonTabIndex(0);
            break;
          case "Incidents":
            setCardList(formattedCardListData(tabIndex, dataList, "incidents"));
            setChipButtonTabIndex(1);
            break;
          case "Opr.Alerts":
            setCardList(formattedCardListData(tabIndex, dataList, "oprAlerts"));
            setChipButtonTabIndex(2);
            break;
          default:
            break;
        }
      } else if (data.tabIndex === 3) {
        setChipButtons(getFormattedChipButtonsData(routeChipButtons, index));
        switch (data.label) {
          case "Active Routes":
            setCardList(
              formattedCardListData(tabIndex, dataList, "activeRoutes")
            );
            setType("");
            break;
          case "Completed Routes":
            setCardList(
              formattedCardListData(tabIndex, dataList, "completedRoutes")
            );
            setType("completedRoutes");
            break;
          default:
            break;
        }
      }
    }
  };

  const handleExpandingList = (index) => {
    setIndexFromMap(index);
    setExpandListFromMap(!expandListFromMap);
  };

  const handleClickFromListToMap = (data) => {
    if (data !== null) {
      setDatafromList(data);
    } else {
      setDatafromList(null);
    }
  };

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className={dashboardContent}>
          <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
            <DashboardMap
              coOrdinates={coOrdinates}
              handleClickDrawRoute={handleClickDrawRoute}
              cardList={cardList}
              tabIndex={tabIndex}
              type={type}
              handleExpandingList={handleExpandingList}
              zoom={10}
              datafromList={datafromList}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={4}
            xl={3}
            className={notificationSection}
          >
            <DashboardListContainer
              handleClickDrawRoute={handleClickDrawRoute}
              handleClickFromListToMap={handleClickFromListToMap}
              cardList={cardList}
              handleTabIndex={handleTabIndex}
              handleChipButton={handleChipButton}
              chipButtons={chipButtons}
              coOrdinates={coOrdinates}
              expandListFromMap={expandListFromMap}
              indexFromMap={indexFromMap}
              chipButtonTabIndex={chipButtonTabIndex}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default DashboardContainer;
