import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Logo2 from "../../assets/zyter-logo.svg";
import useStyles from "./styles";
//Footer Component

const Footer = () => {
  const { footer } = useStyles();

  return (
    <Fragment>
      <Grid container className={footer}>
        <p>
          {/* Powered By <img src={Logo2} alt="Zyter" /> | © 2022 | All Rights */}
          © 2022.All Rights Reserved
        </p>
      </Grid>
    </Fragment>
  );
};

export default Footer;
