import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoutes from "../routes/protectedRoutes";
import Login from "../pages/Login";
import DashBoard from "../pages/DashBoard";
import Analytics from "../pages/Analytics";

const SB_Routes = () => {
  return (
    <Routes>
      {/** Protected Routes */}
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<Navigate replace to="login" />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/analytics" element={<Analytics />} />
      </Route>

      {/** Public Routes */}
      <Route path="/login" element={<Login />} />

      {/** Permission denied route */}
      <Route path="/denied" element={<div>No permission</div>} />
    </Routes>
  );
};

export default SB_Routes;
