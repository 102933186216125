import buses from "redux/actions/busesActions";

const initialState = {
  busesList: [],
  busInfo: {},
  busAnalyticsInfo: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case buses.SET_BUSES_LIST:
      const { busesList } = action;
      return { ...state, busesList };
    case buses.SET_BUS_INFO:
      const { busInfo } = action;
      return { ...state, busInfo };
    case buses.SET_BUS_ANALYTICS:
      const { busAnalyticsInfo } = action;
      return { ...state, busAnalyticsInfo };
    default:
      return state;
  }
};
