import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { OutlinedInput } from "@mui/material";
import Button from "@mui/material/Button";
import BusLogo from "../../assets/SmartTransportLoginlogo.svg";
import ZyterLogo from "../../assets/zyter-logo.svg";
import ZyterLogoContent from "../../assets/zyter-logo-content.svg";
import { getUserLogin } from "../../redux/actions/loginActions";
import { useFormik } from "formik";
import * as Yup from "yup";
import useStyles from "./styles";

const Login = () => {
  const {
    loginBg,
    loginFieldBox,
    logoSection,
    logoImage,
    loginHeading,
    customTextField,
    loginButton,
    poweredBySection,
    poweredBy,
    formikErrorClass,
  } = useStyles();

  const user = useSelector((state) => state.login.loginData);
  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.userName) {
      localStorage.setItem("user", JSON.stringify({ role: "ADMIN" }));
      navigate("/dashboard");
    }
  }, [user]);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      userid: "",
      password: "",
    },
    validationSchema: Yup.object({
      userid: Yup.string()
        .min(2, "Mininum 2 characters")
        .required("Please Enter username"),
      password: Yup.string()
        .min(2, "Mininum 2 characters")
        .max(15, "Maximum 15 characters")
        .required("Please Enter password"),
    }),
    onSubmit: (values) => {
      if (values.userid && values.password) {
        let payload = {
          deviceToken: "abcde",
          userName: values.userid,
          passWord: values.password,
        };

        dispatch(getUserLogin(payload));
      } else {
        alert("Incorrect User Credentials");
      }
    },
  });

  return (
    <>
      <Grid container className={loginBg}>
        <Grid item xs={12}>
          <Box className={loginFieldBox}>
            <form onSubmit={formik.handleSubmit}>
              <div className={logoSection}>
                <img className={logoImage} src={BusLogo} />
                {/* <img src={ZyterLogoContent} /> */}
              </div>
              <div>
                <Typography variant="h5" className={loginHeading}>
                  Login to your account
                </Typography>
              </div>
              <div className={customTextField}>
                <OutlinedInput
                  fullWidth
                  placeholder="Username"
                  value={formik.values.userid}
                  onChange={formik.handleChange}
                  type="text"
                  name="userid"
                />
                {formik.errors.userid && formik.touched.userid && (
                  <p className={formikErrorClass}>{formik.errors.userid}</p>
                )}
              </div>
              <div className={customTextField}>
                <OutlinedInput
                  fullWidth
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  placeholder="******"
                />
                {formik.errors.password && formik.touched.password && (
                  <p className={formikErrorClass}>{formik.errors.password}</p>
                )}
              </div>
              <div className={loginButton}>
                <Button variant="outlined" fullWidth type="submit">
                  Login
                </Button>
              </div>
              {/* <div className={poweredBySection}>
                <Typography variant="h5" className={poweredBy}>
                  Powered by
                </Typography>
                <img src={ZyterLogo} />
              </div> */}
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Login;
