import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(
  {
    loginButton: {
      maxWidth: "373px !important",
      borderRadius: "24px !important",
      marginTop: "30px !important",
      backgroundColor: "#2A859B !important",
      height: "48px",
      color: "white !important",
      fontSize: "16px !important",
      fontWeight: "600 !important",
      textTransform: "none !important",
    },
    CustomButton: {
      maxWidth: "373px !important",
      width: "114px",
      borderRadius: "15.27px !important",
      backgroundColor: "#123D64 !important",
      height: "30.55px",
      color: "white !important",
      fontSize: "9.5px !important",
      fontWeight: "700 !important",
    },

    CustomButtonDisable: {
      maxWidth: "373px !important",
      width: "114px",
      borderRadius: "15.27px !important",
      backgroundColor: "#A0B1C1 !important",
      height: "30.55px",
      color: "white !important",
      fontSize: "9.5px !important",
      fontWeight: "700 !important",
      pointerEvents: "none",
    },
  },
  { index: 1 }
);
export default useStyles;
