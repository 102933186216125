import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useStyles from "./styles";
const INF_Tabs = (props) => {
  const { initialIndex, tabsList, handleTabs, dashboardNotificationClassName } =
    props;
  const [value, setValue] = useState(initialIndex);
  const handleChange = (event, val) => {
    setValue(val);
    handleTabs(val);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={dashboardNotificationClassName}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
          >
            {tabsList.map((item, index) => (
              <Tab key={index} value={index} label={item.name} />
            ))}
          </Tabs>
        </div>
      </Grid>
    </Grid>
  );
};
export default INF_Tabs;
