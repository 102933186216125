const buses = {
  buses: [
    {
      busNo: "1",
      route: "Route#1",
      busIconType: "green",
      speed: "10 mph",
      fuelConsumed: "20 Gal",
      distanceCovered: "20 Miles",
      stops: "02/05",
      driver: "John Doe",
      timeStamp: "08-05-2022 | 10.00 AM",
      source: {
        area: "Florrencia, Costa Rica",
        coOrdinates: {
          lat: 10.364558407178833,
          lng: -84.47743152619893,
        },
      },
      destination: {
        area: "Jicarito, Costa Rica",
        coOrdinates: {
          lat: 10.605794407884536,
          lng: -84.704989450137,
        },
      },
      currentLocation: {
        area: "Florrencia, Costa Rica",
        coOrdinates: {
          lat: 10.364558407178833,
          lng: -84.47743152619893,
        },
      },
      nextStop: {
        area: "Muelle San Carlos",
        coOrdinates: {
          lat: 10.473848132759747,
          lng: -84.46963269522386,
        },
      },
    },
    {
      busNo: "2",
      route: "Route#2",
      busIconType: "green",
      speed: "10 mph",
      fuelConsumed: "20 Gal",
      distanceCovered: "20 Miles",
      stops: "02/05",
      driver: "John Doe",
      timeStamp: "08-05-2022 | 10.00 AM",
      source: {
        area: "San Jorge",
        coOrdinates: {
          lat: 10.717084314358102,
          lng: -84.67959872583144,
        },
      },
      destination: {
        area: "Boco Arenal",
        coOrdinates: {
          lat: 10.535121220817171,
          lng: -84.48273248094928,
        },
      },
      currentLocation: {
        area: "San Jorge",
        coOrdinates: {
          lat: 10.717084314358102,
          lng: -84.67959872583144,
        },
      },
      nextStop: {
        area: "Santa Rosa de Pocosol",
        coOrdinates: {
          lat: 10.628080138363762,
          lng: -84.52937188836226,
        },
      },
    },
    {
      busNo: "3",
      route: "Route#3",
      busIconType: "green",
      speed: "10 mph",
      fuelConsumed: "20 Gal",
      distanceCovered: "20 Miles",
      stops: "02/05",
      driver: "John Doe",
      timeStamp: "08-05-2022 | 10.00 AM",
      source: {
        area: "San Rafael de Guatuso",
        coOrdinates: {
          lat: 10.666413876081828,
          lng: -84.8112894535626,
        },
      },
      destination: {
        area: "Pocosol",
        coOrdinates: {
          lat: 10.381380505208913,
          lng: -84.61877218354736,
        },
      },
      currentLocation: {
        area: "San Rafael de Guatuso",
        coOrdinates: {
          lat: 10.666413876081828,
          lng: -84.8112894535626,
        },
      },
      nextStop: {
        area: "La Fortuna",
        coOrdinates: {
          lat: 10.470200326746983,
          lng: -84.64022251241668,
        },
      },
    },
  ],
};

export default buses;
