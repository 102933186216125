import React, { useState, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import GoogleMap from "../../elements/GoogleMap";
import appData from "../../constants/appData";
import InfoDialog from "components/InfoDialog";

//Footer Component

const DashboardMap = (props) => {
  const {
    coOrdinates,
    cardList,
    tabIndex,
    type,
    handleExpandingList,
    handleClickDrawRoute,
    zoom,
    datafromList,
  } = props;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: appData.apiKey,
    libraries: appData.libraries,
  });

  const [viewAllIsOpen, setViewAllIsOpen] = useState(false);
  const [dialogBoxTabIndexData, setDialogBoxTabIndexData] = useState(null);
  const [infoVideoTitleListData, setInfoVideoTitleListData] = useState();

  const handleViewDetails = (tabIndexData, data) => {
    setInfoVideoTitleListData(data);
    setViewAllIsOpen(!viewAllIsOpen);
    setDialogBoxTabIndexData(tabIndexData);
  };

  return (
    <>
      <div>
        {!isLoaded ? (
          <div>Loading...</div>
        ) : (
          <div>
            <GoogleMap
              coOrdinates={coOrdinates}
              cardList={cardList}
              tabIndex={tabIndex}
              handleViewDetails={handleViewDetails}
              handleClickDrawRoute={handleClickDrawRoute}
              type={type}
              handleExpandingList={handleExpandingList}
              zoom={zoom}
              datafromList={datafromList}
            />
          </div>
        )}
      </div>
      {viewAllIsOpen && (
        <InfoDialog
          handleViewAll={handleViewDetails}
          dialogBoxTabIndexData={dialogBoxTabIndexData}
          tabIndex={dialogBoxTabIndexData}
          infoVideoTitleListData={infoVideoTitleListData}
          coOrdinates={coOrdinates}
        />
      )}
    </>
  );
};

export default DashboardMap;
