import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import busesReducer from "./busesReducer";
import driversReducer from "./driversReducer";
import routesReducer from "./routesReducer";
import notificationsReducer from "./notificationsReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  buses: busesReducer,
  drivers: driversReducer,
  routes: routesReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
