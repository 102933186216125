import { styled } from "@mui/material/styles";

export const RootContainer = styled("div")`
  padding: 10px;
  border-radius: 5px;
  background: #ffffff;
  width: unset;
`;

export const RootSubContainer = styled("div")`
  padding: 10px;
  border-radius: 5px;
  background: #ffffff;
  width: unset;
`;
export const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
`;
export const DefaultBody = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
`;
export const ExpandedBody = styled("div")`
  margin-left: -8px;
`;

export const DetailItems = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const BodyText = styled("div")`
  color: ${({ expanded, source }) =>
    expanded ? (source ? "#118ed4" : "#2E8F1E") : "#646464"};
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 600;
  font-weight: ${({ expanded }) => (expanded ? 600 : 400)};
`;

export const BodyTextLine2 = styled("div")`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #646464;
`;

export const BodyTextLine3 = styled("div")`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #f87e3a;
`;

export const DateTimeText = styled("div")`
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
`;
export const FooterText = styled("div")`
  display: flex;
  align-items: center;
`;

export const DetailItems2 = styled("div")`
  font-size: 10px;
  line-height: 13.62px;
  font-weight: 600;
`;
export const DetailItemsValue = styled("div")`
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 700;
  color: ${({ currentLocation }) => (currentLocation ? "#2E8F1E" : "#456EFF")};
`;

export const DetailItems1 = styled("div")`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const DetailsContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailsContainer1 = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const IconText = styled("div")`
  font-size: 10px;
  line-height: 13.62px;
  font-weight: 600;
`;

export const IconTextValue = styled("div")`
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 700;
  color: #123d64;
`;

export const DetailItemTitle = styled("div")`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #123d64;
`;

export const DetailItemContent = styled(DetailItemTitle)`
  color: #646464;
`;

export const VideoIcon = styled("span")`
  cursor: not-allowed;
`;
