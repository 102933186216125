import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Tabs from "../../elements/Tabs";
import { Typography } from "@mui/material";
import searchImg from "../../assets/search-icon.svg";
import documentImg from "../../assets/document-icon.svg";
import DashboardList from "../DashboardList";
import SearchBox from "elements/SearchBox";
import CloseIcon from "../../assets/Close.svg";
import useStyles from "./styles";

const DashboardListContainer = (props) => {
  const {
    cardList,
    chipButtons,
    coOrdinates,
    expandListFromMap,
    indexFromMap,
    chipButtonTabIndex,
  } = props;

  const { tabSection, searchIcon, customNotificationTabs, searchClass } =
    useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabs = (index) => {
    props.handleClickDrawRoute("");
    setTabIndex(index);
  };

  useEffect(() => {
    props.handleTabIndex(tabIndex);
    setSearchIsOpen(false);
  }, [tabIndex]);

  const tabsList = [
    { name: "NOTIFICATION", val: 0 },
    { name: "BUSES", val: 1 },
    { name: "DRIVERS", val: 2 },
    { name: "ROUTES", val: 3 },
  ];

  const handleClickDrawRoute = (data) => {
    props.handleClickDrawRoute(data);
  };

  const handleClickFromListToMap = (data) => {
    props.handleClickFromListToMap(data);
  };

  const handleChipButton = (data, index) => {
    props.handleChipButton(data, index);
  };

  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const handleSearchIsOpen = () => {
    setSearchIsOpen(!searchIsOpen);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={tabSection}>
            <div>
              <Tabs
                initialIndex={0}
                tabsList={tabsList}
                handleTabs={handleTabs}
                dashboardNotificationClassName={customNotificationTabs}
              />
            </div>
            <div className={searchIcon}>
              <img
                src={searchIsOpen ? CloseIcon : searchImg}
                alt="search icon"
                onClick={handleSearchIsOpen}
              />
              <img src={documentImg} alt="search icon" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            {searchIsOpen && (
              <SearchBox
                searchInput={searchClass}
                placeHolder={"Search"}
                // handleSearch={handleSearch}
                borderRadius={2}
                borderColor={"1px solid #DCDADA"}
                searchIsOpen={searchIsOpen}
              />
            )}
          </div>
          <div>
            <DashboardList
              tabIndex={tabIndex}
              handleClickDrawRoute={handleClickDrawRoute}
              cardList={cardList}
              handleChipButton={handleChipButton}
              chipButtons={chipButtons}
              coOrdinates={coOrdinates}
              expandListFromMap={expandListFromMap}
              indexFromMap={indexFromMap}
              chipButtonTabIndex={chipButtonTabIndex}
              handleClickFromListToMap={handleClickFromListToMap}
              searchIsOpen={searchIsOpen}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardListContainer;
