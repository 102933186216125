const busAnalytics = {
  busAnalytics: [
    {
      busNo: "1",
      busLicensePlateNo: "PB2586",
      generalStats: {
        day: {
          revenue: "$150K",
          avgFuelConsumed: "10Gal",
          avgRunningHours: "50Hrs",
          avgCO2Emission: "1.00T",
          avgPassengerCarried: "500",
        },
        weekly: {
          revenue: "$950K",
          avgFuelConsumed: "90Gal",
          avgRunningHours: "90Hrs",
          avgCO2Emission: "9.00T",
          avgPassengerCarried: "900",
        },
        monthly: {
          revenue: "$850K",
          avgFuelConsumed: "50Gal",
          avgRunningHours: "500Hrs",
          avgCO2Emission: "18.00T",
          avgPassengerCarried: "700",
        },
        yearly: {
          revenue: "$550K",
          avgFuelConsumed: "40Gal",
          avgRunningHours: "80Hrs",
          avgCO2Emission: "2.00T",
          avgPassengerCarried: "800",
        },
      },
      incomeStats: {
        day: {
          income: "$100K",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        weekly: {
          income: "$800K",
          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 347.0,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 447.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 23,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 65,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 75,
                },
              ],
            },
          ],
        },
        monthly: {
          income: "$200K",

          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        yearly: {
          income: "$900K",

          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
      },
      distanceTravelledStats: {
        day: {
          totalDistanceTravelled: "1800",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 1,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 4,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 8,
            },
            {
              node: "2022-10-11T14:00:00",
              count: 3,
            },
            {
              node: "2022-10-11T15:00:00",
              count: 10,
            },
          ],
        },
        weekly: {
          totalDistanceTravelled: "1900",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          totalDistanceTravelled: "1900",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          totalDistanceTravelled: "2000",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      fuelConsumedStats: {
        day: {
          fuelConsumed: "5 Gal",
          observation: "down",
          percentage: "0.5%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 4.0,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 8.0,
            },
            {
              node: "2022-10-11T14:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T15:00:00",
              count: 4.0,
            },
          ],
        },
        weekly: {
          fuelConsumed: "100 Gal",
          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          fuelConsumed: "200 Gal",
          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          fuelConsumed: "250 Gal",
          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      travellingHoursStats: {
        day: {
          runningHours: "20Hrs",

          runningHrsObservation: "up",
          runningHrsPercentage: "1.0%",
          idleHours: "2Hrs",

          idleHrsObservation: "down",
          idleHrsPercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 2.0,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 15.0,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 5.0,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 15.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 1.0,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 6.0,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 9.0,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 2.0,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 7.0,
                },
              ],
            },
          ],
        },
        weekly: {
          runningHours: "30Hrs",

          runningHrsObservation: "up",
          runningHrsPercentage: "1.0%",
          idleHours: "3Hrs",

          idleHrsObservation: "down",
          idleHrsPercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        monthly: {
          runningHours: "40Hrs",

          runningHrsObservation: "up",
          runningHrsPercentage: "1.0%",
          idleHours: "4Hrs",

          idleHrsObservation: "down",
          idleHrsPercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        yearly: {
          runningHours: "50Hrs",

          runningHrsObservation: "up",
          runningHrsPercentage: "1.0%",
          idleHours: "5Hrs",

          idleHrsObservation: "down",
          idleHrsPercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
      },
      co2EmissionStats: {
        day: {
          emission: "2.00T",

          observation: "down",
          percentage: "0.5%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 6.0,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T14:00:00",
              count: 8.0,
            },
            {
              node: "2022-10-11T15:00:00",
              count: 7.0,
            },
          ],
        },
        weekly: {
          emission: "3.00T",

          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          emission: "4.00T",

          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          emission: "5.00T",

          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      expensesStats: {
        observation: "up",
        percentage: "",
        day: {
          fuel: "",
          maintenance: "",
          others: "",
          observation: "down",
          percentage: "3.0%",
        },
        weekly: {
          fuel: "",
          maintenance: "",
          others: "",
          observation: "down",
          percentage: "3.0%",
        },
        monthly: {
          fuel: "",
          maintenance: "",
          others: "",
          observation: "down",
          percentage: "3.0%",
        },
        yearly: {
          fuel: "",
          maintenance: "",
          others: "",
          observation: "down",
          percentage: "3.0%",
        },
      },
    },
    {
      busNo: "2",
      busLicensePlateNo: "PB2586",
      generalStats: {
        day: {
          revenue: "$150K",
          avgFuelConsumed: "10Gal",
          avgRunningHours: "50Hrs",
          avgCO2Emission: "1.00T",
          avgPassengerCarried: "500",
        },
        weekly: {
          revenue: "$950K",
          avgFuelConsumed: "90Gal",
          avgRunningHours: "90Hrs",
          avgCO2Emission: "9.00T",
          avgPassengerCarried: "900",
        },
        monthly: {
          revenue: "$850K",
          avgFuelConsumed: "50Gal",
          avgRunningHours: "500Hrs",
          avgCO2Emission: "18.00T",
          avgPassengerCarried: "700",
        },
        yearly: {
          revenue: "$550K",
          avgFuelConsumed: "40Gal",
          avgRunningHours: "80Hrs",
          avgCO2Emission: "2.00T",
          avgPassengerCarried: "800",
        },
      },
      incomeStats: {
        day: {
          income: "$100K",

          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        weekly: {
          income: "$200K",

          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        monthly: {
          income: "$300K",

          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        yearly: {
          income: "$500K",

          observation: "up",
          percentage: "0.5%",
          analyticsData: [
            {
              metricName: "Current Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Last Year",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
      },
      distanceTravelledStats: {
        day: {
          totalDistanceTravelled: "1800",
          observation: "up",
          percentage: "3.0%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          totalDistanceTravelled: "1800",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          totalDistanceTravelled: "1800",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          totalDistanceTravelled: "1800",
          observation: "up",
          percentage: "3.0%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      fuelConsumedStats: {
        day: {
          fuelConsumed: "5 Gal",
          observation: "down",
          percentage: "0.5%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          fuelConsumed: "5 Gal",
          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          fuelConsumed: "5 Gal",
          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          fuelConsumed: "5 Gal",
          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      travellingHoursStats: {
        day: {
          runningHours: "20Hrs",

          runningHrsObservation: "up",
          runningHrsPercentage: "1.0%",
          idleHours: "2Hrs",

          idleHrsObservation: "down",
          idleHrsPercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        weekly: {
          runningHours: "30Hrs",

          runningHrsObservation: "up",
          runningHrsPercentage: "1.0%",
          idleHours: "2Hrs",

          idleHrsObservation: "down",
          idleHrsPercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        monthly: {
          runningHours: "40Hrs",

          runningHrsObservation: "up",
          runningHrsPercentage: "1.0%",
          idleHours: "2Hrs",

          idleHrsObservation: "down",
          idleHrsPercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
        yearly: {
          runningHours: "50Hrs",

          runningHrsObservation: "up",
          runningHrsPercentage: "1.0%",
          idleHours: "2Hrs",

          idleHrsObservation: "down",
          idleHrsPercentage: "0.5%",
          analyticsData: [
            {
              metricName: "Running Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 0.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 0.0,
                },
              ],
            },
            {
              metricName: "Idle Hours",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
              ],
            },
          ],
        },
      },
      co2EmissionStats: {
        day: {
          emission: "2.00T",

          observation: "down",
          percentage: "0.5%",

          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        weekly: {
          emission: "3.00T",

          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        monthly: {
          emission: "4.00T",

          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
        yearly: {
          emission: "5.00T",

          observation: "down",
          percentage: "0.5%",
          analyticsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 0.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.0,
            },
          ],
        },
      },
      expensesStats: {
        day: {
          fuel: "",
          maintenance: "",
          others: "",
          observation: "up",
          percentage: "",
        },
        weekly: {
          fuel: "",
          maintenance: "",
          others: "",
          observation: "up",
          percentage: "",
        },
        monthly: {
          fuel: "",
          maintenance: "",
          others: "",
          observation: "up",
          percentage: "",
        },
        yearly: {
          fuel: "",
          maintenance: "",
          others: "",
          observation: "up",
          percentage: "",
        },
      },
    },
  ],
  overAllAnalytics: {
    generalStats: {
      day: {
        revenue: "$90K",
        avgFuelConsumed: "200 Gal",
        avgRunningHours: "10Hrs",
        avgCO2Emission: "7.00T",
        avgPassengerCarried: "500",
      },
      weekly: {
        revenue: "$950K",
        avgFuelConsumed: "90Gal",
        avgRunningHours: "90Hrs",
        avgCO2Emission: "9.00T",
        avgPassengerCarried: "900",
      },
      monthly: {
        revenue: "$850K",
        avgFuelConsumed: "50Gal",
        avgRunningHours: "500Hrs",
        avgCO2Emission: "18.00T",
        avgPassengerCarried: "700",
      },
      yearly: {
        revenue: "$550K",
        avgFuelConsumed: "40Gal",
        avgRunningHours: "80Hrs",
        avgCO2Emission: "2.00T",
        avgPassengerCarried: "800",
      },
    },
    incomeStats: {
      day: {
        avgIncome: "$90K",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            metricName: "Current Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 217.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
              {
                node: "2022-10-11T13:00:00",
                count: 257.0,
              },
              {
                node: "2022-10-11T14:00:00",
                count: 247.0,
              },
              {
                node: "2022-10-11T15:00:00",
                count: 287.0,
              },
              {
                node: "2022-10-11T16:00:00",
                count: 367.0,
              },
              {
                node: "2022-10-11T17:00:00",
                count: 327.0,
              },
              {
                node: "2022-10-11T18:00:00",
                count: 357.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 387.0,
              },
              {
                node: "2022-10-11T21:00:00",
                count: 347.0,
              },
              {
                node: "2022-10-11T22:00:00",
                count: 377.0,
              },
            ],
          },
          {
            metricName: "Last Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 117.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 147.0,
              },
              {
                node: "2022-10-11T13:00:00",
                count: 157.0,
              },
              {
                node: "2022-10-11T14:00:00",
                count: 147.0,
              },
              {
                node: "2022-10-11T15:00:00",
                count: 187.0,
              },
              {
                node: "2022-10-11T16:00:00",
                count: 267.0,
              },
              {
                node: "2022-10-11T17:00:00",
                count: 227.0,
              },
              {
                node: "2022-10-11T18:00:00",
                count: 257.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 287.0,
              },
              {
                node: "2022-10-11T21:00:00",
                count: 247.0,
              },
              {
                node: "2022-10-11T22:00:00",
                count: 277.0,
              },
            ],
          },
        ],
      },
      weekly: {
        avgIncome: "$950K",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            metricName: "Current Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 217.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
              {
                node: "2022-10-11T13:00:00",
                count: 257.0,
              },
              {
                node: "2022-10-11T14:00:00",
                count: 247.0,
              },
              {
                node: "2022-10-11T15:00:00",
                count: 287.0,
              },
              {
                node: "2022-10-11T16:00:00",
                count: 367.0,
              },
              {
                node: "2022-10-11T17:00:00",
                count: 327.0,
              },
              {
                node: "2022-10-11T18:00:00",
                count: 357.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 387.0,
              },
              {
                node: "2022-10-11T21:00:00",
                count: 347.0,
              },
              {
                node: "2022-10-11T22:00:00",
                count: 377.0,
              },
            ],
          },
          {
            metricName: "Last Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 117.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 147.0,
              },
              {
                node: "2022-10-11T13:00:00",
                count: 157.0,
              },
              {
                node: "2022-10-11T14:00:00",
                count: 147.0,
              },
              {
                node: "2022-10-11T15:00:00",
                count: 187.0,
              },
              {
                node: "2022-10-11T16:00:00",
                count: 267.0,
              },
              {
                node: "2022-10-11T17:00:00",
                count: 227.0,
              },
              {
                node: "2022-10-11T18:00:00",
                count: 257.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 287.0,
              },
              {
                node: "2022-10-11T21:00:00",
                count: 247.0,
              },
              {
                node: "2022-10-11T22:00:00",
                count: 277.0,
              },
            ],
          },
        ],
      },
      monthly: {
        avgIncome: "$90K",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            metricName: "Current Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 0.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 0.0,
              },
            ],
          },
          {
            metricName: "Last Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
            ],
          },
        ],
      },
      yearly: {
        avgIncome: "$90K",
        observation: "up",
        percentage: "0.5%",
        analyticsData: [
          {
            metricName: "Current Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 0.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 0.0,
              },
            ],
          },
          {
            metricName: "Last Year",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
            ],
          },
        ],
      },
    },
    distanceTravelledStats: {
      day: {
        avgDistanceTravelled: "1524",
        observation: "down",
        percentage: "3.0%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 1,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 4,
          },
          {
            node: "2022-10-11T13:00:00",
            count: 8,
          },
          {
            node: "2022-10-11T14:00:00",
            count: 3,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 10,
          },
        ],
      },
      weekly: {
        avgDistanceTravelled: "1524",
        observation: "up",
        percentage: "3.0%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      monthly: {
        avgDistanceTravelled: "1524",
        observation: "up",
        percentage: "3.0%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      yearly: {
        avgDistanceTravelled: "1524",
        observation: "up",
        percentage: "3.0%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
    },
    fuelConsumedStats: {
      day: {
        avgFuelConsumed: "90 Gal",
        observation: "down",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 1.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 4.0,
          },
          {
            node: "2022-10-11T13:00:00",
            count: 8.0,
          },
          {
            node: "2022-10-11T14:00:00",
            count: 2.0,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 4.0,
          },
        ],
      },
      weekly: {
        avgFuelConsumed: "200 Gal",
        observation: "down",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      monthly: {
        avgFuelConsumed: "200 Gal",
        observation: "down",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      yearly: {
        avgFuelConsumed: "200 Gal",
        observation: "down",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
    },
    travellingHoursStats: {
      day: {
        avgRunningHours: "90Hrs",
        runningHrsObservation: "up",
        runningHrsPercentage: "1.0%",

        avgIdleHours: "2Hrs",
        idleHrsObservation: "down",
        idleHrsPercentage: "0.5%",
        analyticsData: [
          {
            metricName: "Running Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 1.0,
              },
              {
                node: "2022-10-11T14:00:00",
                count: 6.0,
              },
              {
                node: "2022-10-11T16:00:00",
                count: 9.0,
              },
              {
                node: "2022-10-11T18:00:00",
                count: 2.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 7.0,
              },
            ],
          },
          {
            metricName: "Idle Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 2.0,
              },
              {
                node: "2022-10-11T14:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T16:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T18:00:00",
                count: 5.0,
              },
              {
                node: "2022-10-11T19:00:00",
                count: 15.0,
              },
            ],
          },
        ],
      },
      weekly: {
        avgRunningHours: "15Hrs",
        runningHrsObservation: "up",
        runningHrsPercentage: "1.0%",

        avgIdleHours: "2Hrs",
        idleHrsObservation: "down",
        idleHrsPercentage: "0.5%",
        analyticsData: [
          {
            metricName: "Running Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 0.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 0.0,
              },
            ],
          },
          {
            metricName: "Idle Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
            ],
          },
        ],
      },
      monthly: {
        avgRunningHours: "10Hrs",
        runningHrsObservation: "up",
        runningHrsPercentage: "1.0%",

        avgIdleHours: "2Hrs",
        idleHrsObservation: "down",
        idleHrsPercentage: "0.5%",
        analyticsData: [
          {
            metricName: "Running Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 0.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 0.0,
              },
            ],
          },
          {
            metricName: "Idle Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
            ],
          },
        ],
      },
      yearly: {
        avgRunningHours: "10Hrs",
        runningHrsObservation: "up",
        runningHrsPercentage: "1.0%",

        avgIdleHours: "2Hrs",
        idleHrsObservation: "down",
        idleHrsPercentage: "0.5%",
        analyticsData: [
          {
            metricName: "Running Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 0.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 0.0,
              },
            ],
          },
          {
            metricName: "Idle Hours",
            analytics: [
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 247.0,
              },
            ],
          },
        ],
      },
    },
    co2EmissionStats: {
      day: {
        avgEmission: "9.00T",
        observation: "down",
        percentage: "0.5%",

        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 2.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 6.0,
          },
          {
            node: "2022-10-11T13:00:00",
            count: 1.0,
          },
          {
            node: "2022-10-11T14:00:00",
            count: 8.0,
          },
          {
            node: "2022-10-11T15:00:00",
            count: 7.0,
          },
        ],
      },
      weekly: {
        avgEmission: "7.00T",
        observation: "down",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      monthly: {
        avgEmission: "7.00T",
        observation: "down",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
      yearly: {
        avgEmission: "7.00T",
        observation: "down",
        percentage: "0.5%",
        analyticsData: [
          {
            node: "2022-10-11T11:00:00",
            count: 0.0,
          },
          {
            node: "2022-10-11T12:00:00",
            count: 0.0,
          },
        ],
      },
    },
    expensesStats: {
      day: {
        fuel: "",
        maintenance: "",
        others: "",
        observation: "down",
        percentage: "3.0%",
      },
      weekly: {
        fuel: "",
        maintenance: "",
        others: "",
        observation: "down",
        percentage: "3.0%",
      },
      monthly: {
        fuel: "",
        maintenance: "",
        others: "",
        observation: "down",
        percentage: "3.0%",
      },
      yearly: {
        fuel: "",
        maintenance: "",
        others: "",
        observation: "down",
        percentage: "3.0%",
      },
    },
  },
};
export default busAnalytics;
