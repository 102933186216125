import { styled } from "@mui/material/styles";

export const Chip = styled("div")`
  cursor: pointer;
  margin-right: 16px;
  padding: 6px;
  flex: 1;
  border: 1.5px solid #ededed;
  border-radius: 40px;
  background-color: ${({ focused, highlight }) =>
    focused ? highlight : "transparent"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  :last-child {
    margin-right: 0;
  }
`;

export const ChipLabel = styled("div")`
  margin-left: 8px;
  text-align: center;
  font-size: 16px;
  line-height: 18.26px;
  font-weight: 700;
  color: ${({ focused }) => (focused ? "#FFFFFF" : "#727272")};
  @media (max-width: 600px) {
    font-size: 13px;
    line-height: 16.26px;
  }
`;

export const ChipBadgeContainer = styled("div")`
  height: 35px;
  width: 35px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  border-radius: 39px;
  background-color: ${({ focused }) => (focused ? "#FFFFFF" : "#EDEDED")};
  color: ${({ focused, highlight }) => (focused ? highlight : "#727272")};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    height: 25px;
    width: 25px;
  }
`;
