const driverInfo = {
  driverInfo: {
    drivingLicense: "PDRV#123456",
    regionDepot: "Costa Rica",
    totalTrips: "1458",
    drivingScore: "75",
    passengersCarried: "25896",
    totalHoursDriven: "150",
    totalViolations: "50",
    totalMiles: "1500",
    trips: [
      {
        tripNo: "1",
        tripStatus: "In Progress",
        occupancy: "20/40",
        speed: "10 mph",
        workStatus: "On Trip",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        busId: "Bus#123",
        violations: "05",
        drivingScore: "75",
        harshAccelaration: "0",
        corner: "0",
        overspeeding: "10",
        harshBraking: "0",
        idleHours: "0",
        fatigue: "Very Active",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "2",
        tripStatus: "Completed",
        occupancy: "20/40",
        speed: "10 mph",
        workStatus: "On Trip",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        busId: "Bus#123",
        violations: "05",
        drivingScore: "75",
        harshAccelaration: "0",
        corner: "0",
        overspeeding: "10",
        harshBraking: "0",
        idleHours: "0",
        fatigue: "Very Active",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "3",
        tripStatus: "Completed",
        occupancy: "20/40",
        speed: "10 mph",
        workStatus: "On Trip",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        busId: "Bus#123",
        violations: "05",
        drivingScore: "75",
        harshAccelaration: "0",
        corner: "0",
        overspeeding: "10",
        harshBraking: "0",
        idleHours: "0",
        fatigue: "Very Active",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "4",
        tripStatus: "Completed",
        occupancy: "20/40",
        speed: "10 mph",
        workStatus: "On Trip",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        busId: "Bus#123",
        violations: "05",
        drivingScore: "75",
        harshAccelaration: "0",
        corner: "0",
        overspeeding: "10",
        harshBraking: "0",
        idleHours: "0",
        fatigue: "Very Active",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "5",
        tripStatus: "Completed",
        occupancy: "20/40",
        speed: "10 mph",
        workStatus: "On Trip",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        busId: "Bus#123",
        violations: "05",
        drivingScore: "75",
        harshAccelaration: "0",
        corner: "0",
        overspeeding: "10",
        harshBraking: "0",
        idleHours: "0",
        fatigue: "Very Active",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "6",
        tripStatus: "Completed",
        occupancy: "20/40",
        speed: "10 mph",
        workStatus: "On Trip",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        busId: "Bus#123",
        violations: "05",
        drivingScore: "75",
        harshAccelaration: "0",
        corner: "0",
        overspeeding: "10",
        harshBraking: "0",
        idleHours: "0",
        fatigue: "Very Active",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
      {
        tripNo: "7",
        tripStatus: "Completed",
        occupancy: "20/40",
        speed: "10 mph",
        workStatus: "On Trip",
        distanceCovered: "20 Miles",
        distance: "150 Miles",
        busId: "Bus#123",
        violations: "05",
        drivingScore: "75",
        harshAccelaration: "0",
        corner: "0",
        overspeeding: "10",
        harshBraking: "0",
        idleHours: "0",
        fatigue: "Very Active",
        routeDetails: {
          route: "Route#123",
          routeStops: {
            totalStops: "5",
            stopsCovered: "3",
            stoppages: [
              {
                name: "Source",
                observation: "15 Min Delay",
                area: "Florrencia, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Current Location",
                observation: "Over Speeding",
                area: "San Carlos, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 1",
                observation: "Next Stop",
                area: "San Jose, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 2",
                observation: "",
                area: "Mirador, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Stop 3",
                observation: "",
                area: "Pataste, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
              {
                name: "Destination",
                observation: "",
                area: "Jicarito, Costa Rica",
                coOrdinates: {
                  lat: "",
                  lng: "",
                },
                timeStamp: "08-08-2022 | 9:00 AM",
              },
            ],
          },
        },
      },
    ],
    pastViolations: [
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
      {
        violationType: "overspeeding",
        busNo: "123",
        route: "Route#1",
        timeStamp: "08-05-2022 | 10.00 AM",
      },
    ],
  },
};
export default driverInfo;
