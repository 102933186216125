import { makeStyles } from "@material-ui/core/styles";
import theme from "theme";

const useStyles = makeStyles(
  {
    progressBarStyle: {
      borderInlineStartWidth: 5,
    },
    progressMainStyle: {
      display: "inline-flex",
      position: "relative",
    },
    progressInnerText: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      background: theme.palette.grey6,
      borderRadius: "50%",
      width: "40%",
      height: "40%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    progressValueStyle: {
      fontSize: "25px",
      fontWeight: 700,
      color: "#4379DE",
      textAlign: "center",
      [theme.breakpoints.down(1537)]: {
        fontSize: 14,
      },
    },
    progressTextStyle: {
      fontSize: "15px",
      fontWeight: 700,
      color: "#999999",
      textAlign: "center",
      [theme.breakpoints.down(1537)]: {
        fontSize: 10,
      },
    },
  },
  { index: 1 }
);
export default useStyles;
